import React from "react";
import useSWR from "swr";
import { getAdminMessages } from "../../../Firebase/apis";
import { toast } from "react-toastify";
import Spinner from "../../../components/common/Spinner.jsx";
import Table from "../../../components/common/table";
import useStore from "../../../store/store";

export const AdminMessages = () => {
  const { data, error } = useSWR("/get/admin/messages", getAdminMessages);
  const { locale } = useStore((st) => st);
  const COLUMNS = [
    {
      key: "Serial Number",
      label: "#",
      content: (item, index) => <span>{index}</span>,
    },
    {
      label: locale.message,
      path: "message",
    },
  ];
  if (error) return toast.error(error.message);
  if (!data) return <Spinner />;
  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30 mb-5">{locale.venue_portal_nbox}</h4>
      </div>
      <Table data={data} coloumns={COLUMNS} />
    </div>
  );
};
