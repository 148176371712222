import React from "react";
import { useState } from "react";
import Footer from "../../../components/Footer/SecondVariant";
import Navbar from "../../../components/Navbar/SecondVariant";
import useStore from "../../../store/store";
import "./style.css";
import { Form } from "react-bootstrap";
import { ReactComponent as BlackGoodIcon } from "../../../assets/good.svg";
import { ReactComponent as WhiteGoodIcon } from "../../../assets/goodIcon.svg";
export const Pricing = () => {
  const [activeState, setActiveState] = useState(false);
  const { locale, activeLanguage } = useStore((st) => st);
  const p_style = {
    flexDirection: !activeLanguage || activeLanguage.includes("English") ? "row" : "row-reverse",
  }

  const content_style = {
    textAlign: !activeLanguage || activeLanguage.includes("English") ? "left" : "right",
  }

  return (
    <div>
      <Navbar registerLink="/register" buisness={true} pricing={true}/>
      <div className="pricing-secondary-container">
        <div className="container pricing-container">
          <h2 className="main-heading">{locale.choose_plan_that_works}</h2>
          <h6 className="sub-heading">
            {locale.discover_the_perfect_subscriptions}
          </h6>

          <div className="frequency-wrapper">
            <div className="profileMain_portal">
              <p>{locale.monthly}</p>
              <Form.Check
                type="switch"
                id="custom-switch"
                name="servesFood"
                checked={activeState}
                onChange={(e) => setActiveState(e.target.checked)}
              />
              <p>{locale.yearly}</p>
              <div className="discount-wrapper">
                <span>{locale.save_20}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <div className="offer-card">
                <h2>{locale.basic}</h2>
                <div className="bottom-line">
                  <span></span>
                </div>
                <div className="pricing-secondary-container">
                  <div className="price-wrapper">
                    <p>{locale.free}</p>
                  </div>
                </div>
                <div className="offerCard-body">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5 >{locale.whats_included}</h5>
                  </div>
                  <div className="all-offers-content">
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.basic_p1}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.basic_p2}</div>
                    </p>
                  </div>
                  <button
                    className="btn primary-bg-color"
                    onClick={() =>
                      window.location.replace("/portal?register=true")
                    }
                  >
                    {locale.get_started}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <div className="offer-card popular">
                <div className="popular-badge">{locale.most_popular}</div>
                <h2>{locale.standard}</h2>
                <div className="bottom-line">
                  <span></span>
                </div>
                <div className="pricing-secondary-container">
                  <div className="price-wrapper">
                    <p>
                      {locale.standard_p2} {!activeState ? locale.standard_monthly_price : locale.standard_yearly_price}
                    </p>
                    <span>/{activeState ? "yr" : "mo"}</span>
                  </div>
                </div>
                <div className="offerCard-body">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5>{locale.whats_included}</h5>
                  </div>
                  <div className="all-offers-content">
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p5}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p6}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p7}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p8}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p9}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p10}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p11}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.standard_p12}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <WhiteGoodIcon />
                      </div>
                      <div style={content_style}>{locale.stats_dash} </div>
                    </p>
                  </div>

                  <button
                    className="btn primary-bg-color"
                    onClick={() =>
                      window.location.replace("/portal?register=true")
                    }
                  >
                    {locale.get_started}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
              <div className="offer-card">
                <h2>{locale.premium}</h2>
                <div className="bottom-line">
                  <span></span>
                </div>
                <div className="pricing-secondary-container">
                  <div className="price-wrapper">
                    <p>
                      {locale.standard_p2} {!activeState ? locale.premium_monthly_price : locale.premium_yearly_price}
                    </p>
                    <span>/{activeState ? "yr" : "mo"}</span>
                  </div>
                </div>
                <div className="offerCard-body">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5>{locale.whats_included}</h5>
                  </div>
                  <div className="all-offers-content">
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p1}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p2}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p3}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p4}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p5}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p6}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p7}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p8}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p9}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p10}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p11}</div>
                    </p>
                    <p style={p_style}>
                      <div className="good-icon">
                        <BlackGoodIcon />
                      </div>
                      <div style={content_style}>{locale.premium_p12}</div>
                    </p>
                  </div>
                  <button
                    className="btn primary-bg-color"
                    onClick={() =>
                      window.location.replace("/portal?register=true")
                    }
                  >
                    {locale.get_started}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
