import React from "react";
import "./style.css";
import { days, months } from "../../../utils/data";

const Index = ({ match }) => {
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const getTime = (t) => {
    const d = new Date(t);
    const options = { hour: "numeric", minute: "numeric" };
    return d.toLocaleTimeString(undefined, options);
  };

  const getOnlyDate = (t) => {
    const d = new Date(t);
    return (
      days[d.getDay()] +
      ", " +
      d.getDate() +
      " " +
      months[d.getMonth()] +
      " " +
      d.getFullYear()
    );
  };
  return (
    <div className="first-template-container">
      <div className="first-template-inner-container" id="selectedTemplate">
        <img
          className="bg-img"
          src="/assets/Template1.jpg"
          alt="alternatText"
        />
        <img
          className="image1"
          src={`/assets/Flags/${match.firstTeam.flagName}.png`}
          alt={match.firstTeam.name}
        />
        <img
          className="image2"
          src={`/assets/Flags/${match.secondTeam.flagName}.png`}
          alt={match.secondTeam.name}
        />
        <h4 id="time">{getTime(match.startTime)}</h4>
        <h4 className="league-container">
          <span>{match.league.name}</span>
        </h4>
        <h4 id="firstTeam">
          <span>{match.firstTeam.name}</span>
        </h4>
        <h4 id="secondTeam">
          <span>{match.secondTeam.name}</span>
        </h4>
        <h4 id="date">
          <span>{getOnlyDate(match.startTime)}</span>
        </h4>
        <h4 id="city">
          <p>{user.venueName} </p>
          <p>{user.city}</p>
        </h4>
      </div>
    </div>
  );
};

export default Index;
