import React from "react";
import {
  addNotificationForUser,
  addNotificationForVenue,
  deleteClientMessages,
  deletePortalMessageInDB,
  getClientMessages,
  getUser,
  getUserToken,
  sendNotificationToUser,
  submitSupportMessageToUser,
} from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { downloadExcel } from "../../../utils/data";
import { Modal, Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { DescriptionCard } from "./DescriptionCard";

export const Support = () => {
  const { data, error } = useSWR(
    "/admin/get/client-messages",
    getClientMessages
  );
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [model, setModel] = useState(false);
  const [isPortal, setIsPortal] = useState(false);
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");
  const [userId, setUserId] = useState("");
  const deleteHandler = async (id) => {
    const c = window.confirm("Are you sure?");
    if (!c) return;
    try {
      await deleteClientMessages(id);
      alert("Deleted!");
    } catch (error) {
      alert(error.message);
    }
  };
  const deletePortalMessageHandler = async (id) => {
    const c = window.confirm("Are you sure?");
    if (!c) return;
    try {
      await deletePortalMessageInDB(id);
      alert("Deleted!");
    } catch (error) {
      alert(error.message);
    }
  };

  const COLUMNS_PORTALS = [
    {
      key: "Subject",
      label: "Subject",
      content: (item) => <span>{item.title}</span>,
    },
    {
      key: "Name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      key: "email",
      label: "email",
      content: (item) => <span>{item.email}</span>,
    },
    {
      key: "Body",
      label: "Body",
      content: (item) => <DescriptionCard details={item.details} />,
    },
    {
      key: "send message",
      label: "",
      content: (item) => (
        <button
          className="btn btn-primary"
          onClick={() => messageHandler(item.docId, true, item.venueId)}
        >
          Send Messsage
        </button>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deletePortalMessageHandler(item.docId)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];

  const COLUMNS = [
    {
      key: "subject",
      label: "Subject",
      content: (item) => <span>{item.subject}</span>,
    },
    {
      key: "email",
      label: "email",
      content: (item) => <span>{item.email}</span>,
    },
    {
      path: "body",
      label: "Body",
    },
    {
      key: "send message",
      label: "",
      content: (item) => (
        <button
          className="btn btn-primary"
          onClick={() => messageHandler(item.docID, false, item.email)}
        >
          Send Messsage
        </button>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.docID)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];

  function handleDownloadExcel() {
    const tData = data
      ? data.users.map((item) => ({
        name: item.name,
        email: item.email,
        subject: item.subject,
        body: item.body,
      }))
      : [];
    downloadExcel(tData, "supportUserMessages");
  }
  function handlePortalDownloadExcel() {
    const tData = data
      ? data.portals.map((item) => ({
        name: item.name,
        email: item.email,
        subject: item.subject,
        body: item.body,
      }))
      : [];
    downloadExcel(tData, "supportUserMessages");
  }
  const submitMessage = async () => {
    try {
      const collection = isPortal ? "cotactUs" : "contactCollection";
      await submitSupportMessageToUser(id, message, collection);
      const notificationMessage = "New message from admin, please have a look!";
      if (isPortal) {
        addNotificationForVenue(notificationMessage, "/portal/contactus", userId);
      } else {
        const token = await getUserToken(userId);
        if (token) {
          console.log(token.deviceTokens);
          await sendNotificationToUser(token.deviceTokens);
        }
      }
      setModel(false);
      toast.success("Uploaded");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const messageHandler = async (id, portal, uId) => {
    if (portal) {
      setUserId(uId);
    } else {
      const user = await getUser(uId);
      if (!user) {
        toast.error("No user with is data, It is delete!")
      } else {
        setUserId(user.userID);
      }
    }
    setId(id);
    setModel(true);
    setIsPortal(portal);
  };

  if (error) toast.error(error.message);
  if (!data) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Messages</h1>
        </div>

        <Tabs
          defaultActiveKey="usermessages"
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
        >
          <Tab eventKey="usermessages" title="Users">
            <button
              className="btn btn-secondary my-2"
              onClick={handleDownloadExcel}
            >
              Exports Users Messages
            </button>
            <Table data={data.users} coloumns={COLUMNS} />
            {data.users.length <= 0 && <span>No Messages added yet.</span>}
            {data.users.length > 0 && data.users.length > itemsPerPage && (
              <span className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => setItemsPerPage((c) => c + 10)}
                >
                  <BsChevronDoubleDown />
                </button>
              </span>
            )}
          </Tab>
          <Tab eventKey="portalmessages" title="Portals">
            <button
              className="btn btn-secondary my-2"
              onClick={handlePortalDownloadExcel}
            >
              Exports Venues Messages
            </button>
            <Table data={data.portals} coloumns={COLUMNS_PORTALS} />
            {data.portals.length > 0 && data.portals.length > itemsPerPage && (
              <span className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => setItemsPerPage((c) => c + 10)}
                >
                  <BsChevronDoubleDown />
                </button>
              </span>
            )}
          </Tab>
        </Tabs>
      </div>
      <Modal show={model} onHide={() => setModel(false)}>
        <Modal.Body className="modal-body">
          <h2 className="modal-title">Send Message</h2>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <p htmlFor="contactNo"> Message </p>
            <input
              type="text"
              id="contactNo"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
            />
          </div>
          <button className="btn btn-custom mt-5" onClick={submitMessage}>
            Submit
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
