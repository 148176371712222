import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import AdminImage from "../../assets/placeholder.jpg";
import "./style.css";
import useStore from "../../store/store";
import { MdOutlineSettings, MdReorder } from "react-icons/md";
import { BiBell } from "react-icons/bi";
import { firestore } from "../../Firebase/config";
import isMobileScreen from "../../hooks/isMobileScreen";

const Index = (props) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const isMobile = isMobileScreen();
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const {
    setLocale,
    locale,
    englishLocale,
    arrabicLocale,
    setLanguage,
  } = useStore((st) => st);
  const [notifications, setNotifications] = useState([]);

  const getVenueNotifications = () => {
    const messagesdbRef = firestore.collection("venueNotifications");
    messagesdbRef.onSnapshot((querySnapShot) => {
      const notifications = [];
      querySnapShot.forEach(async (item) => {
        const data = item.data();
        if (!data.isRead && data.venueid === user.userId) {
          notifications.push({ id: item.id, ...data });
        }
      });
      setNotifications(notifications);
    });
  };

  useEffect(() => {
    getVenueNotifications();
  }, []);

  const notificationClickedHandler = async (item) => {
    await firestore
      .collection("venueNotifications")
      .doc(item.id)
      .delete();
    navigate(item.route);
  };

  const changeHandler = (e) => {
    if (!e.target.value) return;
    if (e.target.value.includes("Arrabic")) {
      setLocale(arrabicLocale);
      sessionStorage.setItem("fanspot-locale", e.target.value);
      setLanguage(e.target.value);
    } else if (e.target.value.includes("English")) {
      sessionStorage.setItem("fanspot-locale", e.target.value);
      setLocale(englishLocale);
      setLanguage(e.target.value);
    }
  };
  const navbarToggle = () => {
    setIsActive(!isActive);
  };

  const checkActive = (path) => {
    const exist = window.location.pathname.includes(path);
    return exist ? "active" : "";
  };

  const clickHandler = (path) => {
    navigate(path);
    if (isMobile) {
      setIsActive(p => !p);
    }
  };

  return (
    <div>
      <Navbar className="navbarMain" expand="lg">
        <Navbar.Brand>
          <span className="navbarMenu" onClick={navbarToggle}>
            <MdReorder />
          </span>
          <img alt="alternatText" src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {!props.isAdmin && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <div className="dropdown venue pointer">
                <span
                  className="notifications-container"
                  id="notificationDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BiBell />
                  {notifications.length ? (
                    <span className="badge badge-light">
                      {notifications.length}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                <div
                  className="dropdown-menu dropdown-menu-right dropdown-menu-lg-left"
                  aria-labelledby="notificationDropdown"
                >
                  {notifications.length <= 0 ? (
                    <span className="dropdown-item">No Notifications</span>
                  ) : (
                    notifications.map((item) => (
                      <div
                        className="dropdown-item"
                        onClick={() => notificationClickedHandler(item)}
                      >
                        {item.message}
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="form-group" style={{ margin: "13px" }}>
                <select
                  className="form-control selectLanguage"
                  onChange={changeHandler}
                >
                  <option>{locale.change_language}</option>
                  <option value="English">English</option>
                  <option value="Arrabic">العربية</option>
                </select>
              </div>
              <Link to="/portal/settings" className="nav-link">
                <span className="settingItem">
                  <MdOutlineSettings />
                </span>
              </Link>

              <Link to="/portal/profile" className="nav-link adminImageWrapper">
                <img
                  alt="alternatText"
                  src={!props.isAdmin && user.logo ? user.logo : AdminImage}
                  className="adminImage "
                />
              </Link>
              <p className="nav-venueName">{user.venueName}</p>
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>

      <div className="main">
        <div
          className={"sidebar"}
          style={{
            display: isActive ? "none" : "",
            transition: isActive ? ".5s" : "",
          }}
        >
          <div className="sidebar_links">
            <div
              key={"/portal/dashboard"}
              className={`sidebar_link ${checkActive("/portal/dashboard")}`}
              onClick={() => clickHandler("/portal/dashboard")}
            >
              <span>
                {checkActive("/portal/dashboard") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/dashboard.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/dashboard.svg" />
                )}
              </span>
              {locale.dashboard}
            </div>
            <div
              key={"/portal/profile"}
              className={`sidebar_link ${checkActive("/portal/profile")}`}
              onClick={() => clickHandler("/portal/profile")}
            >
              <span>
                {checkActive("/portal/profile") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/profile .svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/profile .svg" />
                )}
              </span>
              {locale.profile}
            </div>
            <div
              key={"/portal/fixtures"}
              className={`sidebar_link ${checkActive("/portal/fixtures")}`}
              onClick={() => clickHandler("/portal/fixtures")}
            >
              <span>
                {checkActive("/portal/fixtures") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/Fixture.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/Fixture.svg" />
                )}
              </span>
              {locale.fixtures}
            </div>

            <div
              key={"/portal/reviews"}
              className={`sidebar_link ${checkActive("/portal/reviews")}`}
              onClick={() => clickHandler("/portal/reviews")}
            >
              <span>
                {checkActive("/portal/reviews") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/Review.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/Review.svg" />
                )}
              </span>
              {locale.reviews}
            </div>

            <div
              key={"/portal/tablemanagement"}
              className={`sidebar_link ${checkActive(
                "/portal/tablemanagement"
              )}`}
              onClick={() => clickHandler("/portal/tablemanagement")}
            >
              <span>
                {checkActive("/portal/tablemanagement") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/table Booking.svg"
                  />
                ) : (
                  <img
                    alt="alternatText"
                    src="/assets/icons/table Booking.svg"
                  />
                )}
              </span>
              {locale.table_booking_managment}
            </div>
            <div
              key={"/portal/socialmanagement"}
              className={`sidebar_link ${checkActive(
                "/portal/socialmanagement"
              )}`}
              onClick={() => clickHandler("/portal/socialmanagement")}
            >
              <span>
                {checkActive("/portal/socialmanagement") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/social-media.svg"
                  />
                ) : (
                  <img
                    alt="alternatText"
                    src="/assets/icons/social-media.svg"
                  />
                )}
              </span>
              {locale.social_managment}
            </div>
            <div
              key={"/portal/offers"}
              className={`sidebar_link ${checkActive("/portal/offers")}`}
              onClick={() => clickHandler("/portal/offers")}
            >
              <span>
                {checkActive("/portal/offers") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/Offers.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/Offers.svg" />
                )}
              </span>
              {locale.offers}
            </div>
            <div
              key={"/portal/stats"}
              className={`sidebar_link ${checkActive("/portal/stats")}`}
              onClick={() => clickHandler("/portal/stats")}
            >
              <span>
                {checkActive("/portal/stats") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/Statistics.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/Statistics.svg" />
                )}
              </span>
              {locale.statistics}
            </div>
            <div
              key={"/portal/memberships"}
              className={`sidebar_link ${checkActive("/portal/memberships")}`}
              onClick={() => clickHandler("/portal/memberships")}
            >
              <span>
                {checkActive("/portal/memberships") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/membership.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/membership.svg" />
                )}
              </span>
              {locale.membership}
            </div>
            <div
              key={"/portal/contactus"}
              className={`sidebar_link ${checkActive("/portal/contactus")}`}
              onClick={() => clickHandler("/portal/contactus")}
            >
              <span>
                {checkActive("/portal/contactus") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/Contact us.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/Contact us.svg" />
                )}
              </span>
              {locale.contact_us}
            </div>
            <div
              key={"/portal/inbox"}
              className={`sidebar_link ${checkActive("/portal/inbox")}`}
              onClick={() => clickHandler("/portal/inbox")}
            >
              <span>
                {checkActive("/portal/inbox") ? (
                  <img alt="alternatText" src="/assets/ActiveIcons/inbox.svg" />
                ) : (
                  <img alt="alternatText" src="/assets/icons/inbox.svg" />
                )}
              </span>
              {locale.inbox}
            </div>
            <div
              key={"/portal/settings"}
              className={`sidebar_link ${checkActive("/portal/settings")}`}
              onClick={() => clickHandler("/portal/settings")}
            >
              <span>
                {checkActive("/portal/settings") ? (
                  <img
                    alt="alternatText"
                    src="/assets/ActiveIcons/Settings.svg"
                  />
                ) : (
                  <img alt="alternatText" src="/assets/icons/Settings.svg" />
                )}
              </span>
              {locale.settings}
            </div>

            <div className="sidebar_link" onClick={props.logoutHandler}>
              <span>
                <img alt="alternatText" src="/assets/icons/logout.svg" />
              </span>
              {locale.logout}
            </div>
          </div>
        </div>

        <div
          className={!isActive ? "left-margin dashboardMain" : "dashboardMain"}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Index;
