import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import GoogleMap from "./GoogleMap";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 0,
        lng: 0,
      },
    };
  }

  componentDidMount() {
    if (this.props.lat && this.props.lat !== 0) {
      this.setState({
        mapCenter: {
          lat: this.props.lat,
          lng: this.props.lng,
        },
      });
    }
  }

  handleChange = (address) => {
    this.setState({ address });
  };
  handleSelect = (address) => {
    this.setState({ address });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // update center state
        this.props.selectedLocation({ address, ...latLng });
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <div id="googleMaps">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input form-control",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <p style={{ marginTop: "1.4rem", fontWeight: "600" }}>
          {this.props.address}
        </p>

        <div style={{ margin: "10px 0px" }}>
          <GoogleMap
            lat={this.state.mapCenter.lat}
            lng={this.state.mapCenter.lng}
            selectedLocation={this.props.selectedLocation}
          />
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA",
})(MapContainer);
