import React from "react";
import { MdOutlineSearch } from "react-icons/md";
import { Tab, Tabs } from "react-bootstrap";
import {
  getMonthDates,
  userStatus,
  userStatusforPremium,
} from "../../../utils/data";
import { toast } from "react-toastify";
import { updateReservation } from "../../../Firebase/apis";
import "./Fixture.css";
import { useMemo } from "react";
import { DateDifference } from "../../../utils/date";
import { useEffect } from "react";
import { useState } from "react";
import useStore from "../../../store/store";

const FixturesView = ({ data, activeindex = 3, setActiveIndex, changeHandler }) => {
  const [tab, setTab] = useState("home");
  const { locale, activeLanguage } = useStore((st) => st);
  const getTime = (date) => {
    const d = new Date(date);
    var localeSpecificTime = d.toLocaleTimeString();
    return localeSpecificTime.replace(/:\d+ /, " ");
  };

  const getMatchesForActiveTab = (matches) => {
    const activeDay = new Date(getMonthDates()[activeindex].fullDate);
    const filtermatches = [];
    matches.forEach((match) => {
      const matchDate = new Date(match.startTime);
      if (
        matchDate.getFullYear() === activeDay.getFullYear() &&
        matchDate.getMonth() === activeDay.getMonth() &&
        matchDate.getDate() === activeDay.getDate()
      ) {
        filtermatches.push(match);
      }
    });
    return filtermatches;
  };


  const removeHandler = async (id, reservations) => {
    if (!userStatus) return alert("you need to upgrade to use this feature");
    const confirm = window.confirm("Are you sure?");
    if (!confirm) return;
    const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
    let r_copy = [...reservations];
    r_copy = r_copy.filter((item) => item !== portal.userId);
    try {
      await updateReservation(id, r_copy);
      toast.success("Deleted Reservation successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const elements = document.getElementsByClassName("calendarDate");
    if (elements.length > 0) {
      elements[
        activeindex < 6 || activeindex > 24 ? activeindex : activeindex + 4
      ].scrollIntoView();
    }
  }, [data, tab]);
  useEffect(() => {
    const elements = document.getElementsByClassName("mycalendarDate");
    if (elements.length > 0) {
      elements[
        activeindex < 6 || activeindex > 24 ? activeindex : activeindex + 4
      ].scrollIntoView();
    }
  }, [data, tab]);

  const remainingMatches = useMemo(() => {
    let count = 0;
    const cDate = new Date();
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    const lastDayOfCM = new Date(cDate.getFullYear(), cDate.getMonth() + 1, 0);
    data.myMatches.map((item) => {
      const mDate = new Date(item.startTime);
      const diff = DateDifference.inRange(mDate, firstDayOfMonth, lastDayOfCM);
      if (diff) {
        count += 1;
      }
      return item;
    });
    const rmMatches = (userStatusforPremium ? 100000000 : 10) - count;
    return rmMatches > 0 ? rmMatches : 0;
  }, [data]);

  const reserveMatchHandler = async (id, reservations, time) => {
    if (!userStatus) return alert("you need to upgrade to use this feature");
    if (remainingMatches <= 0) return alert("No remaing matches");
    const matchDate = new Date(time);
    const currentDate = new Date();
    if (currentDate.getTime() > matchDate.getTime()) {
      return toast.error("Cannot reserve past matches");
    }
    const confirm = window.confirm("Are you sure to add?");
    if (!confirm) return;
    const r_copy = reservations ? [...reservations] : [];
    const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
    r_copy.push(portal.userId);
    try {
      await updateReservation(id, r_copy);
      toast.success("Added Reservation");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSearchTerm = (e) => {
    if (e.key === 'Enter') {
      changeHandler(e.target.value);
    }
  }
  console.log(data);

  const matches = [...data.countryMatches, ...data.clubsMatches];

  return (
    <div className="container">
      <div>
        <div className="breadCrumb">
          <h4 className="fs-30">{locale.venue_portal_fixture}</h4>
        </div>

        <div className="profile_main">
          <div className="navLinks">
            <Tabs
              activeKey={tab}
              id="uncontrolled-tab-example"
              className="mb-3 fixtureNav"
              onSelect={(tb) => setTab(tb)}
            >
              <Tab eventKey="home" title={locale.add_fixture} className="fs-20">
                <br />
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-7">
                    <h6 className="fs-17">
                      {locale.select_matches_plan_your_schedule}
                    </h6>
                    <div className="fixtureHeadInput mt-3">
                      <div>
                        <span className="fixtureInputIcon">
                          <MdOutlineSearch />
                        </span>
                      </div>
                      <div>
                        <input 
                          type="text"
                          onKeyPress={handleSearchTerm}
                          className="fixtureCustomInput fs-15"
                          placeholder={locale.search_for_team_league}
                        />
                      </div>
                    </div>
                  </div>
                  {!userStatusforPremium && (
                    <div className="col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center flex-column">
                      <h6>{locale.remaining_matches_for_this_month}</h6>
                      <div className="fixtureHeadInput2 mt-3">
                        {!userStatus ? 0 : remainingMatches} {locale.matches}
                      </div>
                    </div>
                  )}
                </div>

                <div className="fixtureCalendar">
                  {getMonthDates().map((item, index) => (
                    <div
                      key={item.day}
                      onClick={() => setActiveIndex(index)}
                      className={
                        index === activeindex
                          ? "calendarDate active"
                          : "calendarDate"
                      }
                    >
                      <h5>{item.date}</h5>
                      <p>
                        {activeLanguage && !activeLanguage.includes("English")
                          ? item.day.ar
                          : item.day.eng}
                      </p>
                    </div>
                  ))}
                </div>

                {matches.map((club) => (
                  <div className="fixtureBottom" key={club.clubId}>
                    {getMatchesForActiveTab(club.matches).length > 0 && (
                      <div className="fixtureBottom_league d-flex align-items-center">
                        <img
                          src={club.image}
                          alt="clubImage"
                          width={50}
                          height={50}
                        />
                        <h4 className="m-lg-4">{activeLanguage && activeLanguage.includes("English")
                          ? club.name
                          : club.nameInArabic}</h4>
                      </div>
                    )}
                    <div className="leagueMatches">
                      {getMatchesForActiveTab(club.matches).map((match) => (
                        <div className="leagueMatch" key={match.docId}>
                          <p>{activeLanguage && activeLanguage.includes("English")
                            ? match.firstTeam.name
                            : match.firstTeam.nameInArabic}</p>
                          <img
                            src={
                              match.firstTeam.image
                                ? match.firstTeam.image
                                : `/assets/Flags/${match.firstTeam.flagName}.png`
                            }
                            alt={match.firstTeam.name}
                          />
                          <h5>{getTime(match.startTime)}</h5>
                          <img
                            src={
                              match.secondTeam.image
                                ? match.secondTeam.image
                                : `/assets/Flags/${match.secondTeam.flagName}.png`
                            }
                            alt={match.secondTeam.name}
                          />
                          <p>{activeLanguage && activeLanguage.includes("English")
                            ? match.secondTeam.name
                            : match.secondTeam.nameInArabic}</p>
                          <button
                            className="btn btn-fixture"
                            onClick={() =>
                              reserveMatchHandler(
                                match.docId,
                                match.reservations,
                                match.startTime
                              )
                            }
                          >
                            {locale.add}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Tab>

              <Tab
                eventKey="profile"
                title={locale.my_schedule}
                className="fs-20"
              >
                <br />
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-7">
                    <h6 className="fs-17">{locale.edit_your_schedule}</h6>
                    <div className="fixtureHeadInput mt-3">
                      <div>
                        <span className="fixtureInputIcon">
                          <MdOutlineSearch />
                        </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="fixtureCustomInput fs-15"
                          placeholder="Search for a team or league"
                        />
                      </div>
                    </div>
                  </div>
                  {!userStatusforPremium && (
                    <div className="col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center flex-column">
                      <h6>{locale.remaining_matches_for_this_month}</h6>
                      <div className="fixtureHeadInput2 mt-3">
                        {!userStatus ? 0 : remainingMatches} {locale.matches}
                      </div>
                    </div>
                  )}
                </div>

                <div className="fixtureCalendar">
                  {getMonthDates().map((item, index) => (
                    <div
                      key={item.date}
                      onClick={() => setActiveIndex(index)}
                      className={
                        index === activeindex
                          ? "mycalendarDate active"
                          : "mycalendarDate"
                      }
                    >
                      <h5>{item.date}</h5>
                      <p>
                        {" "}
                        {activeLanguage && !activeLanguage.includes("English")
                          ? item.day.ar
                          : item.day.eng}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="fixtureBottom">
                  <div className="leagueMatches">
                    {getMatchesForActiveTab(data.myMatches).map((match) => (
                      <div className="leagueMatch" key={match.docId}>
                        <p>{activeLanguage && activeLanguage.includes("English")
                          ? match.firstTeam.name
                          : match.firstTeam.nameInArabic}</p>
                        <img
                          src={
                            match.firstTeam.image
                              ? match.firstTeam.image
                              : `/assets/Flags/${match.firstTeam.flagName}.png`
                          }
                          alt={match.firstTeam.name}
                        />
                        <h5>{getTime(match.startTime)}</h5>
                        <img
                          src={
                            match.secondTeam.image
                              ? match.secondTeam.image
                              : `/assets/Flags/${match.secondTeam.flagName}.png`
                          }
                          alt={match.secondTeam.name}
                        />
                        <p>{activeLanguage && activeLanguage.includes("English")
                          ? match.secondTeam.name
                          : match.secondTeam.nameInArabic}</p>
                        <button
                          className="btn btn-fixture"
                          onClick={() =>
                            removeHandler(match.docId, match.reservations)
                          }
                        >
                          {locale.remove}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixturesView;
