import React from "react";
import { deleteClub, getClubss } from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { downloadExcel } from "../../../utils/data";

export const Clubs = () => {
  const { data, error } = useSWR("/admin/get/clubs", getClubss);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  function handleDownloadExcel() {
    const tData = data
      ? data.map((item) => ({
          id: item.docId,
          name: item.name,
          nameInArabic: item.nameInArabic,
          image: item.image,
        }))
      : [];
    downloadExcel(tData, "clubs");
  }

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you sure, you want to delete?");
      if (confirm) {
        await deleteClub(id);
        alert("Deleted successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const COLUMNS = [
    {
      key: "logo",
      label: "Image",
      content: (item) => (
        <img
          src={item.image}
          width={"100px"}
          height={"50px"}
          alt="alternatText"
        />
      ),
    },
    {
      key: "name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <Link to="/admin/clubs/add" state={item} className="btn btn-secondary">
          Edit
        </Link>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.docId)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];

  if (error) toast.error(error.message);
  if (!data) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Leagues</h1>
          <Link
            to="/admin/clubs/add"
            className="btn btn-outline-secondary d-flex justify-content-center align-items-center mb-3"
          >
            <span style={{ marginRight: "10px" }}>
              <GrAdd />
            </span>
            Add
          </Link>
        </div>

        <button
          className="btn btn-secondary my-2 px-5"
          onClick={handleDownloadExcel}
        >
          Export
        </button>

        <Table data={data} coloumns={COLUMNS} />
        {data.length <= 0 && <span>No clubs added yet.</span>}

        {data.length > 0 && data.length > itemsPerPage && (
          <span className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => setItemsPerPage((c) => c + 10)}
            >
              <BsChevronDoubleDown />
            </button>
          </span>
        )}
      </div>
    </div>
  );
};
