import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import enLocale from "../../utils/locale.json";
import arrabicLocale from "../../utils/localeArrabic.json";
import useStore from "../../store/store";

const Index = (props) => {
  const { setLocale, locale, setLanguage } = useStore((st) => st);
  const changeHandler = (e) => {
    if (!e) return;
    if (e.includes("Arrabic")) {
      setLocale(arrabicLocale);
      sessionStorage.setItem("fanspot-locale", e);
      setLanguage(e);
    } else if (e.includes("English")) {
      sessionStorage.setItem("fanspot-locale", e);
      setLocale(enLocale);
      setLanguage(e);
    }
  };
  return (
    <Navbar className="navbarMain nav-position py-4" expand="lg">
      <a href={props.isPortal ? "/?buisness=true" : "/"}>
        <Navbar.Brand>
          <img src={logo} alt="App Logo" />
        </Navbar.Brand>
      </a>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="d-flex align-items-center justify-content-center">
          <li className="nav-item dropdown mt-2">
            <a
              className="nav-link dropdown-toggle"
              href="#test"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {sessionStorage.getItem("fanspot-locale")
                ? sessionStorage.getItem("fanspot-locale").includes("English")
                  ? "English"
                  : "العربية"
                : "English"}
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <option
                className="dropdown-item"
                onClick={() => changeHandler("English")}
              >
                English
              </option>
              <option
                className="dropdown-item"
                onClick={() => changeHandler("Arrabic")}
              >
                العربية
              </option>
            </div>
          </li>
          {props.client && !props.clientHome ? (
            <Link to="/pricing" className="mt-2 nav-link">
              {locale.pricing}
            </Link>
          ) : (
            ""
          )}
          {props.client && props.clientHome ? (
            <a href="#clientfixures" className="mt-2 nav-link">
              {locale.fixtures}
            </a>
          ) : (
            ""
          )}
          {props.client && props.clientHome ? (
            <a href="#clientvenues" className="mt-2 nav-link">
              {locale.venues}
            </a>
          ) : (
            ""
          )}
          {props.client && props.clientHome ? (
            <a href="/" className="mt-2 nav-link">
              {locale.app}
            </a>
          ) : (
            ""
          )}
          {props.client && props.clientHome ? (
            <Link to="/aboutus" className="mt-2 nav-link">
              {locale.about_us}
            </Link>
          ) : (
            ""
          )}

          {props.client && !props.clientHome ? (
            <a href="#faqs" className="mt-2 nav-link">
              {locale.faqs}
            </a>
          ) : (
            ""
          )}
          {!props.clientHome ? (
            <a href="/portal" className="nav-link mt-2 mr-2 text-nowrap">
              {locale.login}
            </a>
          ) : (
            ""
          )}
          {props.clientHome ? (
            <Link to="/buisness" className="px-3">
              <Button>{locale.imclub}</Button>
            </Link>
          ) : (
            <a href="/portal?register=true">
              <Button>{locale.create_venue_account}</Button>
            </a>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Index;
