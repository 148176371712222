import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDNd_VLumdwECtf4uZXNxxUT-en82IykiE",
  authDomain: "firstapp-92c68.firebaseapp.com",
  databaseURL: "https://firstapp-92c68.firebaseio.com",
  projectId: "firstapp-92c68",
  storageBucket: "firstapp-92c68.appspot.com",
  messagingSenderId: "917574703722",
  appId: "1:917574703722:web:e2f1441a0df4a2bbff8813",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storageRef = firebase.storage();
