import React, { useState } from "react";
import Table from "./../../../components/common/table";
import useStore from "../../../store/store";
import { toast } from "react-toastify";
import { downloadExcel } from "../../../utils/data";
import { IoArrowBack } from "react-icons/io5";

const List = ({ data, setSelectedItem, deleteHandler }) => {
  const { portals, matches } = useStore((state) => state);
  const [selectedEventUsers, setSelectedEventUsers] = useState(null);

  const getVenue = (id) => {
    const venue = portals.find((it) => it.userId === id);
    return venue ? venue.venueName : "";
  };
  const getTeam = (id) => {
    const match = matches.find((it) => it.docId === id);
    return match ? match : null;
  };
  const getDate = (d) => {
    const dt = new Date(d);
    return dt.toLocaleDateString();
  };

  const getEventUsers = (eventUsers) => {
    setSelectedEventUsers(eventUsers);
  };
  const COLUMNS = [
    {
      path: "name",
      label: "Ultra Name",
    },
    {
      path: "city",
      label: "Ultra City",
    },
    {
      key: "Match",
      label: "Match",
      content: (it) => (
        <div>
          <span>
            {getTeam(it.match) &&
              getTeam(it.match).firstTeam &&
              getTeam(it.match).firstTeam.name}
          </span>
          <span className="mx-2">vs</span>
          <span>
            {getTeam(it.match) &&
              getTeam(it.match).secondTeam &&
              getTeam(it.match).secondTeam.name}
          </span>
        </div>
      ),
    },
    {
      key: "Venue",
      label: "Venue",
      content: (it) => (
        <div>
          <span>{getVenue(it.venue)}</span>
        </div>
      ),
    },
    {
      key: "Minimum Order/Person",
      label: "Minimunm Order Per Person",
      content: (it) => (
        <div>
          <span>SAR {it.minimumOrderPerPerson}</span>
        </div>
      ),
    },
    {
      path: "capacity",
      label: "Capacity",
    },
    {
      key: "date",
      label: "Expiry Date",
      content: (it) => <span> {getDate(it.date)}</span>,
    },
    {
      key: "registerUsers",
      label: "Registrations",
      content: (it) => (
        <span onClick={() => getEventUsers(it.users)}>
          <u className="text-primary pointer">{it.users.length}</u>{" "}
        </span>
      ),
    },
    {
      key: "Edit",
      label: "Edit",
      content: (it) => (
        <button onClick={() => setSelectedItem(it)} className="btn btn-primary">
          Edit
        </button>
      ),
    },
    {
      key: "Delete",
      label: "Delete",
      content: (it) => (
        <button
          onClick={() => deleteHandler(it.docId)}
          className="btn btn-danger"
        >
          Delete
        </button>
      ),
    },
  ];
  const EVENT_USERS_COLUMNS = [
    {
      path: "name",
      label: "Name",
    },
    {
      path: "phoneNumber",
      label: "Phone Number",
    },
  ];

  function handleDownloadExcel() {
    if (!selectedEventUsers) return toast.error("No users");
    const tData = selectedEventUsers.map((item) => ({
      name: item.name,
      phoneNumber: item.phoneNumber,
    }));
    downloadExcel(tData, "eventUsers");
  }
  return selectedEventUsers ? (
    <div>
      <span
        className="btn btn-primary px-3"
        onClick={() => setSelectedEventUsers(null)}
      >
        <IoArrowBack />
      </span>
      <button
        className="btn btn-secondary m-2 px-5"
        onClick={handleDownloadExcel}
      >
        Export Users
      </button>
      <Table data={selectedEventUsers} coloumns={EVENT_USERS_COLUMNS} />
    </div>
  ) : (
    <Table data={data} coloumns={COLUMNS} />
  );
};

export default List;
