import React, { useMemo } from "react";
import { Modal, Button, Form as BootstrapForm, Table } from "react-bootstrap";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Input from "../../../components/common/input";
import Select from "../../../components/common/select";
import SelectSecondVariant from "../../../components/common/select2";
import Map1 from "../../../components/common/Map1";
import { toast } from "react-toastify";
import {
  adminUpdatedVenuePortal,
  getRegions,
  registerVenuePortal,
  uploadEmailFromAuth,
} from "../../../Firebase/apis";
import Spinner from "../../../components/common/Spinner.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  CHANNELS,
  COUNTRIES,
  SUBSCRIPTIONS,
  TIMINGS,
  WORKING_HOURS,
} from "./../../../utils/data";
import CustomSelect from "../../../components/common/MultiSelect";
import useSWR from "swr";
import shallow from "zustand/shallow";
import useStore from "../../../store/store";
import {
  MdAirlineSeatReclineNormal,
  MdAssignment,
  MdCameraOutdoor,
  MdNoFood,
  MdOutlineCasino,
  MdOutlineSignalCellularNoSim,
  MdPublicOff,
} from "react-icons/md";
import { TbCoffeeOff, TbScreenShareOff, TbTableOff } from "react-icons/tb";
import { BsTable, BsWifi, BsWifiOff } from "react-icons/bs";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { AiOutlineCoffee } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { ReactComponent as Commentry } from "../../../assets/commentary.svg";
import { ReactComponent as NoCommentry } from "../../../assets/nocommentry.svg";

export const PortalForm = (props) => {
  const { data } = useSWR("/get/getRegions", getRegions);
  const location = useLocation();
  const navigate = useNavigate();
  const { addVenue, editVenue } = useStore(
    (state) => ({
      addVenue: state.addVenue,
      editVenue: state.editVenue,
    }),
    shallow
  );

  const [state, setState] = useState({
    lat: 0,
    lng: 0,
    address: "",
    showModel: false,
    loading: true,
    region: null,
    city: null,
    userId: "",
  });

  const [portalForm, setPortalForm] = useState({
    venueName: "",
    firstName: "",
    lastName: "",
    country: COUNTRIES[0].name,
    commercialRegister: "",
    email: "",
    mangerPhoneNumber: "",
    password: "",
    confirmPassword: "",
    channelSubscription: [],
    subscriptionBundle: "",
    paymentFrequency: "",
    subscriptionExpiryDate: "",
  });
  const [editFormFields, setEditFormFields] = useState({
    venuePhoneNumber: "",
    website: "",
    menue: null,
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    snapchatLink: "",
    numberOfScreens: 1,
    wifi: false,
    commentry: false,
    projector: false,
    servesFood: false,
    outdoorSeating: false,
    tableBooking: false,
    shisha: false,
    privateRoom: false,
    servesCofee: false,
    seatBooking: false,
    workingHours: WORKING_HOURS,
    logo: null,
    rating: 0,
    pictures: [],
    applyAll: false,
    sTime: "",
    eTime: "",
  });

  const validations = yup.object().shape({
    venueName: yup
      .string()
      .required()
      .label("Venue Name"),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().required(),
    mangerPhoneNumber: yup.string().required(),
    channelSubscription: yup.array().required(),
    subscriptionBundle: yup.string().required(),
    subscriptionExpiryDate: yup.string().required(),
  });

  useEffect(() => {
    const portal = location.state;
    if (portal) {
      setPortalForm({
        venueName: portal.venueName,
        firstName: portal.firstName,
        lastName: portal.lastName,
        country: portal.country,
        commercialRegister: portal.commercialRegister
          ? portal.commercialRegister
          : "",
        email: portal.email,
        mangerPhoneNumber: portal.mangerPhoneNumber,
        channelSubscription: portal.channelSubscription,
        subscriptionBundle: portal.subscriptionBundle,
        paymentFrequency: portal.paymentFrequency,
        subscriptionExpiryDate: portal.subscriptionExpiryDate
          ? portal.subscriptionExpiryDate
          : new Date().toLocaleDateString(),
      });
      setEditFormFields((pre) => ({
        venuePhoneNumber: portal.venuePhoneNumber
          ? portal.venuePhoneNumber
          : "",
        website: portal.website ? portal.website : "",
        menue: portal.menue ? portal.menue : null,
        facebookLink: portal.facebookLink ? portal.facebookLink : "",
        instagramLink: portal.instagramLink ? portal.instagramLink : "",
        twitterLink: portal.twitterLink ? portal.twitterLink : "",
        snapchatLink: portal.snapchatLink ? portal.snapchatLink : "",
        numberOfScreens: portal.numberOfScreens ? portal.numberOfScreens : 1,
        wifi: portal.wifi ? portal.wifi : false,
        commentry: portal.commentry ? portal.commentry : false,
        projector: portal.projector ? portal.projector : false,
        servesFood: portal.servesFood ? portal.servesFood : false,
        outdoorSeating: portal.outdoorSeating ? portal.outdoorSeating : false,
        tableBooking: portal.tableBooking ? portal.tableBooking : false,
        shisha: portal.shisha ? portal.shisha : false,
        privateRoom: portal.privateRoom ? portal.privateRoom : false,
        servesCofee: portal.servesCofee ? portal.servesCofee : false,
        seatBooking: portal.seatBooking ? portal.seatBooking : false,
        rating: portal.rating ? portal.rating : 0,
        workingHours: portal.workingHours
          ? portal.workingHours
          : pre.workingHours,
        logo: portal.logo ? portal.logo : null,
        pictures: portal.pictures ? portal.pictures : [],
      }));
      setState((pre) => ({
        ...pre,
        loading: false,
        lat: portal.lat,
        lng: portal.lng,
        userId: portal.userId,
        city: portal.city,
        region: portal.region,
        address: portal.address ? portal.address : "",
      }));
    } else {
      setState((pre) => ({
        ...pre,
        loading: false,
      }));
    }
  }, [location.state]);

  const removeImageHandler = (id) => {
    let picsCopy = [...editFormFields.pictures];

    picsCopy = picsCopy.filter((it) => it.id !== id);
    setEditFormFields((p) => ({
      ...p,
      pictures: picsCopy,
    }));
  };

  const handleSubmit = async (values) => {
    if (state.lat === 0) return alert("Location is required");
    if (!state.region || !state.city)
      return alert("Region and City is required");

    try {
      if (location.state) {
        const venuData = {
          ...location.state,
          ...editFormFields,
          ...values,
          lat: state.lat,
          lng: state.lng,
          userId: state.userId,
          address: state.address,
          city: state.city,
          region: state.region,
        };
        if (location.state && (location.state.email.trim() !== values.email.trim())) {
          uploadEmailFromAuth(location.state.email, values.email, location.state.password).then(async (emailRes) => {
            await adminUpdatedVenuePortal(venuData);
            editVenue(venuData);
            toast.success("Uploaded");
            navigate("/admin/venueportals");
          })
            .catch(emailErr => {
              alert("Error while changing email: " + emailErr.message);
            });
        } else {
          await adminUpdatedVenuePortal(venuData);
          editVenue(venuData);
          toast.success("Uploaded");
          navigate("/admin/venueportals");
        }
      } else {
        if (values.password === "" || values.confirmPassword === "")
          return alert("Password is required.");
        if (values.password !== values.confirmPassword)
          return alert("Password don't match.");
        const venueData = {
          ...values,
          logo: editFormFields.logo,
          pictures: editFormFields.pictures,
          lat: state.lat,
          lng: state.lng,
          address: state.address,
          city: state.city,
          region: state.region,
          rating: 0,
          adminVerified: true,
          isCompleted: true,
          status: true,
          subscriptions: [values.subscriptionBundle],
        };
        const res = await registerVenuePortal(venueData, true);
        addVenue(res);
        toast.success("Uploaded");
        navigate("/admin/venueportals");
      }


    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleModal = (e) => {
    setState((pre) => ({
      ...pre,
      showModel: !state.showModel,
    }));
  };
  const handleLocationSubmition = () => {
    toast.success("Selected successfully!");
    handleModal();
  };
  const cancelLocationHandler = () => {
    handleModal();
  };
  const handleLocation = (loc) => {
    setState((pre) => ({
      ...pre,
      lat: loc.lat,
      lng: loc.lng,
      address: loc.address,
    }));
  };

  const fileHandler = (e) => {
    if (!e.target.files[0]) return;
    setEditFormFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    toast.success("Uploaded!");
  };

  const statusHandler = (e, day) => {
    const whCopy = [...editFormFields.workingHours];
    const index = whCopy.findIndex((item) => item.day.eng === day);
    whCopy[index]["status"] = e.target.checked;
    setEditFormFields((prev) => ({
      ...prev,
      workingHours: whCopy,
    }));
  };

  const featureStatusHandler = (e) => {
    setEditFormFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const ApplytimeHandler = (day, e) => {
    const whCopy = [...editFormFields.workingHours];
    const index = whCopy.findIndex((item) => item.day.eng === day);
    whCopy[index][e.target.name] = e.target.value;
    setEditFormFields((prev) => ({
      ...prev,
      workingHours: whCopy,
    }));
  };

  const checkLiveUrl = (item) => {
    return item.image && item.image.size ? true : false;
  };

  const venuePhotoHandler = (e) => {
    if (!e.target.files[0]) return;
    const imgObj = {
      id: Math.random(),
      image: e.target.files[0],
    };
    setEditFormFields((prev) => ({
      ...prev,
      pictures: [...prev.pictures, imgObj],
    }));
  };

  const changeHandler = (e) => {
    setState((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };
  const changeHandlerForEditForm = (e) => {
    setEditFormFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const selectChangeHandler = (name, value) => {
    setPortalForm((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const timeApplyHandler = (e) => {
    if (
      editFormFields.sTime.length > 0 &&
      editFormFields.eTime.length > 0 &&
      e.target.checked
    ) {
      let hCopy = [...editFormFields.workingHours];
      hCopy = hCopy.map((item) => ({
        ...item,
        startTime: editFormFields.sTime,
        endTime: editFormFields.eTime,
      }));
      setEditFormFields((p) => ({
        ...p,
        workingHours: hCopy,
      }));
    }

    setEditFormFields((p) => ({
      ...p,
      applyAll: e.target.checked,
    }));
  };

  const regions = useMemo(() => {
    return data ? data.resgions : [];
  }, [data]);

  const cities = useMemo(() => {
    let i = 0;
    if (data && state.region) {
      i = data.resgions.findIndex((item) => item.province.eng === state.region);
    }
    return data ? data.resgions[i].cities : [];
  }, [data, state.region]);

  if (state.loading) return <Spinner />;
  return (
    <div className="container mb-5">
      <div className="secondary-container mx-5 px-5">
        <h3>Create your fanspot account</h3>
        <h5>Enter all the details</h5>
        <button
          className="btn btn-block btn-secondary my-3 px-5"
          onClick={handleModal}
        >
          Venue Location
        </button>
        <p>{state.address}</p>

        <h4>Venue Logo</h4>
        <div className="formInput formFileInput">
          {!editFormFields.logo && (
            <button className="btnFile">
              <span className="fileIcon">
                <MdAssignment />
              </span>
              Upload logo
            </button>
          )}
          <input
            onChange={fileHandler}
            type="file"
            name="logo"
            className="fileInput pointer"
            style={{ height: "200px", marginTop: "-6rem" }}
          />
          {editFormFields.logo && (
            <img
              width="100%"
              style={{ maxWidth: "200px" }}
              src={
                editFormFields.logo && editFormFields.logo.size
                  ? URL.createObjectURL(editFormFields.logo)
                  : editFormFields.logo
              }
              alt="logo"
            />
          )}
        </div>

        <h4 className="mt-5">Add Photo</h4>
        <div className="formInput formFileInput">
          <button className="btnFile">
            <span className="fileIcon">
              <MdAssignment />
            </span>
            Upload Photo
          </button>
          <input
            onChange={venuePhotoHandler}
            type="file"
            className="fileInput"
          />
        </div>

        <div className="row mb-5">
          {editFormFields.pictures.map((item, index) => (
            <div className="col-sm-12 col-md-6 col-lg-3 mt-5 gallery-img">
              <span onClick={() => removeImageHandler(item.id)}>
                <ImCross />
              </span>
              <img
                width="100%"
                height="250px"
                style={{ maxWidth: "200px" }}
                src={
                  checkLiveUrl(item)
                    ? URL.createObjectURL(item.image)
                    : item.image
                }
                alt={index}
                key={index}
              />
            </div>
          ))}
        </div>

        <Formik
          initialValues={portalForm}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {() => (
            <div>
              <Form>
                <Input name="venueName" type="text" label="Venue Name" />
                <div className="row">
                  <div className="col-md-6">
                    <Input name="firstName" label="First Name" />
                  </div>
                  <div className="col-md-6">
                    <Input name="lastName" label="Last Name" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      data={COUNTRIES}
                      searchKey="name"
                      searchValue="name"
                      name="country"
                      label="Country"
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="commercialRegister"
                      label="Commercial Register #"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div class="form-group">
                      <label for="region">Region</label>
                      <select
                        class="form-control"
                        onChange={changeHandler}
                        name="region"
                        value={state.region}
                        id="region"
                      >
                        <option value="">Select Region</option>
                        {regions.map((item) => (
                          <option value={item.province.eng}>
                            {item.province.eng}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {state.region && (
                      <div class="form-group">
                        <label htmlFor="city">City</label>
                        <select
                          class="form-control"
                          onChange={changeHandler}
                          name="city"
                          value={state.city}
                          id="city"
                        >
                          <option value="">Select City</option>
                          {cities.map((item) => (
                            <option value={item.eng}>{item.eng}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Input name="email" type="email" label="Email" />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="mangerPhoneNumber"
                      type="text"
                      label="Manager Phone Number"
                    />
                  </div>
                </div>

                <div className="pt-3">
                  <SelectSecondVariant
                    data={SUBSCRIPTIONS}
                    changeHandler={selectChangeHandler}
                    searchKey="name"
                    searchValue="name"
                    name="subscriptionBundle"
                    label="Subscription Bundle"
                  />
                </div>
                <Input
                  name="subscriptionExpiryDate"
                  type="date"
                  label="Expiry Date"
                />
                <div className="row mb-5 pb-5">
                  <div className="col-md-12" style={{ height: "100px" }}>
                    <Field
                      className="custom-select mt-2"
                      name="channelSubscription"
                      options={CHANNELS}
                      component={CustomSelect}
                      placeholder="Select multi Channels"
                      isMulti={true}
                    />
                  </div>
                  {/* <div className="col-md-6">
                    {!portalForm.subscriptionBundle.includes(
                      SUBSCRIPTIONS[0].name
                    ) && (
                      <Select
                        data={PAYMENTS_TYPES}
                        searchKey="name"
                        searchValue="name"
                        name="paymentFrequency"
                        label="Payment Frequency"
                      />
                    )}
                  </div> */}
                </div>
                {location.state && (
                  <>
                    <div className="formInput">
                      <p>
                        Venue Phone Number (this number will show up to users)
                      </p>
                      <input
                        type="text"
                        placeholder="Venue Phone Number"
                        name="venuePhoneNumber"
                        value={editFormFields.venuePhoneNumber}
                        onChange={changeHandlerForEditForm}
                      />
                    </div>

                    <div className="formInput">
                      <p>Website (Optional)</p>
                      <input
                        type="text"
                        placeholder="Website"
                        name="website"
                        value={editFormFields.website}
                        onChange={changeHandlerForEditForm}
                      />
                    </div>
                    <br />
                    <br />
                    <h5>Set Working hours</h5>

                    <td>
                      <BootstrapForm.Select
                        aria-label="Default select example"
                        value={editFormFields.sTime}
                        name="sTime"
                        onChange={changeHandlerForEditForm}
                      >
                        {TIMINGS.map((time) => (
                          <option value={time} key={time}>
                            {time}
                          </option>
                        ))}
                      </BootstrapForm.Select>
                    </td>
                    <td>
                      <span style={{ fontSize: "1.2rem", margin: "0 20px" }}>
                        TO
                      </span>
                    </td>
                    <td>
                      <BootstrapForm.Select
                        aria-label="Default select example"
                        value={editFormFields.eTime}
                        name="eTime"
                        onChange={changeHandlerForEditForm}
                      >
                        {TIMINGS.map((time) => (
                          <option value={time} key={time}>
                            {time}
                          </option>
                        ))}
                      </BootstrapForm.Select>
                    </td>
                    <div
                      style={{
                        maxWidth: "250px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "2rem 0",
                      }}
                    >
                      <label
                        style={{ fontSize: "1.2rem" }}
                        class="form-check-label ml-2"
                        htmlFor="applyall"
                      >
                        Apply To All?
                      </label>
                      <BootstrapForm.Check
                        type="switch"
                        id="custom-switch"
                        checked={state.applyAll}
                        onChange={timeApplyHandler}
                      />
                    </div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {editFormFields.workingHours.map((item, i) => (
                          <tr key={i}>
                            <td style={{ textTransform: "capitalize" }}>
                              {item.day.eng}
                            </td>

                            <td>
                              <BootstrapForm.Check
                                type="switch"
                                id="custom-switch"
                                checked={item.status}
                                onChange={(e) => statusHandler(e, item.day.eng)}
                              />
                            </td>

                            <td>
                              <BootstrapForm.Select
                                aria-label="Default select example"
                                value={item.startTime}
                                name="startTime"
                                onChange={(e) => ApplytimeHandler(item.day.eng, e)}
                              >
                                {TIMINGS.map((time, index) => (
                                  <option value={time} key={i + time}>
                                    {time}
                                  </option>
                                ))}
                              </BootstrapForm.Select>
                            </td>
                            <td>
                              <span style={{ fontSize: "1.2rem" }}>TO</span>
                            </td>
                            <td>
                              <BootstrapForm.Select
                                aria-label="Default select example"
                                value={item.endTime}
                                name="endTime"
                                onChange={(e) => ApplytimeHandler(item.day.eng, e)}
                              >
                                {TIMINGS.map((time, index) => (
                                  <option value={time} key={i + time}>
                                    {time}
                                  </option>
                                ))}
                              </BootstrapForm.Select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="row mt-5">
                      <h5>Add social media links</h5>

                      <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                        <div className="formInput">
                          <p>Faebook</p>
                          <input
                            type="text"
                            placeholder="Faebook"
                            name="facebookLink"
                            value={editFormFields.facebookLink}
                            onChange={changeHandlerForEditForm}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                        <div className="formInput">
                          <p>Instagram</p>
                          <input
                            type="text"
                            placeholder="Instagram"
                            name="instagramLink"
                            value={editFormFields.instagramLink}
                            onChange={changeHandlerForEditForm}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                        <div className="formInput">
                          <p>Twitter</p>
                          <input
                            type="text"
                            placeholder="Twitter"
                            name="twitterLink"
                            value={editFormFields.twitterLink}
                            onChange={changeHandlerForEditForm}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                        <div className="formInput">
                          <p>Snapchat</p>
                          <input
                            type="text"
                            placeholder="Snapchat"
                            name="snapchatLink"
                            value={editFormFields.snapchatLink}
                            onChange={changeHandlerForEditForm}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row profileMain">
                      <div className="col-sm-12 col-md-12 col-lg-6 p-2">
                        <h6 className="mb-5 mt-5">
                          Number of screens
                          <span className="noOfScreenBadge">
                            <BootstrapForm.Select
                              aria-label="Default select example"
                              name="numberOfScreens"
                              onChange={changeHandlerForEditForm}
                            >
                              <option value="1">1</option>
                              <option value="2-5">2-5</option>
                              <option value="5-10">5-10</option>
                              <option value="+10">+10</option>
                            </BootstrapForm.Select>
                          </span>
                        </h6>

                        <div className="profileMain_portal">
                          <p>Wifi</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            checked={editFormFields.wifi}
                            name="wifi"
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Projector</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="projector"
                            checked={editFormFields.projector}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Serves Food</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="servesFood"
                            checked={editFormFields.servesFood}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Outdoor seating</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="outdoorSeating"
                            checked={editFormFields.outdoorSeating}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Table Booking</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="tableBooking"
                            checked={editFormFields.tableBooking}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Shisha</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="shisha"
                            checked={editFormFields.shisha}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Private Room</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="privateRoom"
                            checked={editFormFields.privateRoom}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Serves Coffee</p>

                          <BootstrapForm.Check
                            type="switch"
                            id="custom-switch"
                            name="servesCofee"
                            checked={editFormFields.servesCofee}
                            onChange={featureStatusHandler}
                          />
                        </div>

                        <div className="profileMain_portal">
                          <p>Seat Booking</p>

                          <BootstrapForm.Check
                            type="switch"
                            name="seatBooking"
                            id="custom-switch"
                            checked={editFormFields.seatBooking}
                            onChange={featureStatusHandler}
                          />
                        </div>
                        <div className="profileMain_portal">
                          <p>Commentry</p>

                          <BootstrapForm.Check
                            type="switch"
                            name="commentry"
                            id="custom-switch"
                            checked={editFormFields.commentry}
                            onChange={featureStatusHandler}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 mt-5 p-5">
                        <div className="wifiPassword">
                          <div className="password_text">
                            <h6>Wifi Passowrd</h6>
                            <p>
                              (Password will only <br /> show up to checked in
                              users)
                            </p>
                          </div>

                          <div className="password_input">
                            <input
                              type="text"
                              name="password"
                              value={editFormFields.password}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="Custom_container">
                        <div className="row">
                          <div className="col">
                            <div className="feature-previeW-container">
                              <span className="numberofscreen-icon">
                                {editFormFields.numberOfScreens}
                              </span>
                            </div>
                            <p>Screen</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.projector ? (
                                  <img
                                    alt="alternatText"
                                    src="/assets/icons/projector.png"
                                  />
                                ) : (
                                  <TbScreenShareOff />
                                )}
                              </span>
                            </div>
                            <p>Projector</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.wifi ? (
                                  <BsWifi />
                                ) : (
                                  <BsWifiOff />
                                )}
                              </span>
                            </div>
                            <p>Wifi</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.servesFood ? (
                                  <img
                                    alt="alternatText"
                                    src="/assets/icons/food.png"
                                  />
                                ) : (
                                  <MdNoFood />
                                )}
                              </span>
                            </div>
                            <p>Food</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.outdoorSeating ? (
                                  <img
                                    alt="alternatText"
                                    src="/assets/icons/outdoor.png"
                                  />
                                ) : (
                                  <MdCameraOutdoor />
                                )}
                              </span>
                            </div>
                            <p>Outdoor</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.tableBooking ? (
                                  <BsTable />
                                ) : (
                                  <TbTableOff />
                                )}
                              </span>
                            </div>
                            <p>Book Table</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.shisha ? (
                                  <img src="/assets/icons/ShishaIcon.png" alt="alternatText" />
                                ) : (
                                  <MdOutlineCasino />
                                )}
                              </span>
                            </div>
                            <p>Shisha</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.privateRoom ? (
                                  <RiGitRepositoryPrivateFill />
                                ) : (
                                  <MdPublicOff />
                                )}
                              </span>
                            </div>
                            <p>Private Room</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.servesCofee ? (
                                  <AiOutlineCoffee />
                                ) : (
                                  <TbCoffeeOff />
                                )}
                              </span>
                            </div>
                            <p>Coffee</p>
                          </div>

                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.seatBooking ? (
                                  <MdAirlineSeatReclineNormal />
                                ) : (
                                  <MdOutlineSignalCellularNoSim />
                                )}
                              </span>
                            </div>
                            <p>Seat Booking</p>
                          </div>
                          <div className="col">
                            <div className="feature-previeW-container">
                              <span>
                                {editFormFields.commentry ? (
                                  <Commentry />
                                ) : (
                                  <NoCommentry />
                                )}
                              </span>
                            </div>
                            <p>Commentry</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!location.state && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          name="password"
                          type="password"
                          label="Password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          name="confirmPassword"
                          type="password"
                          label="Confirm Password"
                        />
                      </div>
                    </div>
                  </>
                )}
                <button className="btn btn-success mt-5" type="submit">
                  {props.loading ? (
                    <div class="spinner-border text-light" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>

      <Modal show={state.showModel} onHide={handleModal} size="lg">
        <Modal.Header>
          <Modal.Title>Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Map1
            selectedLocation={handleLocation}
            lat={state.lat}
            lng={state.lng}
          />
        </Modal.Body>
        <Modal.Footer style={{ zIndex: "123", marginTop: "3rem" }}>
          <Button
            className="mt-3"
            variant="secondary"
            onClick={cancelLocationHandler}
          >
            Cancel
          </Button>
          <Button
            className="mt-3"
            variant="success"
            onClick={handleLocationSubmition}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
