import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import {
  deleteAccountHandler,
  sendPassMail,
  updateAccountSetting,
} from "../../../Firebase/apis";
import useStore from "../../../store/store";

export const Settings = () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const [state, setState] = useState({
    venueName: "",
    mangerPhoneNumber: "",
  });
  const [reason, setReason] = useState("");
  const [showPassModel, setPassMdoel] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const { locale } = useStore((st) => st);

  const handleDeleteModal = () => {
    setDeleteModal(!showDeleteModal);
  };
  const handlePassworModal = () => {
    setPassMdoel(!showPassModel);
  };

  useEffect(() => {
    setState({
      venueName: portal.venueName,
      mangerPhoneNumber: portal.mangerPhoneNumber,
    });
  }, []);

  const changeHandler = (e) => {
    setState((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = async () => {
    try {
      await updateAccountSetting(state);
      toast.success("Uploaded!!");
    } catch (error) {
      toast.error(error.message);
    }
  };
  const sendMail = async () => {
    const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
    try {
      await sendPassMail(portal.email);
      toast.success("send!!");
      handlePassworModal();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteHandler = async () => {
    try {
      await deleteAccountHandler(reason);
      toast.success("Added!!");
      handleDeleteModal();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30">{locale.venue_portal_settings}</h4>
      </div>

      <div className="profile_main p-3">
        <p>
          <span className="fs-17">
            {locale.change_your_account_information}
          </span>
          <span className="deleteAccount fs-15" onClick={handleDeleteModal}>
            <AiOutlineDelete /> {locale.delete_venue_account}
          </span>
        </p>

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
            <p className="fs-15" htmlFor="portalId">
              {locale.id}
            </p>
            <input
              className="fs-15"
              type="text"
              id="portalId"
              name="userId"
              value={portal.userId}
              disabled
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
            <p className="fs-15" htmlFor="clientId">
              {locale.name}
            </p>
            <input
              type="text"
              id="clientId"
              className="fs-15"
              name="venueName"
              value={state.venueName}
              onChange={changeHandler}
            />
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
            <p className="fs-15" htmlFor="email">
              {locale.email_not_changed}{" "}
            </p>
            <input
              type="email"
              id="email"
              value={portal.email}
              placeholder="john@gmail.com"
              disabled
              className="fs-15"
            />
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
            <p className="fs-15" htmlFor="phoneNo">
              {" "}
              {locale.manager_phone_number}{" "}
            </p>
            <input
              type="text"
              id="phoneNo"
              value={state.mangerPhoneNumber}
              placeholder="23672789"
              name="mangerPhoneNumber"
              className="fs-15"
              onChange={changeHandler}
            />
          </div>

          <p className="changePassword fs-15" onClick={handlePassworModal}>
            {locale.change_password}
          </p>

          <Modal show={showPassModel} onHide={handlePassworModal}>
            <Modal.Body className="modal-body">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
                <p className="fs-15" htmlFor="email">
                  {locale.email}
                </p>
                <input
                  className="fs-15"
                  type="email"
                  id="email"
                  value={portal.email}
                  disabled
                />
              </div>
              <button className="btn btn-custom mt-5 fs-15" onClick={sendMail}>
                {locale.portal_setting_submit}
              </button>
            </Modal.Body>
          </Modal>

          {/* DELETE MODEL */}
          <Modal show={showDeleteModal} onHide={handleDeleteModal}>
            <Modal.Body className="modal-body">
              <h2 className="modal-title">{locale.delete_venue_account}</h2>

              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="fs-15">
                    {locale.delete_reason}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    className="fs-15"
                    placeholder="Type your message..."
                    rows={5}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </Form.Group>
              </div>
              <button
                className="btn btn-custom mt-5 fs-15"
                onClick={deleteHandler}
              >
                {locale.send_admin}
              </button>
            </Modal.Body>
          </Modal>

          <div className="col-sm-12 col-md-12 col-lg-12 mt-5 fs-17">
            <button className="btn btn-custom" onClick={submitHandler}>
              {locale.save_changes}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
