import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../components/common/input";
import Select from "../../../components/common/select";
import {
  addContactUs,
  addNotificationForAdmin,
  getPortalMessage,
  getVenueContactUs,
} from "../../../Firebase/apis";
import TextArea from "./../../../components/common/textArea";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { CONTACT_REQUESTS, getContactTypes } from "../../../utils/data";
import useStore from "../../../store/store";
import { Tab, Tabs } from "react-bootstrap";
import useSWR from "swr";
import Table from "./../../../components/common/table";
import Spinner from "./../../../components/common/Spinner.jsx";

export const ContactUs = () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const { data: contacts } = useSWR("/api/venue/contacts", getVenueContactUs);
  const [loading, setLoading] = useState(false);
  const [data] = useState({
    title: "",
    name: portal.venueName,
    email: portal.email,
    details: "",
  });
  const { locale } = useStore((st) => st);

  const validations = yup.object().shape({
    title: yup.string().required(),
    name: yup.string().required(),
    details: yup.string().required(),
    email: yup.string().required(),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await addContactUs(values);
      const message =
        "New Query from" + values.name + " has been added, Please have a look!";
      const route = "admin/support";
      await addNotificationForAdmin(message, route);
      toast.success("Uploaded!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  useEffect(() => {
    getPortalMessage().then((data) => {
      if (data && data.message) {
        toast.info(data.message);
      }
    });
  }, []);
  const COLUMNS = [
    { label: "#", content: (item, index) => <span>{index}</span> },
    { path: "title", label: locale.title },
    { path: "details", label: locale.details },
    { path: "message", label: locale.reply },
  ];

  if (!contacts) return <Spinner />;
  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30">{locale.venue_portal_contact_us}</h4>
      </div>
      <Tabs
        defaultActiveKey="ContactForm"
        id="uncontrolled-tab-example"
        className="mb-3 fixtureNav"
      >
        <Tab
          eventKey="ContactForm"
          title={locale.contact_form}
          className="fs-20"
        >
          <div className="profile_main p-3">
            <p className="fs-17">{locale.you_can_query_your_problem}</p>
            <div className="row">
              <Formik
                initialValues={data}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form>
                    <Select
                      data={getContactTypes(locale)}
                      searchKey="name"
                      searchValue="name"
                      name="title"
                      label={locale.what_we_can_help_you_with}
                      placeholder={locale.portal_contact_us_what_we_can_help_you_with}
                    />
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Input
                          name="name"
                          type="text"
                          label={locale.your_name}
                          placeholder="Your name"
                        />
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Input
                          name="email"
                          type="email"
                          label={locale.email}
                          placeholder="Email"
                          disabled
                        />
                      </div>
                    </div>

                    <TextArea
                      name="details"
                      type="text"
                      label={locale.details}
                      placeholder={locale.contact_us_details_placeholder}
                    />

                    <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                      <button className="btn btn-custom fs-17" type="submit">
                        {loading ? (
                          <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          locale.portal_contact_us_btn_submit
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Tab>
        <Tab eventKey="Inbox" title={locale.inbox} className="fs-20">
          <Table data={contacts} coloumns={COLUMNS} />
        </Tab>
      </Tabs>
    </div>
  );
};
