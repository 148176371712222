import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar";
import "./Home.css";
import Footer from "../../../components/Footer";
import Spinner from "../../../components/common/Spinner.jsx";
import { getHomeData } from "../../../Firebase/apis";
import { Fixtures as FComponent } from "./../../../components/fixtures/index";
import useSWR from "swr";
import { useState } from "react";
import { VenuesList } from "./venuesList";
import { SingleVenue } from "./singleVenue";
import heroImage from "../../../assets/hero.png";
import PlayStoreIcon from "../../../assets/playstore.png";
import AppstoreIcon from "../../../assets/appstore.png";
import { AiFillStar } from "react-icons/ai";
import { TfiLocationPin } from "react-icons/tfi";
import { getMonthDates } from "../../../utils/data";
import {
  HiOutlineArrowCircleLeft,
  HiOutlineArrowCircleRight,
} from "react-icons/hi";
import { useMemo } from "react";
import useStore from "../../../store/store";

export const Home = () => {
  const currentDate = new Date();
  const { data, error: err } = useSWR("/client/get/getHomeData", getHomeData);
  const [activeState, setActiveState] = useState("");
  const [vReservations, setVReservation] = useState([]);
  const [limit, setLimit] = useState(8);
  const [venue, setVenue] = useState(null);
  const [activeMatch, setMatch] = useState(null);
  const [activeindex, setActiveIndex] = useState(0);
  const { locale } = useStore((st) => st);

  const clickHandler = (url) => {
    const el = document.createElement("a");
    el.href = url;
    el.setAttribute("target", "_blank");
    el.click();
  };

  const offerVenuesHandler = (reservations, match) => {
    setActiveState("venues");
    const filterVenues = [];
    for (let i = 0; i < reservations.length; i++) {
      for (let j = 0; j < data.vanuues.length; j++) {
        const venue = data.vanuues[j];
        if (venue.userId === reservations[i]) {
          filterVenues.push(venue);
        }
      }
    }
    setMatch(match);
    setVReservation(filterVenues);
  };

  const venueClickHandler = (item) => {
    setVenue(item);
    setActiveState("singleVenue");
  };

  const backHandler = () => {
    setActiveState("");
  };

  const decreamentLimit = () => {
    if (activeindex > 0) {
      setActiveIndex(activeindex - 1);
    }
  };

  const increamentLimit = () => {
    if (activeindex < 29) {
      setActiveIndex(activeindex + 1);
    }
  };

  useEffect(() => {
    const elements = document.getElementsByClassName("calendarDate");
    if (elements.length > 0) {
      elements[
        activeindex < 6 || activeindex > 24 ? activeindex : activeindex + 4
      ].scrollIntoView();
    }
  }, [activeindex, data]);
  const activeDateMatches = useMemo(() => {
    const activeDate = new Date(getMonthDates()[activeindex].fullDate);
    return data
      ? data.matches.filter(
          (m) => m.matchDate === activeDate.toLocaleDateString()
        )
      : [];
  }, [activeindex, data]);

  if (!data && !err) return <Spinner />;
  return (
    <div className="home-container">
      <Navbar loginLink="/portal" client={true} clientHome={true} />
      <section className="hero">
        <div className="hero-section">
          <div className="row" style={{ margin: "100px 50px" }}>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="hero_content">
                <h1>{locale.hate_turning_to_venue}</h1>
                <h5>{locale.find_venues_nearby}</h5>
                <p>{locale.download_fanspot_app_now}</p>
                <div className="storeicons">
                  <div className="storeicons-wrapper">
                    <img
                      src={PlayStoreIcon}
                      alt="GooglePlaystore"
                      className="pointer"
                      onClick={() =>
                        clickHandler(
                          "https://play.google.com/store/games?pli=1"
                        )
                      }
                    />
                    <img
                      src={AppstoreIcon}
                      alt="Appstore"
                      className="pointer"
                      onClick={() =>
                        clickHandler("https://www.apple.com/store")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="hero-img mt-3">
                <img
                  src={heroImage}
                  className="img-fluid mb-4"
                  alt="alternatText"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {activeState.length < 1 ? (
        <div className="container fixture-client-container">
          <h1>{locale.upcomming_fixture}</h1>
          <div className="fixture-calender-wrapper">
            <span className="left-arrow pointer" onClick={decreamentLimit}>
              <HiOutlineArrowCircleLeft />
            </span>
            <div className="fixtureCalendar">
              {getMonthDates().map((item, index) => (
                <div
                  key={item.day}
                  onClick={() => setActiveIndex(index)}
                  className={
                    index === activeindex
                      ? "calendarDate active"
                      : "calendarDate"
                  }
                >
                  <h5>{item.date}</h5>
                  <p>{item.day.eng}</p>
                </div>
              ))}
            </div>
            <span className="right-arrow pointer" onClick={increamentLimit}>
              <HiOutlineArrowCircleRight />
            </span>
          </div>
          <FComponent
            data={activeDateMatches}
            offerVenuesHandler={offerVenuesHandler}
          />
        </div>
      ) : activeState === "venues" ? (
        <VenuesList
          backHandler={backHandler}
          data={vReservations}
          clickHandler={venueClickHandler}
        />
      ) : (
        venue && (
          <SingleVenue
            venue={venue}
            backHandler={backHandler}
            match={activeMatch}
          />
        )
      )}
      <div className="container venues-wrapper">
        <div className="venues-header">
          <h1>{locale.fanspot_venues}</h1>
          <p>{locale.find_venues_nearby}</p>
          <div className="venues-list row">
            {data.vanuues &&
              data.vanuues.slice(0, limit).map((item) => (
                <div className="venue-list-item col-md-3" key={item.userId}>
                  <img
                    src={item.logo ? item.logo : "/assets/download.png"}
                    alt="userImage"
                  />
                  <h6>{item.venueName}</h6>
                  <div className="venue-list-item-inner-container">
                    <div>
                      <TfiLocationPin />
                      <span>2.5km</span>
                    </div>
                    <div>
                      <AiFillStar fill="#ffef00" />
                      <span>{item.rating}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {data.vanuues.length > limit && (
            <button
              className="btn btn-primary"
              onClick={() => setLimit((p) => p + 4)}
            >
              {locale.load_more}
            </button>
          )}
        </div>
      </div>
      <div className="container story-wrapper">
        <div className="row">
          <div className="col-md-6 story-container">
            <h3>{locale.our_story}</h3>
            <p>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before final copy is available
            </p>
          </div>
          <div className="col-md-6 empty-container"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
