import { create } from "zustand";

const locale = sessionStorage.getItem("fanspot-locale");
const store = create((set) => ({
  portals: [],
  users: [],
  matches: [],
  locale: null,
  englishLocale: null,
  arrabicLocale: null,
  activeLanguage: locale ? locale : "Arrabic",
  setData: (portals, users, matches = []) => {
    set((state) => {
      return {
        ...state,
        portals,
        users,
        matches,
      };
    });
  },
  setUsers: (users) => {
    set((state) => {
      return {
        ...state,
        users,
      };
    });
  },
  setAppLocale: (arrabicLocale, englishLocale) => {
    set((state) => {
      return {
        ...state,
        englishLocale,
        arrabicLocale,
      };
    });
  },
  setLanguage: (language) => {
    set((state) => {
      return {
        ...state,
        activeLanguage: language,
      };
    });
  },
  setLocale: (locale) => {
    set((state) => {
      return {
        ...state,
        locale,
      };
    });
  },
  deleteVenue: (id) => {
    set((state) => {
      return {
        ...state,
        portals: state.portals.filter((item) => item.userId !== id),
      };
    });
  },
  editVenue: (data) => {
    set((state) => {
      const portalCopy = [...state.portals];
      const index = portalCopy.findIndex((item) => item.userId === data.userId);
      if (index >= 0) {
        portalCopy[index] = data;
      }
      return {
        ...state,
        portals: portalCopy,
      };
    });
  },
  addVenue: (data) => {
    set((state) => {
      const portalCopy = [...state.portals];
      portalCopy.push(data);
      return {
        ...state,
        portals: portalCopy,
      };
    });
  },
}));

export default store;
