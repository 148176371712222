import React from "react";
import {
  getVenueSuggestions,
  deleteSuggestion,
  submitMessageToUser,
} from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { downloadExcel } from "../../../utils/data";
import { Modal } from "react-bootstrap";

export const VenuesSuggestions = () => {
  const { data, error } = useSWR(
    "/admin/get/venuesuggestions",
    getVenueSuggestions
  );
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [model, setModel] = useState(false);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");
  const deleteHandler = async (id) => {
    const c = window.confirm("Are you sure to delete?");
    if (!c) return;
    try {
      await deleteSuggestion(id);
      toast.success("Deleted successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const COLUMNS = [
    {
      key: "name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      path: "city",
      label: "City",
    },
    {
      path: "userId",
      label: "User ID",
    },
    {
      path: "userName",
      label: "User",
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <button
          className="btn btn-primary"
          onClick={() => messageHandler(item.docID)}
        >
          Send Messsage
        </button>
      ),
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <button
          className="btn btn-danger"
          onClick={() => deleteHandler(item.docID)}
        >
          Delete
        </button>
      ),
    },
  ];

  const submitMessage = async () => {
    try {
      await submitMessageToUser(userId, message);
      setModel(false);
      toast.success("Uploaded");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const messageHandler = (id) => {
    setUserId(id);
    setModel(true);
  };

  function handleDownloadExcel() {
    const tData = data
      ? data.map((item) => ({
          docID: item.docID,
          name: item.name,
          city: item.city,
          userName: item.userName,
          userId: item.userId,
        }))
      : [];
    downloadExcel(tData, "suggestedVenues");
  }

  if (error) toast.error(error.message);
  if (!data) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Suggested Portals</h1>
        </div>
        <div className="btn btn-secondary my-2" onClick={handleDownloadExcel}>
          Exports Suggested Venues
        </div>
        <Table data={data.slice(0, itemsPerPage)} coloumns={COLUMNS} />
        {data.length <= 0 && <span>No suggestions added yet.</span>}

        {data.length > 0 && data.length > itemsPerPage && (
          <span className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => setItemsPerPage((c) => c + 10)}
            >
              <BsChevronDoubleDown />
            </button>
          </span>
        )}
      </div>
      <Modal show={model} onHide={() => setModel(false)}>
        <Modal.Body className="modal-body">
          <h2 className="modal-title">Send Message</h2>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <p htmlFor="contactNo"> Message </p>
            <input
              type="text"
              id="contactNo"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
            />
          </div>
          <button className="btn btn-custom mt-5" onClick={submitMessage}>
            Submit
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
