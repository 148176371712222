import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Input from "../common/input";
import "./Login.css";
import { Link } from "react-router-dom";
import useStore from "../../store/store";

const Login = (props) => {
  const { locale, activeLanguage } = useStore((st) => st);

  const initialState = {
    email: "",
    password: "",
  };

  const validations = yup.object().shape({
    email: yup.string().required(locale.venue_register_email_message),
    password: yup.string().required(locale.venue_register_password_message),
  });

  const handleSubmit = async (values) => {
    props.submitHandler(values);
  };

  return (
    <div>
      <div className="container">
        <div
          className={`login-form ${props.registerLink ? "portal-login" : ""}`}
        >
          <Formik
            initialValues={initialState}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {() => (
              <div>
                <h1 className={`mb-2 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}>{props.primaryTitle}</h1>
                <h5 className={`mb-3 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}>{props.secondaryTitle}</h5>
                <Form>
                  <Input name="email" type="email" label={locale.email} />
                  <Input
                    name="password"
                    type="password"
                    label={locale.password}
                  />
                  {props.registerLink && (
                    <p onClick={props.forgotHandler}>
                      {locale.forgot_your_password_then_click_here}
                    </p>
                  )}
                  <button
                    className="btn btn-block btn-success mt-3"
                    style={{ padding: "1rem 7rem" }}
                    type="submit"
                  >
                    {props.loading ? (
                      <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      locale.submit
                    )}
                  </button>
                </Form>
              </div>
            )}
          </Formik>
          {props.registerLink && (
            <Link to={props.registerLink} className="mt-3">
              {locale.need_an_account} <span>{locale.signup}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
