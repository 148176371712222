import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { createEvent, updateEvent } from "../../../Firebase/apis";
import { MdAssignment } from "react-icons/md";
import Input from "./../../../components/common/normalInput";
import Select from "./../../../components/common/normalSelect";
import useStore from "../../../store/store";
import { getCurrentDate } from "../../../utils/data";

const EventForm = ({ addEvent, setActiveKey, selectedItem, changeEvent }) => {
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [venues, setVenues] = useState([]);
  const { portals, matches } = useStore((state) => state);
  const [dataForm, setDataForm] = useState({
    name: "",
    nameInArrabic: "",
    minimumOrderPerPerson: 1,
    capacity: 1,
    date: 0,
    city: "",
    cityInArrabic: "",
    venue: "",
    match: "",
  });

  useEffect(() => {
    setVenues(portals.filter((it) => it.adminVerified));
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setDataForm({
        name: selectedItem.name,
        nameInArrabic: selectedItem.nameInArrabic
          ? selectedItem.nameInArrabic
          : "",
        minimumOrderPerPerson: selectedItem.minimumOrderPerPerson,
        capacity: selectedItem.capacity,
        date: selectedItem.date,
        city: selectedItem.city,
        cityInArrabic: selectedItem.cityInArrabic
          ? selectedItem.cityInArrabic
          : "",
        venue: selectedItem.venue,
        match: selectedItem.match,
      });
      setLogo(selectedItem.logo);
    }
    setLoading(false);
  }, [selectedItem]);

  const changeHandler = (e) => {
    console.log();
    setDataForm((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (selectedItem) {
        const res = await updateEvent(
          { ...dataForm, date: new Date(dataForm.date).getTime() },
          logo,
          selectedItem.docId,
          selectedItem.users
        );
        changeEvent(res);
        toast.success("Event updated!");
      } else {
        const res = await createEvent(
          { ...dataForm, date: new Date(dataForm.date).getTime() },
          logo
        );
        addEvent(res);
        toast.success("Event created!");
      }
      setLoading(false);
      setActiveKey("Events");
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const checkValidation = () => {
    if (
      dataForm.name.trim().length &&
      dataForm.city.trim().length &&
      dataForm.match &&
      dataForm.match.trim().length &&
      dataForm.venue &&
      dataForm.venue.trim().length &&
      dataForm.cityInArrabic.trim().length &&
      dataForm.nameInArrabic.trim().length
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="formInput formFileInput eventFileInput">
          {!logo && (
            <button className="btnFile">
              <span className="fileIcon">
                <MdAssignment />
              </span>
              Upload logo
            </button>
          )}
          <input
            onChange={(e) =>
              e.target.files.length && setLogo(e.target.files[0])
            }
            type="file"
            name="logo"
            className="fileInput pointer"
            style={{ height: logo ? "200px" : "80px" }}
          />
          {logo && (
            <img
              width="100%"
              src={logo && logo.size ? URL.createObjectURL(logo) : logo}
              alt="logo"
            />
          )}
        </div>

        <Input
          name="name"
          type="text"
          value={dataForm.name}
          label="Event Name"
          onChange={changeHandler}
        />
        <Input
          name="nameInArrabic"
          type="text"
          value={dataForm.nameInArrabic}
          label="Event Name in Arrabic"
          onChange={changeHandler}
        />
        <Input
          name="city"
          type="text"
          value={dataForm.city}
          label="Event City"
          onChange={changeHandler}
        />
        <Input
          name="cityInArrabic"
          type="text"
          value={dataForm.cityInArrabic}
          label="Event City in Arrabic"
          onChange={changeHandler}
        />
        <Select
          onChange={changeHandler}
          data={venues}
          searchKey="userId"
          searchValue="venueName"
          name="venue"
          value={dataForm.venue}
          label="Venue Name"
        />
        <div className="form-group">
          <label htmlFor="match" className="col-form-label font-weight-bold">
            Match
          </label>

          <select
            onChange={changeHandler}
            value={dataForm.match}
            name="match"
            className={`form-control`}
          >
            <option value="">Search Match</option>
            {matches.map((e, i) => (
              <option key={i} value={e.docId}>
                <span>{e.firstTeam && e.firstTeam.name}</span>
                <span> Vs </span>
                <span>{e.secondTeam && e.secondTeam.name}</span>
              </option>
            ))}
          </select>
        </div>
        <Input
          name="date"
          type="date"
          value={getCurrentDate(dataForm.date)}
          label="Date"
          onChange={changeHandler}
        />
        <Input
          name="capacity"
          type="number"
          value={dataForm.capacity}
          label="Capacity"
          onChange={changeHandler}
        />
        <Input
          name="minimumOrderPerPerson"
          type="number"
          value={dataForm.minimumOrderPerPerson}
          label="Minimum Order/Person"
          onChange={changeHandler}
        />

        <button
          className="btn btn-primary"
          disabled={checkValidation()}
          onClick={handleSubmit}
        >
          {loading ? (
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default EventForm;
