import React, { useEffect } from "react";
import { approveReqHandler, getMembershipReq } from "../../../Firebase/apis";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { SUBSCRIPTIONS } from "../../../utils/data";
import Spinner from "./../../../components/common/Spinner.jsx";

export const MembershipRequests = () => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [reqs, setReqs] = useState([false]);

  useEffect(() => {
    getMembershipReq()
      .then((res) => {
        setReqs(res);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, []);

  const approveHandler = async (item) => {
    try {
      const confirm = window.confirm("Are you sure, you want to Approve?");
      if (confirm) {
        setLoading(true);
        let subscriptionExpiryDate = new Date();
        if (
          item.subscriptionRequest &&
          item.subscriptionRequest.includes("Trial")
        ) {
          item.subscriptionBundle = SUBSCRIPTIONS[1].name;
          item.subscriptions = [SUBSCRIPTIONS[1].name];
          item.isTrialReqTaken = true;
          subscriptionExpiryDate = new Date(
            subscriptionExpiryDate.setMonth(
              subscriptionExpiryDate.getMonth() + 1
            )
          ).toLocaleDateString();
        } else {
          subscriptionExpiryDate = new Date(
            subscriptionExpiryDate.setFullYear(
              subscriptionExpiryDate.getFullYear() + 1
            )
          ).toLocaleDateString();
        }
        setLoading(true);
        await approveReqHandler({ ...item, subscriptionExpiryDate });
        setReqs((p) => p.filter((st) => st.docID !== item.docID));
        toast.success("Approved");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const COLUMNS = [
    {
      path: "email",
      label: "User Email",
    },
    {
      path: "venueName",
      label: "User Name",
    },
    {
      path: "subscriptionBundle",
      label: "Subscription Bundle",
    },
    {
      path: "mangerPhoneNumber",
      label: "Manger PhoneNumber",
    },
    {
      path: "subscriptionRequest",
      label: "subscription Request",
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => approveHandler(item)}
          className="btn btn-secondary"
        >
          Approve
        </span>
      ),
    },
  ];

  if (loading) return <Spinner />;
  return (
    <div className="container">
      <div className="secondary-container">
        <h3 className="my-5">Subscription Requests</h3>
        <Table data={reqs} coloumns={COLUMNS} />
        {reqs.length <= 0 && <span>No reqs added yet.</span>}
        {reqs.length > 0 && reqs.length > itemsPerPage && (
          <span className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => setItemsPerPage((c) => c + 10)}
            >
              <BsChevronDoubleDown />
            </button>
          </span>
        )}
      </div>
    </div>
  );
};
