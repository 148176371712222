import React from 'react';
import {
  getTeamsAndClubs,
  registerTeam,
  updateteam,
} from '../../../Firebase/apis';
import * as yup from 'yup';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import Input from '../../../components/common/input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Spinner from '../../../components/common/Spinner.jsx';
import useSWR from 'swr';
import { Form as BootstrapForm } from 'react-bootstrap';
import { MdAssignment } from 'react-icons/md';

export const TeamForm = () => {
  const [dataForm, setDataForm] = useState({
    name: '',
    flagName: '',
    nameInArabic: '',
    sortIndex: 1,
    isNationalTeam: true,
  });

  const [state, setState] = useState({
    id: '',
    loading: true,
    image: null,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useSWR('/admin/get/teamsandclubs', getTeamsAndClubs);

  useEffect(() => {
    if (location.state) {
      setState((c) => ({
        ...c,
        id: location.state.docId,
        image: location.state.image,
      }));
      setDataForm((c) => ({
        name: location.state.name,
        flagName: location.state.flagName,
        nameInArabic: location.state.nameInArabic,
        sortIndex: location.state.sortIndex ? location.state.sortIndex : 1,
        isNationalTeam: location.state.isNationalTeam,
      }));
    }
    setState((c) => ({ ...c, loading: false }));
  }, []);

  const validations = yup.object().shape({
    name: yup.string().required(),
    nameInArabic: yup.string().required(),
    isNationalTeam: yup.boolean().required(),
    sortIndex: yup.number().required(),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading();
      if (!state.image && dataForm.flagName.length === 0)
        return alert('Either image or flag name is required');
      if (location.state) {
        await updateteam({
          ...values,
          isNationalTeam: dataForm.isNationalTeam,
          id: state.id,
          image: state.image,
        });
      } else {
        await registerTeam({
          ...values,
          isNationalTeam: dataForm.isNationalTeam,
          image: state.image,
        });
      }
      navigate('/admin/teams');
    } catch (error) {
      setLoading();
      alert(error.message);
    }
  };

  const fileHandler = (e) => {
    setState((pre) => ({
      ...pre,
      image: e.target.files[0],
    }));
  };
  const setLoading = () => {
    setState((prev) => ({ ...prev, loading: !prev.loading }));
  };

  const teamStatusHandler = (e) => {
    setDataForm((pre) => ({
      ...pre,
      isNationalTeam: e.target.checked,
    }));
  };

  if (state.loading || !data) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <Formik
          initialValues={dataForm}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {() => (
            <div>
              <h1 className="mb-2">{location.state ? 'Edit ' : 'Add '} Team</h1>
              <div className="formInput formFileInput">
                <button className="btnFile">
                  <span className="fileIcon">
                    <MdAssignment />
                  </span>
                  Upload logo
                </button>
                <input
                  onChange={fileHandler}
                  type="file"
                  name="logo"
                  className="fileInput"
                />
                {state.image && (
                  <img
                    width="100%"
                    height="200px"
                    src={
                      state.image.size
                        ? URL.createObjectURL(state.image)
                        : state.image
                    }
                    alt="logo"
                  />
                )}
              </div>
              <div className="profileMain_portal">
                <h3 className="font-weight-bold">Is National Team</h3>

                <BootstrapForm.Check
                  type="switch"
                  id="custom-switch"
                  checked={dataForm.isNationalTeam}
                  name="isNationalTeam"
                  onChange={teamStatusHandler}
                />
              </div>

              <Form>
                <Input name="name" type="text" label="Name" />
                <Input name="flagName" type="text" label="Flag Name" />
                <p>
                  Not: Make sure name is correct and don't mention extension e.g
                  (.png)
                </p>
                <Input name="nameInArabic" type="text" label="Name In Arabic" />
                <Input
                  name="sortIndex"
                  min="1"
                  type="number"
                  label="Priority"
                />
                <button
                  className="btn btn-block btn-success mt-3 px-5"
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
