import React from "react";
import { Button } from "react-bootstrap";
import { FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import FooterImage from "../../assets/logo2.png";
import Logo from "../../assets/fanspot-vision.png";
import PlayStoreIcon from "../../assets/playstore.png";
import AppstoreIcon from "../../assets/appstore.png";
import useStore from "../../store/store";

const Footer = () => {
  const { locale } = useStore((state) => state);
  const clickHandler = (url) => {
    const el = document.createElement("a");
    el.href = url;
    el.setAttribute("target", "_blank");
    el.click();
  };
  return (
    <section className="footer pt-5 pb-2">
      <div style={{ width: "100%", margin: "0 2rem" }}>
        <div className="row">
          <div className="col-sm-12 col-lg-3 text-center">
            <img
              src={FooterImage}
              className="footer-logo-1 img-fluid pt-3"
              alt="main-logo"
            />
            <div className="footer-logo-2-wrapper">
              <img
                src={Logo}
                className="f-logo2 pointer"
                onClick={() => clickHandler("https://www.vision2030.gov.sa/")}
                alt="vision-logo"
              />
              <img
                src={
                  "https://www.molakhas.net/assets/frontend/images/maroof.png"
                }
                className="f-logo3 pointer"
                onClick={() => clickHandler("https://maroof.sa/")}
                alt="maroof-logo"
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-4">
            <p style={{ color: "#fff", fontWeight: "500", fontSize: "16px" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard18 dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book
            </p>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="footerLinks">
              <span>
                <Link to="/pricing">{locale.pricing}</Link>
                <Link to="/login">{locale.login}</Link>
              </span>
              <div className="btn-account">
                <a href="/portal?register=true">
                  <Button>{locale.create_venue_account}</Button>
                </a>
              </div>
            </div>
            <div className="storeicons-wrapper">
              <img
                src={PlayStoreIcon}
                alt="GooglePlaystore"
                className="pointer"
                onClick={() =>
                  clickHandler("https://play.google.com/store/games?pli=1")
                }
              />
              <img
                src={AppstoreIcon}
                alt="Appstore"
                className="pointer"
                onClick={() => clickHandler("https://www.apple.com/store")}
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-2">
            <div className="contact-info">
              <div className="contact-icons">
                <FaInstagram
                  className="icon pointer"
                  onClick={() =>
                    clickHandler("https://www.instagram.com/fanspotapp/")
                  }
                />
                <FaWhatsapp className="icon pointer" />
                <FaTwitter className="icon pointer" />
              </div>
              <Link to="/contactus">{locale.contact_us}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
