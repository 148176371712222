import React from "react";
import { addOffer, updateOffer } from "../../../Firebase/apis";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Spinner from "../../../components/common/Spinner.jsx";
import { FaExclamationCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { useCallback } from "react";
import useStore from "../../../store/store";

export const OfferForm = () => {
  const [data, setData] = useState({
    title: "",
    code: "",
    date: "",
    details: "",
  });
  const [state, setState] = useState({
    docID: "",
    loading: true,
    preview: false,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { locale } = useStore((st) => st);

  useEffect(() => {
    if (location.state) {
      setState((c) => ({
        ...c,
        docID: location.state.docID,
      }));
      setData((c) => ({
        ...c,
        title: location.state.title,
        code: location.state.code,
        details: location.state.details,
        date: getDate(location.state.date),
      }));
    }
    setState((c) => ({ ...c, loading: false }));
  }, []);

  const getDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  const previewHandler = () => {
    setState((p) => ({
      ...p,
      preview: !p.preview,
    }));
  };

  const handleSubmit = async (va) => {
    const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
    if (
      data.title.length <= 0 ||
      data.details.length <= 0 ||
      data.date.length <= 0 ||
      data.code.length <= 0
    ) {
      return toast.error(locale.all_fields_are_required);
    }
    try {
      setLoading();
      if (location.state) {
        await updateOffer({
          ...data,
          docID: state.docID,
          date: new Date(data.date).toISOString(),
          venueID: portal.userId,
        });
      } else {
        await addOffer({
          ...data,
          date: new Date(data.date).toISOString(),
          venueID: portal.userId,
        });
      }
      navigate("/portal/offers");
    } catch (error) {
      setLoading();
      alert(error.message);
    }
  };

  const setLoading = () => {
    setState((prev) => ({ ...prev, loading: !prev.loading }));
  };

  const changeHandler = (e) => {
    setData((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const getDMYDateFormat = useCallback(() => {
    if (data.date.length) {
      const d = new Date(data.date);
      const month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      const fdate = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      return d.getFullYear() + "-" + month + "-" + fdate;
    } else {
      return data.date;
    }
  }, [data.date]);

  const restrictPAstDates = () => {
    let dtToday = new Date();
    let month = dtToday.getMonth() + 1;
    let day = dtToday.getDate();
    let year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    return year + "-" + month + "-" + day;
  };

  if (state.loading) return <Spinner />;
  return (
    <div className="container">
      <div className="breadCrumb mt-2">
        <h4 className="fs-30">{locale.venue_portal_offers}</h4>
      </div>
      <div className="profile_main p-3 pt-5">
        <h5 className="fs-17">
          {locale.create_special_offer_to_redeem_in_venue}
        </h5>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="fs-15" htmlFor="title">
                {locale.title}
              </label>
              <input
                id="title"
                name="title"
                className={`form-control fs-15`}
                type="text"
                value={data.title}
                placeholder={locale.offer_title}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="fs-15" htmlFor="Date">
                {locale.valid_until}
              </label>
              <input
                id="Date"
                name="date"
                type="date"
                min={restrictPAstDates()}
                className={`form-control offerDate fs-15`}
                value={getDMYDateFormat()}
                placeholder="Validity Date"
                onChange={changeHandler}
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label className="fs-15" htmlFor="Details">
            {locale.details}
          </label>
          <textarea
            className={`form-control fs-15`}
            rows={3}
            name="details"
            type="text"
            value={data.details}
            placeholder={locale.offer_details}
            onChange={changeHandler}
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="fs-15" htmlFor="Code">
            {locale.offer_code}
          </label>
          <input
            id="Code"
            name="code"
            type="text"
            className={`form-control fs-15`}
            value={data.code}
            placeholder={locale.offer_code_placeholder_text}
            onChange={changeHandler}
          />
        </div>
        <div style={{ display: "flex", gap: "1rem" }} onClick={previewHandler}>
          <button className="btn btn-success px-3 fs-15" type="submit">
            {locale.preview}
          </button>

          <button
            className="btn btn-success px-3 fs-15"
            type="submit"
            onClick={handleSubmit}
          >
            {locale.portal_offer_btn_submit}
          </button>
        </div>

        {state.preview && (
          <>
            <h6 className="mt-5">{locale.preview}</h6>
            <div className="previewCard">
              <div className="proviewMainCard">
                <p>
                  {locale.valid}: {data.date}
                  <span className="exclamationIcon">
                    <FaExclamationCircle />
                  </span>
                </p>

                <h5>{data.title}</h5>
                <p className="text-center mt-5">
                  <button className="btn btn-offer fs-15">
                    {locale.check_in_claim}
                  </button>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
