import React from "react";
import { deleteUser, verifyUser, bookingBlock } from "../../../Firebase/apis";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";
import { GrAddCircle } from "react-icons/gr";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { downloadExcel } from "../../../utils/data";
import { useMemo } from "react";
import useStore from "../../../store/store";

export const Users = () => {
  const { users, setUsers } = useStore((st) => st);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const deleteHandler = async (id) => {
    const c = window.confirm("Are you sure?");
    if (!c) return;
    try {
      await deleteUser(id);
      setUsers(users.filter((it) => it.userID !== id));
      toast.success("Deleted successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const statusChangeHandler = async (item) => {
    try {
      const confirm = window.confirm("Are you sure, you want to Verify?");
      if (confirm) {
        await verifyUser(item.userID, !item.isVerified);
        alert("Updated!");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const bookingBlockHandler = async (item) => {
    try {
      const confirm = window.confirm(
        item.bookingBlock
          ? "Are you sure, you want to un-block?"
          : "Are you sure, you want to block?"
      );
      if (confirm) {
        await bookingBlock(item.userID, !item.bookingBlock);
        const ucopy = [...users];
        const i = ucopy.findIndex((it) => it.userID === item.userID);
        if (i >= 0) {
          ucopy[i].bookingBlock = !item.bookingBlock;
          setUsers(ucopy);
        }
        alert("Updated successfully!");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  function handleDownloadExcel() {
    const tData = users.map((item) => ({
      name: item.name,
      email: item.email,
      image: item.image,
      country: item.country,
      nationalTeam: item.nationalTeam,
      password: item.password,
      phoneNumber: item.phoneNumber,
      isPhoneVerified: item.isPhoneVerified ? "Yes" : "No",
      year: item.year,
      allowMarketing: item.allowMarketing,
      userID: item.userID,
    }));
    downloadExcel(tData, "users");
  }

  const COLUMNS = [
    {
      path: "userID",
      label: "User ID",
    },
    {
      key: "name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      path: "email",
      label: "Email",
    },
    {
      path: "country",
      label: "Country",
    },
    {
      path: "phoneNumber",
      label: "Phone Number",
    },
    {
      label: "Table Booking",
      key: "changeStatus",
      content: (item) => (
        <div className="form-check form-switch">
          <input
            onChange={() => bookingBlockHandler(item)}
            checked={item.bookingBlock}
            type="checkbox"
            className="form-check-input"
            id={item.userID}
          />
          <label className="form-check-label" htmlFor={item.bookingBlock}>
            Un-Block/Block
          </label>
        </div>
      ),
    },
    {
      key: "changeStatus",
      content: (item) => (
        <div className="form-check form-switch">
          <input
            onChange={() => statusChangeHandler(item)}
            checked={item.isVerified}
            type="checkbox"
            className="form-check-input"
            id={item.userID}
          />
          <label className="form-check-label" htmlFor={item.userID}>
            Disable/Enable
          </label>
        </div>
      ),
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <Link to="/admin/users/add" state={item} className="btn btn-secondary">
          Edit
        </Link>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.userID)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];

  const filterUsers = useMemo(() => {
    return users
      ? users.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.phoneNumber.includes(searchTerm)
        )
      : [];
  }, [searchTerm, users]);

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Users</h1>
          <Link
            to="/admin/users/add"
            className="btn btn-outline-secondary d-flex justify-content-center align-items-center mb-3"
          >
            <span style={{ marginRight: "10px" }}>
              <GrAddCircle />
            </span>
            Add
          </Link>
        </div>
        <div className="mb-3">
          <input
            className={`form-control`}
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search users"
          />
        </div>

        <div className="btn btn-secondary my-3" onClick={handleDownloadExcel}>
          Exports Users
        </div>

        <Table data={filterUsers.slice(0, itemsPerPage)} coloumns={COLUMNS} />
        {users.length <= 0 && <span>No users added yet.</span>}

        {users.length > 0 && users.length > itemsPerPage && (
          <span className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => setItemsPerPage((c) => c + 10)}
            >
              <BsChevronDoubleDown />
            </button>
          </span>
        )}
      </div>
    </div>
  );
};
