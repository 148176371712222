import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { MdOutlineSearch } from "react-icons/md";
import { toast } from "react-toastify";
import useSWR from "swr";
import {
  addFaq,
  deleteFAQ,
  getFaqs,
  setFAQanswer,
  updateFAQ,
  updateFaq,
} from "../../../Firebase/apis";
import Spinner from "./../../../components/common/Spinner.jsx";

export const FAQS = () => {
  const { data, mutate } = useSWR("get/faqs", getFaqs);
  const [answer, setAnswer] = useState("");
  const [answerInArrabic, setAnswerInArrabic] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [reviewId, setReviewId] = useState("");
  const [appearIn, setAppearIn] = useState("");
  const [selectedQuestionID, setSelectedQuestionID] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionInArrabic, setNewQuestionInArrabic] = useState("");
  const [showModel, setModel] = useState(false);
  const [showAM, setShowAM] = useState(false);
  const [showNewQuestionModel, setShowNewQuestionModel] = useState(false);

  const submitanswer = async () => {
    try {
      if (!answer.length) return toast.error("Answer is required.");
      if (!answerInArrabic.length)
        return toast.error("Answer in arrabic is required.");
      await setFAQanswer(reviewId, answer, answerInArrabic);
      mutate();
      toast.success("Uploaded");
      setAnswer("");
      setAnswerInArrabic("");
      setModel(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const submitNewQuestionHandler = async () => {
    try {
      if (!newQuestion.length) return toast.error("Question is required.");
      if (!newQuestionInArrabic.length)
        return toast.error("Question in arrabic is required.");
      if (selectedQuestionID.length) {
        await updateFaq(selectedQuestionID, newQuestion, newQuestionInArrabic);
        setSelectedQuestionID("");
        toast.success("Updated");
      } else {
        await addFaq(newQuestion, newQuestionInArrabic);
        toast.success("Created");
      }
      mutate();
      setNewQuestionInArrabic("");
      setNewQuestion("");
      setShowNewQuestionModel(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const submitAppearance = async () => {
    try {
      await updateFAQ(reviewId, appearIn);
      mutate();
      toast.success("Uploaded");
      setAppearIn("");
      setShowAM(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const editHanlder = (item) => {
    setSelectedQuestionID(item.docID);
    setNewQuestion(item.question);
    setNewQuestionInArrabic(item.questionInArrabic);
    setShowNewQuestionModel(true);
  };
  const deleteHanlder = async (id) => {
    const c = window.confirm("Are you sure you want to delete it?");
    if (!c) return;
    try {
      await deleteFAQ(id);
      mutate();
      toast.success("deleted");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const answerHandler = (id, answer, answerInArrabic) => {
    if (answer || answerInArrabic) {
      setAnswer(answer);
      setAnswerInArrabic(answerInArrabic);
    }
    setReviewId(id);
    setModel(true);
  };
  const appearHandler = (item) => {
    console.log(item);
    setAppearIn(item.appearIn ? item.appearIn : "");
    setReviewId(item.docID);
    setShowAM(true);
  };

  const getDate = (t) => {
    const d = new Date(t);
    return d.toLocaleString();
  };

  const filterData = useMemo(() => {
    return (
      data &&
      data.filter((item) => {
        return (
          item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.answer.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [data, searchTerm]);
  if (!data) return <Spinner />;
  return (
    <div className="container">
      <div className="breadCrumb">
        <h4>Admin Portal | FAQS</h4>
      </div>
      <div className="profile_main p-3 pt-2">
        <div className="row">
          <div className="col-sm-12 col-md-10">
            <div className="fixtureHeadInput mt-3">
              <div>
                <span className="fixtureInputIcon">
                  <MdOutlineSearch />
                </span>
              </div>
              <div>
                <input
                  type="text"
                  className="fixtureCustomInput"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search for Question or Answer"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-2 mt-2">
            <button
              className="btn btn-secondary"
              onClick={() => {
                setShowNewQuestionModel(true);
                setNewQuestion("");
                setNewQuestionInArrabic("");
              }}
            >
              Add Question
            </button>
          </div>
        </div>
        <Table responsive className="mt-5 customTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Question</th>
              <th>Question (Arrabic)</th>
              <th>Answer</th>
              <th>Answer (Arrabic)</th>
              <th>Appear In</th>
              <th>Reply</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((item, i) => (
              <tr className="mt-5">
                <td>{i + 1}</td>
                <td>{getDate(item.time)}</td>
                <td>{item.question}</td>
                <td>{item.questionInArrabic}</td>
                <td>{item.answer}</td>
                <td>{item.answerInArrabic}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => appearHandler(item)}
                  >
                    Appear In
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      answerHandler(
                        item.docID,
                        item.answer,
                        item.answerInArrabic
                      )
                    }
                  >
                    Set Answer
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => editHanlder(item)}
                  >
                    Edit Question
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteHanlder(item.docID)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={showModel} onHide={() => setModel(false)}>
          <Modal.Body className="modal-body">
            <h2 className="modal-title">Answer to Question</h2>

            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <textarea
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder="Answer (English)"
                rows="5"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <textarea
                value={answerInArrabic}
                onChange={(e) => setAnswerInArrabic(e.target.value)}
                placeholder="Answer (Arrabic)"
                rows="5"
                className="form-control"
              />
            </div>
            <button className="btn btn-custom mt-5" onClick={submitanswer}>
              Submit
            </button>
          </Modal.Body>
        </Modal>

        <Modal show={showAM} onHide={() => setShowAM(false)}>
          <Modal.Body className="modal-body">
            <h2 className="modal-title">Appear In App/Portal/Both</h2>
            <div className="row mt-5">
              <div className="col-4">
                <div className="form-check form-check-inline d-flex mx-4">
                  <input
                    className="form-check-input mr-2"
                    type="checkbox"
                    id="App"
                    width="50px"
                    checked={appearIn.includes("App")}
                    onChange={() => setAppearIn("App")}
                  />
                  <label className="form-check-label" htmlFor="App">
                    App
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-check form-check-inline d-flex mx-4">
                  <input
                    className="form-check-input mr-2"
                    type="checkbox"
                    width="50px"
                    onChange={() => setAppearIn("Portal")}
                    id="Portal"
                    checked={appearIn.includes("Portal")}
                  />
                  <label className="form-check-label" htmlFor="Portal">
                    Portal
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-check form-check-inline d-flex mx-4">
                  <input
                    className="form-check-input mr-2"
                    type="checkbox"
                    onChange={() => setAppearIn("Both")}
                    id="Both"
                    width="50px"
                    checked={appearIn.includes("Both")}
                  />
                  <label className="form-check-label" htmlFor="Both">
                    Both
                  </label>
                </div>
              </div>
            </div>

            <button className="btn btn-custom mt-5" onClick={submitAppearance}>
              Submit
            </button>
          </Modal.Body>
        </Modal>
        <Modal
          show={showNewQuestionModel}
          onHide={() => setShowNewQuestionModel(false)}
        >
          <Modal.Body className="modal-body">
            <h2 className="modal-title">
              {selectedQuestionID.length ? "Edit" : "New"} Question
            </h2>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <textarea
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                placeholder="New Question (English)"
                rows="3"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <textarea
                value={newQuestionInArrabic}
                onChange={(e) => setNewQuestionInArrabic(e.target.value)}
                placeholder="New Question (Arrabic)"
                rows="3"
                className="form-control"
              />
            </div>
            <button
              className="btn btn-custom mt-5"
              onClick={submitNewQuestionHandler}
            >
              Submit
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
