import React from "react";

const ButtonLoader = () => {
  return (
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  );
};

export default ButtonLoader;
