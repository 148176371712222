import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../store/store";
import isMobileScreen from "../../hooks/isMobileScreen.jsx";

const Index = (props) => {
  const [collapse, setCollapse] = useState(false);
  const isMobile = isMobileScreen();
  const {
    setLocale,
    locale,
    setLanguage,
    englishLocale,
    arrabicLocale,
    activeLanguage
  } = useStore((st) => st);

  const changeHandler = (e) => {
    if (!e) return;
    if (e.includes("Arrabic")) {
      setLocale(arrabicLocale);
      sessionStorage.setItem("fanspot-locale", e);
      setLanguage(e);
    } else if (e.includes("English")) {
      sessionStorage.setItem("fanspot-locale", e);
      setLocale(englishLocale);
      setLanguage(e);
    }
  };
  const loginPortal = () => {
    window.open("/portal", "_self");
  };
  return (
    <div className="header" style={{ height: isMobile && collapse ? "650px" : "90px" }}>
      <div className="container-fluid navSecondVariant">
        <nav className="navbar navbar-expand-lg  bg-custom">
          <Link
            className="navbar-brand"
            to={props.buisness ? "/buisness" : "/"}
          >
            <img src="/assets/venueHome/logo.png" width="150px" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setCollapse((p) => !p)}
          >
            <span className="navbar-toggler-icon">
              <img
                className="toggleImage"
                src="/assets/venueHome/menu.png"
                height="20px"
                alt=""
              />
            </span>
          </button>

          {!isMobile ? (<div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <div className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alight-pc-right" : "alight-pc-left"}`}>
                <li className="nav-item active">
                  <a className="nav-link" href={props.pricing ? "/?buisness=true" : "#home"}>
                    {locale.home}
                  </a>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link" to="/pricing">
                    {locale.pricing}
                  </Link>
                </li>
                {
                  !props.pricing && (
                    <>
                      <li className="nav-item active">
                        <a className="nav-link" href="#benifits">
                          {locale.benifits}
                        </a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" href="#getting-started">
                          {locale.getting_started}
                        </a>
                      </li>

                      <li className="nav-item active">
                        <a className="nav-link" href="#faqs">
                          {locale.faqs}
                        </a>
                      </li>
                    </>
                  )
                }
              </div>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#test"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {sessionStorage.getItem("fanspot-locale")
                    ? sessionStorage
                      .getItem("fanspot-locale")
                      .includes("English")
                      ? "English"
                      : "العربية"
                    : "English"}
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <option
                    className="dropdown-item"
                    onClick={() => changeHandler("English")}
                  >
                    English
                  </option>
                  <option
                    className="dropdown-item"
                    onClick={() => changeHandler("Arrabic")}
                  >
                    العربية
                  </option>
                </div>
              </li>

              <li className="nav-item active">
                <a className="nav-link" href="/portal?register=true">
                  {locale.create_venue_account}
                </a>
              </li>

              <li className="nav-item">
                <button
                  onClick={loginPortal}
                  className="btn btn-secondary px-5"
                >
                  {locale.login}
                </button>
              </li>
            </ul>
          </div>) : collapse ? (
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active" onClick={() => setCollapse(false)}>
                  <a className="nav-link" href="#home">
                    {locale.home}
                  </a>
                </li>
                <li className="nav-item active" onClick={() => setCollapse(false)}>
                  <Link className="nav-link" to="/pricing">
                    {locale.pricing}
                  </Link>
                </li>
                <li className="nav-item active" onClick={() => setCollapse(false)}>
                  <a className="nav-link" href="#benifits">
                    {locale.benifits}
                  </a>
                </li>
                <li className="nav-item active" onClick={() => setCollapse(false)}>
                  <a className="nav-link" href="#getting-started">
                    {locale.getting_started}
                  </a>
                </li>

                <li className="nav-item active" onClick={() => setCollapse(false)}>
                  <a className="nav-link" href="#faqs">
                    {locale.faqs}
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#test"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {sessionStorage.getItem("fanspot-locale")
                      ? sessionStorage
                        .getItem("fanspot-locale")
                        .includes("English")
                        ? "English"
                        : "العربية"
                      : "العربية"}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <option
                      className="dropdown-item"
                      onClick={() => {
                        changeHandler("English");
                        setCollapse(false)
                      }}
                    >
                      English
                    </option>
                    <option
                      className="dropdown-item"
                      onClick={() => {
                        changeHandler("Arrabic");
                        setCollapse(false)
                      }}
                    >
                      العربية
                    </option>
                  </div>
                </li>

                <li className="nav-item active">
                  <a className="nav-link" href="/portal?register=true">
                    {locale.create_venue_account}
                  </a>
                </li>

                <li className="nav-item">
                  <button
                    onClick={loginPortal}
                    className="btn btn-secondary px-5"
                  >
                    {locale.login}
                  </button>
                </li>
              </ul>
            </div>
          ) : ""}
        </nav>
      </div>
    </div>
  );
};

export default Index;
