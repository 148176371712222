import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import locale from "../../../utils/locale.json";

export const VenuesList = ({ data, clickHandler, backHandler }) => {
  return (
    <div className="container venue-list" style={{ marginTop: "5rem" }}>
      <span onClick={() => backHandler()}>
        <IoArrowBackCircleOutline />
      </span>
      <h3 className="mb-3">{locale.venue_list}</h3>
      <div
        className="row"
        style={{
          marginBottom: data.length < 3 ? "220px" : "10px",
        }}
      >
        {data.length > 0 ? (
          data.map((item) => {
            return (
              <div
                className="col-md-4 pointer"
                onClick={() => clickHandler(item)}
              >
                <div className="row singleVenueItem">
                  <div className="col-md-4">
                    <img
                      src={item.logo ? item.logo : "assets/placeholder.jpg"}
                      alt={item.venueName}
                    />
                  </div>
                  <div className="col-md-8">
                    <h4>{item.venueName}</h4>
                    <h6>{item.mangerPhoneNumber}</h6>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <span>{locale.there_is_no_venues_message}</span>
        )}
      </div>
    </div>
  );
};
