import React from 'react';
import { FaUsers } from 'react-icons/fa';
import { StatisticsComponent } from '../../../components/Statistics/index.jsx';
import CardItem from './Item';
import useStore from '../../../store/store';

export const Dashboard = () => {
  const venues = useStore((state) => state.portals);
  const users = useStore((state) => state.users);


  return (
    <div className="container">
      <div className="secondary-container">
        <div className="breadCrumb">
          <h4> Dashboard</h4>
        </div>

        <div className="container">
          <div className="row">
            <CardItem
              title="Total Users"
              Icon={FaUsers}
              value={users.length}
              bgcolor="bg-purple"
              txtcolor="text-purple"
              borderColor="border-purple"
            />

            <CardItem
              title="Total Venues"
              Icon={FaUsers}
              value={venues.length}
              bgcolor="bg-green"
              txtcolor="text-green"
              borderColor="border-green"
            />
          </div>
        </div>

        <StatisticsComponent users={users} portals={venues} />
      </div>
    </div>
  );
};
