import React from "react";
import { StatisticsComponent } from "../../../components/Statistics";
import useStore from "../../../store/store";

export const Statistics = () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const { locale } = useStore((st) => st);

  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30">{portal.venueName} | {locale.statistics}</h4>
      </div>
      <StatisticsComponent portal={portal} />
    </div>
  );
};
