import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form } from "formik";
import Input from "./../../../components/common/input";
import Select from "./../../../components/common/select";
import {
  SUBSCRIPTIONS,
  getSubscriptions,
  userStatus,
} from "../../../utils/data";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  addMembershipReq,
  addNotificationForAdmin,
} from "../../../Firebase/apis";
import ButtonLoader from "../../../components/common/buttonLoader";
import useStore from "../../../store/store";

export const Memberships = () => {
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const [loading, setLoading] = useState(false);
  const [showPaymentsReq, setShowPaymentsReq] = useState(true);
  const { locale } = useStore((st) => st);
  const [initialState, setInitialState] = useState({
    venueName: user.venueName,
    subscriptionBundle: user.subscriptionBundle,
    subscriptionRequest: "",
    paymentFrequency: "",
    email: user.email,
    mangerPhoneNumber: user.mangerPhoneNumber,
    subscriptionExpiryDate: user.subscriptionExpiryDate
      ? user.subscriptionExpiryDate
      : new Date(),
  });

  const validations = yup.object().shape({
    venueName: yup.string().required().label("Venue Name"),
    mangerPhoneNumber: yup.string().required(),
    subscriptionBundle: yup.string().required(),
    email: yup.string().required(),
  });

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      if (initialState.subscriptionRequest.length <= 0)
        return toast.error(locale.select_payment_req);
      const res = await addMembershipReq(
        {
          ...values,
          subscriptionRequest: initialState.subscriptionRequest,
        },
        user.userId
      );
      setLoading(false);
      if (res) {
        alert(locale.req_submited_sccuessfully);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const getSubscriptionsHandler = () => {
    return getSubscriptions(locale).filter((it) => it.id !== 1);
  };
  const membershipReqHandler = (e) => {
    setInitialState((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
    if (e.target.value === locale.portal_membership_option_trial) {
      setShowPaymentsReq(false);
    } else {
      setShowPaymentsReq(true);
    }
  };
  const getMembershipRequests = () => {
    const MEMBERSHIP_REQUESTS = [
      { id: 1, name:  locale.portal_membership_option_trial},
      { id: 2, name: locale.portal_membership_option_renew },
      { id: 3, name: locale.portal_membership_option_upgrade },
    ];
    return user.isTrialReqTaken
      ? MEMBERSHIP_REQUESTS.filter((it) => it.id !== 1)
      : MEMBERSHIP_REQUESTS;
  };
  const getDate = (d) => {
    return new Date(d).toLocaleString();
  };

  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30">{locale.venue_portal_membership} </h4>
      </div>

      <div className="profile_main p-3">
        <p className="fs-17">
          {locale.view_or_upgrade_your_membership_detatil}
        </p>

        <div className="member_custom_table">
          <p className="fs-15">{locale.status}</p>
          <div className="custom_badge">
            <p className="fs-15">{locale.active}</p>
          </div>
        </div>

        <div className="member_custom_table">
          <p className="fs-15">{locale.subscription_package}</p>
          <div className="custom_badge">
            <p className="fs-15">{initialState.subscriptionBundle}</p>
          </div>
        </div>

        <div className="member_custom_table">
          <p className="fs-15">{locale.expiry_date}</p>
          <div className="custom_badge">
            <p className="fs-15">
              {userStatus && getDate(initialState.subscriptionExpiryDate)}
            </p>
          </div>
        </div>

        <p className="mt-5 fs-17">{locale.upgrade_or_renew_membership}</p>

        <Formik
          initialValues={initialState}
          validationSchema={validations}
          onSubmit={submitHandler}
        >
          {() => (
            <div>
              <Form>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Input
                      name="venueName"
                      type="text"
                      label={locale.venue_name}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Input
                      name="email"
                      type="text"
                      label={locale.email}
                      disabled
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Input
                      name="mangerPhoneNumber"
                      type="text"
                      label={locale.manager_phone_number}
                    />
                  </div>
                </div>
                <Select
                  data={getMembershipRequests()}
                  searchKey="name"
                  searchValue="name"
                  name="subscriptionRequest"
                  label={locale.select_req}
                  value={initialState.subscriptionRequest}
                  onChange={membershipReqHandler}
                />
                {showPaymentsReq && (
                  <>
                    <Select
                      data={getSubscriptionsHandler()}
                      searchKey="name"
                      searchValue="name"
                      name="subscriptionBundle"
                      label={locale.select_bundle}
                    />
                  </>
                )}
                <button
                  className="btn btn-success px-5 mt-3 fs-17"
                  onClick={submitHandler}
                >
                  {loading ? <ButtonLoader /> : locale.portal_membership_btn_submit}
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
