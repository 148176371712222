import { useMemo, useState } from "react";
import { Formik, Form } from "formik";
import React from "react";
import * as yup from "yup";
import useSWR from "swr";
import Select from "../../../components/common/select1";
import Spinner from "../../../components/common/Spinner.jsx";
import { getMatches } from "../../../Firebase/apis";
import Html2Canvas from "html2canvas";
import { checkPastDate, userStatus } from "../../../utils/data";
import FirstTemplate from "../../../components/Templates/FirstTemplate";
import SecondTemplate from "../../../components/Templates/SecondTemplate";
import ThirdTemplate from "../../../components/Templates/ThirdTemplate";
import FourthTemplate from "../../../components/Templates/FourthTemplate";
import FifthTemplate from "../../../components/Templates/FifthTemplate";
import SixthTemplate from "../../../components/Templates/SixthTemplate";
import SevenTemplate from "../../../components/Templates/SevenTemplate";
import EightTemplate from "../../../components/Templates/EightTemplate";
import NineTemplate from "../../../components/Templates/NineTemplate";
import TenTemplate from "../../../components/Templates/TenTemplate";
import useStore from "../../../store/store";
import "./SocialMedia.css";

const TEMPLATES = [
  {
    id: 0,
    image: "/assets/Template1.jpg",
    name: "First Template",
  },
  {
    id: 1,
    name: "Second Template",
    image: "/assets/Template2.png",
  },
  {
    id: 2,
    name: "Third Template",
    image: "/assets/Template3.png",
  },
  {
    id: 3,
    name: "Fourth Template",
    image: "/assets/Template4.png",
  },
  {
    id: 4,
    name: "Fifth Template",
    image: "/assets/Template5.png",
  },
  {
    id: 5,
    name: "Sixth Template",
    image: "/assets/Template6.png",
  },
  {
    id: 6,
    name: "Seven Template",
    image: "/assets/Template7.png",
  },
  {
    id: 7,
    name: "Eight Template",
    image: "/assets/Template8.png",
  },
  {
    id: 8,
    name: "Nine Template",
    image: "/assets/Template9.png",
  },
  {
    id: 9,
    name: "Ten Template",
    image: "/assets/Template10.png",
  },
];
export const SocialManagment = () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const { data } = useSWR("/get/getmatches", getMatches);
  const [match, setMatch] = useState(null);
  const [template, setTemplate] = useState("");
  const [loading, setLoading] = useState(false);
  const { locale, activeLanguage } = useStore((st) => st);

  const matchHandler = (e) => {
    const i = data.findIndex((item) => item.docId === e.target.value);
    setMatch(data[i]);
  };

  const [state] = useState({
    matchId: "",
  });

  const validations = yup.object().shape({
    matchId: yup.string().required(),
  });

  const templateHandler = (e) => {
    setTemplate(e.target.value);
  };

  const getTemplate = () => {
    setLoading(true);
    Html2Canvas(document.querySelector("#selectedTemplate")).then((canvas) => {
      fetch(canvas.toDataURL())
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], "temp.png", { type: "image/png" });
          const url = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = url;
          a.download = "template.png";
          a.click();
          setLoading(false);
        });
    });
  };

  const getTeamplates = () => {
    return !userStatus ? [] : TEMPLATES;
  };
  const checkUserStatus = () => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
  };

  const matches = useMemo(() => {
    return data
      ? data.filter(
        (item) =>
          !checkPastDate(item.matchDate) &&
          item.reservations &&
          item.reservations.includes(portal.userId)
      )
      : [];
  }, [data]);
  if (!data) return <Spinner />;
  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30">{locale.venue_portal_social_media_management}</h4>
      </div>
      <div className="profile_main p-3 pt-5">
        <h5 className="fs-17">{locale.promote_fixture_message} </h5>

        <div className="row">
          <Formik initialValues={state} validationSchema={validations}>
            {() => (
              <Form>
                <Select
                  onClick={checkUserStatus}
                  data={!userStatus ? [] : matches}
                  searchKey="docId"
                  name="docId"
                  label={locale.select_match}
                  placeholder={locale.portal_social_management_select_match_placeholder}
                  onChange={matchHandler}
                />
                <button type="submit" style={{ display: "none" }}></button>
              </Form>
            )}
          </Formik>

          <div className="col-sm-12 col-md-12">
            <div className="form-group">
              <label htmlFor="Template1" className="col-form-label fs-15">
                {locale.select_template}
              </label>

              <select
                className={`form-control fs-15`}
                onChange={templateHandler}
                value={template}
              >
                <option value="">{locale.select_template_social_management}</option>
                {getTeamplates().map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {template.length > 0 && match && (
          <div>
            <h5 className="mt-5 pt-3 fs-17">{locale.post_review}</h5>
            {template.toString() === "0" && <FirstTemplate match={match} />}
            {template.toString() === "1" && <SecondTemplate match={match} />}
            {template.toString() === "2" && <ThirdTemplate match={match} />}
            {template.toString() === "3" && <FourthTemplate match={match} />}
            {template.toString() === "4" && <FifthTemplate match={match} />}
            {template.toString() === "5" && <SixthTemplate match={match} />}
            {template.toString() === "6" && <SevenTemplate match={match} />}
            {template.toString() === "7" && <EightTemplate match={match} />}
            {template.toString() === "8" && <NineTemplate match={match} />}
            {template.toString() === "9" && <TenTemplate match={match} />}
            <button
              className="btn btn-success my-3 ml-3 py-2 px-5"
              onClick={getTemplate}
              disabled={!userStatus}
            >
              {loading ? (
                <div class="spinner-border text-light fs-17" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                locale.download
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
