import React from "react";
import * as yup from "yup";
import { useState } from "react";
import { Form, Field, Formik } from "formik";
import Input from "../common/input";
import { useEffect } from "react";
import { MdAssignment } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";
import Map from "../common/Map1";
import { toast } from "react-toastify";
import { foodsVarities, TIMINGS_DATA } from "../../utils/data";
import { CustomSelect } from "../common/MultiSelect";
import { ImCross } from "react-icons/im";
import Select from "./../common/select";
import useStore from "../../store/store";

export const RestaurantForm = ({
  restaurant = null,
  submitHandler,
  cancelHandler,
}) => {
  const [data, setData] = useState({
    name: "",
    number: "",
    city: "",
    instagramLink: "",
    openingHour: "",
    closingHour: "",
    offerStartDate: "",
    offerCloseDate: "",
    claimCode: "",
    foodType: [],
    discount: "0",
  });
  const [state, setState] = useState({
    docId: "",
    address: "",
    image: null,
    lat: 0,
    lng: 0,
    isOnDiscount: true,
    showModel: false,
    loading: true,
    images: [],
  });
  const { locale } = useStore((st) => st);

  useEffect(() => {
    if (restaurant) {
      setState((c) => ({
        ...c,
        docId: restaurant.docId,
        image: restaurant.image,
        lat: restaurant.lat,
        lng: restaurant.lng,
        images: restaurant.images,
      }));
      setData((c) => ({
        ...c,
        name: restaurant.name,
        number: restaurant.number,
        city: restaurant.city,
        instagramLink: restaurant.instagramLink,
        openingHour: restaurant.openingHour,
        closingHour: restaurant.closingHour,
        offerStartDater: restaurant.offerStartDater,
        offerCloseDate: restaurant.offerCloseDate,
        claimCode: restaurant.claimCode,
        foodType: restaurant.foodType,
        discount: restaurant.discount,
      }));
    }
    setState((p) => ({
      ...p,
      loading: false,
    }));
  }, []);

  const validations = yup.object().shape({
    name: yup.string().required(),
    city: yup.string().required(),
    number: yup.string().required(),
    closingHour: yup.string().required(),
    openingHour: yup.string().required(),
    offerStartDate: yup.string().required(),
    offerCloseDate: yup.string().required(),
    claimCode: yup.string().required(),
    foodType: yup.array().required(),
    discount: yup.string().required(),
  });

  const fileHandler = (e) => {
    setState((pre) => ({
      ...pre,
      image: e.target.files[0],
    }));
  };
  const handleModal = (e) => {
    setState((pre) => ({
      ...pre,
      showModel: !state.showModel,
    }));
  };

  const handleLocation = (loc) => {
    setState((pre) => ({
      ...pre,
      lat: loc.lat,
      lng: loc.lng,
      address: loc.address,
    }));
  };

  const handleLocationSubmition = () => {
    toast.success(locale.selected_success_message);
    handleModal();
  };

  const handleSubmit = (values) => {
    if (state.lat === 0 || state.lng === 0)
      return toast.error(locale.location_required_message);
    submitHandler({
      ...values,
      docId: state.docId,
      image: state.image,
      images: state.images,
      lat: state.lat,
      lng: state.lng,
      isOnDiscount: state.isOnDiscount,
    });
  };

  const removeImageHandler = (id) => {
    let picsCopy = [...state.images];

    picsCopy = picsCopy.filter((it) => it.id !== id);
    setState((p) => ({
      ...p,
      images: picsCopy,
    }));
  };

  const checkLiveUrl = (item) => {
    return item.image && item.image.size ? true : false;
  };

  const changePhotoHandler = (e) => {
    const imgObj = {
      id: Math.random(),
      image: e.target.files[0],
    };
    setState((prev) => ({
      ...prev,
      images: [...prev.images, imgObj],
    }));
  };

  if (state.loading) return "";
  return (
    <div className="container">
      <div className="secondary-container">
        <Formik
          initialValues={data}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {() => (
            <div>
              <h1 className="mb-2">
                {restaurant ? locale.edit : locale.add} {locale.restaurant}
              </h1>
              <div className="formInput formFileInput">
                <button className="btnFile ">
                  <span className="fileIcon">
                    <MdAssignment />
                  </span>
                  {locale.upload_logo}
                </button>
                <input
                  onChange={fileHandler}
                  type="file"
                  name="logo"
                  className="fileInput"
                />
                {state.image && (
                  <>
                    <img
                      width="100%"
                      height="200px"
                      src={
                        state.image.size
                          ? URL.createObjectURL(state.image)
                          : state.image
                      }
                      alt="logo"
                    />
                    <p>{locale.note_click_on_image_to_edit}</p>
                  </>
                )}
              </div>

              <button
                className="btn btn-block btn-secondary my-2 px-5"
                onClick={handleModal}
              >
                {locale.restaurant_location}
              </button>
              <p className="my-3"> {state.address}</p>
              <Form>
                <div className="row">
                  <div className="col-md-4">
                    <Input name="name" type="text" label={locale.name} />
                  </div>
                  <div className="col-md-4">
                    <Input name="number" type="text" label={locale.number} />
                  </div>
                  <div className="col-md-4">
                    <Input name="city" type="text" label={locale.city} />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Input
                      name="instagramLink"
                      type="text"
                      label={locale.insta_link}
                    />
                  </div>
                  <div className="col-md-4">
                    <Select
                      data={TIMINGS_DATA}
                      searchKey="name"
                      searchValue="name"
                      name="openingHour"
                      label={locale.opening_hour}
                    />
                  </div>
                  <div className="col-md-4">
                    <Select
                      data={TIMINGS_DATA}
                      searchKey="name"
                      searchValue="name"
                      name="closingHour"
                      label={locale.closing_hour}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="claimCode"
                      type="text"
                      label={locale.claim_code}
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      className="form-control offerDate"
                      name="offerStartDate"
                      type="date"
                      label={locale.offer_start_code}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      className="form-control offerDate"
                      name="offerCloseDate"
                      type="date"
                      label={locale.offer_close_date}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      className="custom-select mt-3"
                      name="foodType"
                      options={foodsVarities}
                      component={CustomSelect}
                      placeholder="Type of Food"
                      isMulti={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="discount"
                      type="text"
                      label={locale.discount}
                    />
                  </div>
                </div>
                <h4 className="mt-5">{locale.add_photo}</h4>
                <div className="formInput formFileInput">
                  <button className="btnFile">
                    <span className="fileIcon">
                      <MdAssignment />
                    </span>
                    {locale.upload_photo}
                  </button>
                  <input
                    onChange={changePhotoHandler}
                    type="file"
                    className="fileInput"
                  />
                </div>
                <h4 className="mt-5">{locale.library}</h4>
                <div className="row">
                  {state.images.map((item, index) => (
                    <div className="col-sm-12 col-md-6 col-lg-3 mt-5 gallery-img">
                      <span onClick={() => removeImageHandler(item.id)}>
                        <ImCross />
                      </span>
                      <img
                        width="100%"
                        height="250px"
                        src={
                          checkLiveUrl(item)
                            ? URL.createObjectURL(item.image)
                            : item.image
                        }
                        alt={index}
                        key={index}
                      />
                    </div>
                  ))}
                </div>

                <br />
                <div className="d-flex" style={{ gap: "10px" }}>
                  <button
                    className="btn btn-block btn-success mt-3 px-5"
                    type="submit"
                  >
                    {locale.submit}
                  </button>
                  <button
                    onClick={cancelHandler}
                    className="btn btn-block btn-danger mt-3 px-5"
                  >
                    {locale.cancel}
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>

      <Modal show={state.showModel} onHide={handleModal} size="lg">
        <Modal.Header>
          <Modal.Title>{locale.location}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Map
            selectedLocation={handleLocation}
            lat={state.lat}
            lng={state.lng}
          />
        </Modal.Body>
        <Modal.Footer style={{ zIndex: "123", marginTop: "3rem" }}>
          <Button className="mt-3" variant="secondary" onClick={handleModal}>
            {locale.close}
          </Button>
          <Button
            className="mt-3"
            variant="success"
            onClick={handleLocationSubmition}
          >
            {locale.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
