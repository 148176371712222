
exports.DateDifference = {
  inDays: function (d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000));
  },

  inWeeks: function (d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
  },

  inHours: function (d1, d2) {
    // get total seconds between the times
    var deltaHours = Math.abs(d2 - d1) / 1000;
    var deltaMinuts = Math.abs(d2 - d1) / 1000;
    var deltaDays = Math.abs(d2 - d1) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(deltaDays / 86400);
    deltaDays -= days * 86400;

    // // calculate (and subtract) whole hours
    var hours = Math.floor(deltaHours / 3600) % 24;
    deltaHours -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(deltaMinuts / 60) % 60;
    deltaMinuts -= minutes * 60;

    return hours + " hours-" + minutes + " minutes";
  },
  inMonths: function (d1, d2) {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return d2M + 12 * d2Y - (d1M + 12 * d1Y);
  },

  inYears: function (d1, d2) {
    return d2.getFullYear() - d1.getFullYear();
  },
  convert: function (d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return d.constructor === Date
      ? d
      : d.constructor === Array
        ? new Date(d[0], d[1], d[2])
        : d.constructor === Number
          ? new Date(d)
          : d.constructor === String
            ? new Date(d)
            : typeof d === "object"
              ? new Date(d.year, d.month, d.date)
              : NaN;
  },

  inRange: function (d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((d = this.convert(d).valueOf())) &&
      isFinite((start = this.convert(start).valueOf())) &&
      isFinite((end = this.convert(end).valueOf()))
      ? start <= d && d <= end
      : NaN;
  },
};

exports.calculateAge = (birthday) => {
  const dobDate = new Date(birthday);
  let ageDifMs = Date.now() - dobDate.getTime();
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

exports.monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

exports.checkDateIsGreaterThanToday = (date) => {
  const q = new Date(date);
  const c = new Date();
  const qDate = q.getDate();
  const cDate = c.getDate();
  const qMonth = q.getMonth();
  const cMonth = c.getMonth();
  const qYear = q.getFullYear();
  const cYear = c.getFullYear();
  return qYear >= cYear && qMonth >= cMonth && qDate >= cDate;
}
