import React from "react";
import { deleteTeam, getTeams, registerTeam } from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { downloadExcel } from "../../../utils/data";
import { MdAssignment } from "react-icons/md";
import readXlsxFile from "read-excel-file";
import { useMemo } from "react";

export const Teams = () => {
  const { data, error } = useSWR("/admin/get/teams", getTeams);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you sure, you want to delete?");
      if (confirm) {
        await deleteTeam(id);
        alert("Deleted successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  function handleDownloadExcel() {
    const teamsData = data
      ? data.map((item) => ({
          id: item.docId,
          name: item.name,
          nameInArabic: item.nameInArabic,
          flagName: item.flagName,
          isNationalTeam: item.isNationalTeam ? "true" : "false",
        }))
      : [];
    downloadExcel(teamsData, "teams");
  }

  const COLUMNS = [
    {
      key: "logo",
      label: "Image",
      content: (item) => (
        <img
          src={item.image ? item.image : `/assets/Flags/${item.flagName}.png`}
          width={"100px"}
          height={"50px"}
          alt="alternateText"
        />
      ),
    },
    {
      key: "name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      key: "nameInArabic",
      label: "Name In Arabic",
      content: (item) => <span>{item.nameInArabic}</span>,
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <Link to="/admin/teams/add" state={item} className="btn btn-secondary">
          Edit
        </Link>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.docId)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];
  const getFilterData = (data, isNationals) => {
    return data.filter((item) => item.isNationalTeam === isNationals);
  };

  const importTeamsHandler = (e) => {
    let fileObj = e.target.files[0];
    readXlsxFile(fileObj)
      .then(async (teams) => {
        for (let i = 0; i < teams.length; i++) {
          if (i > 0) {
            const item = teams[i];
            const match = {
              name: item[0],
              flagName: item[1],
              nameInArabic: item[2],
              isNationalTeam: item[3],
              sortIndex: item[4],
              image: null,
            };
            await registerTeam(match);
          }
        }
        toast.success("successfully imported all the teams.");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const filterData = useMemo(() => {
    return data
      ? searchTerm.length
        ? data.filter((it) =>
            it.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : data
      : [];
  }, [data, searchTerm]);
  if (error) toast.error(error.message);
  if (!data) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Teams</h1>
          <Link
            to="/admin/teams/add"
            className="btn btn-outline-secondary d-flex justify-content-center align-items-center mb-3"
          >
            <span style={{ marginRight: "10px" }}>
              <GrAdd />
            </span>
            Add
          </Link>
        </div>

        <button
          className="btn btn-secondary my-2 px-5"
          style={{ width: "100%" }}
          onClick={handleDownloadExcel}
        >
          Export
        </button>
        <div className="formInput formFileInput">
          <button className="btnFile btn-excell">
            <span className="fileIcon">
              <MdAssignment />
            </span>
            Import Teams
          </button>
          <input
            onChange={importTeamsHandler}
            type="file"
            style={{ margin: "-2rem -0.8rem", padding: "0.5rem 1rem 1.5rem" }}
            className="fileInput"
          />
        </div>
        <div className="form-group mt-3 mb-2">
          <input
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            placeholder="Search teams by name"
            className="form-control"
          />
        </div>
        <Tabs
          defaultActiveKey="Clubs"
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
        >
          <Tab eventKey="Clubs" title="Clubs">
            <Table
              data={getFilterData(filterData, false).slice(0, itemsPerPage)}
              coloumns={COLUMNS}
            />
          </Tab>

          <Tab eventKey="Nationals" title="Nationals">
            <Table
              data={getFilterData(filterData, true).slice(0, itemsPerPage)}
              coloumns={COLUMNS}
            />
          </Tab>
        </Tabs>

        {data.length <= 0 && <span>No teams added yet.</span>}

        {filterData.length > 0 && filterData.length > itemsPerPage && (
          <span className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => setItemsPerPage((c) => c + 10)}
            >
              <BsChevronDoubleDown />
            </button>
          </span>
        )}
      </div>
    </div>
  );
};
