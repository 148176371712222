import React, { useMemo } from "react";
import { Form as BootstrapForm } from "react-bootstrap";
import { MdOutlineSearch } from "react-icons/md";
import { Table } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Select from "../../../components/common/select2";
import Input from "./../../../components/common/inputWithChange";
import "./TableManagement.css";
import useSWR from "swr";
import { getTableBookings, tablebookingSetting } from "../../../Firebase/apis";
import Spinner from "../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { userStatus, userStatusforStandard } from "../../../utils/data";
import { firestore } from "../../../Firebase/config";
import useStore from "../../../store/store";
import isMobileScreen from "./../../../hooks/isMobileScreen.jsx";
import { downloadExcel } from "../../../utils/data";

const DUMY = [
  { id: 1, name: { eng: "Tables", ar: "طاولات" } },
  { id: 2, name: { eng: "Seats", ar: "كراسي" } },
];

export const TableManagement = () => {
  const { data, error } = useSWR("/admin/get/tablebookings", getTableBookings);
  const [isEnabled, setIsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const { locale, activeLanguage } = useStore((st) => st);
  const isMobile = isMobileScreen();
  const [state, setState] = useState({
    whatsappNumber: "",
    venueLayout: "",
    numberOfTables: "",
    minimumOrderPerPerson: "",
  });
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));

  const validations = yup.object().shape({
    whatsappNumber: yup.string().required(),
    venueLayout: yup.string().required(),
    numberOfTables: yup.string().required(),
    minimumOrderPerPerson: yup.string().required(),
  });

  const handleSubmit = async (values) => {
    try {
      await tablebookingSetting({ ...values, isEnabled });
      toast.success("Updated successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getDate = (d) => {
    const date = new Date(d);
    return date.toLocaleDateString();
  };
  const getLatestBookings = async () => {
    const bookingRef = await firestore
      .collection("bookingCollection")
      .where("venueID", "==", user.userId)
      .where("isViewed", "==", false)
      .get();
    return bookingRef.docs.map((item) => item.data());
  };
  const setViewBookings = async (bookings) => {
    bookings.forEach(async (item) => {
      const bRef = firestore.collection("bookingCollection").doc(item.docID);
      await bRef.update({
        isViewed: true,
      });
    });
  };

  useEffect(() => {
    getLatestBookings().then((items) => {
      setViewBookings(items);
    });
  }, []);
  useEffect(() => {
    if (data && data.setting) {
      setState({
        whatsappNumber: data.setting.whatsappNumber,
        venueLayout: data.setting.venueLayout,
        numberOfTables: data.setting.numberOfTables,
        minimumOrderPerPerson: data.setting.minimumOrderPerPerson,
      });
      setIsEnabled(data.setting.isEnabled);
    }
    if (data) {
      setLoading(false);
    }
  }, [data]);

  const enableHandler = (e) => {
    if (!userStatus || !userStatusforStandard)
      return alert("You need to upgrade to use this feature");
    setIsEnabled(e.target.checked);
  };

  const inputHandler = (name, value) => {
    if (!value.length) return;
    if (!userStatus || !userStatusforStandard)
      return alert("You need to upgrade to use this function");
    setState((p) => ({
      ...p,
      [name]: value,
    }));
  };

  function handleDownloadExcel() {
    if (!userStatus || !userStatusforStandard)
      return alert("You need to upgrade to use this function");

    const exportingData = data
      ? data.bookings.map((item) => ({
          user: item.user ? item.user.name : "User Deleted",
          PhoneNumber: item.phoneNumber,
          Date: getDate(item.date),
          People: item.people,
          Status: item.status,
          firstTeam:
            item.match && item.match.firstTeam && item.match.firstTeam.name,
          secondTeam:
            item.match && item.match.secondTeam && item.match.secondTeam.name,
          Note: item.note,
        }))
      : [];
    downloadExcel(exportingData, "reservations");
  }

  const bookingTypes = useMemo(() => {
    if (activeLanguage && !activeLanguage.includes("English")) {
      return DUMY.map((it) => ({
        ...it,
        name: it.name.eng,
        active: it.name.ar,
      }));
    } else {
      return DUMY.map((it) => ({
        ...it,
        name: it.name.eng,
        active: it.name.eng,
      }));
    }
  }, [activeLanguage]);
  if (error) return toast.error(error.message);
  if (loading || !data) return <Spinner />;
  return (
    <div className="container">
      <div>
        <div className="breadCrumb mt-5">
          <h4 className="fs-30">
            {locale.venue_portal_table_booking_managment}
          </h4>
        </div>

        <div className="profile_main p-3 pt-5">
          <div className="d-flex align-items-center table-booking">
            <h5 className="fs-17">{locale.enable_table_booking} </h5>
            <span className="m-lg-4">
              <BootstrapForm.Check
                type="switch"
                id="custom-switch"
                checked={isEnabled}
                onChange={(e) => enableHandler(e)}
              />
            </span>
          </div>

          <Formik
            initialValues={state}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-4">
                    <Input
                      name="whatsappNumber"
                      type="text"
                      value={state.whatsappNumber}
                      label={locale.whatsapp_number_request}
                      changeHandler={inputHandler}
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                    <Select
                      data={bookingTypes}
                      searchkey="name"
                      searchValue="active"
                      value={state.venueLayout}
                      name="venueLayout"
                      label={locale.select_venue_layout_table_management}
                      changeHandler={inputHandler}
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-4">
                    <Input
                      name="numberOfTables"
                      type="text"
                      value={state.numberOfTables}
                      label={locale.number_of_tables}
                      changeHandler={inputHandler}
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                    <Input
                      name="minimumOrderPerPerson"
                      type="text"
                      value={state.minimumOrderPerPerson}
                      label={locale.minimum_order_person}
                      changeHandler={inputHandler}
                    />
                  </div>
                </div>

                <button
                  className="btn btn-block btn-success mt-3 px-5 fs-17"
                  type="submit"
                  disabled={!userStatus || !userStatusforStandard}
                >
                  {locale.portal_table_managment_btn_submit}
                </button>
              </Form>
            )}
          </Formik>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3 mt-4">
              <h5>{locale.reservation_history}</h5>
            </div>

            <div
              className="btn btn-secondary my-2 fs-17"
              onClick={handleDownloadExcel}
            >
              {locale.exports_history}
            </div>

            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="fixtureHeadInput mt-3">
                <div>
                  <span className="fixtureInputIcon">
                    <MdOutlineSearch />
                  </span>
                </div>
                <div>
                  <input
                    type="text"
                    className="fixtureCustomInput fs-15"
                    placeholder={locale.portal_table_managment_search_for_past_reservation}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isMobile ? (
            <Table responsive className="mt-5 management-table-container">
              <thead>
                <tr>
                  <th className="fs-20">{locale.phone}</th>
                  <th className="fs-20">{locale.date}</th>
                  <th className="fs-20">{locale.people}</th>
                  <th className="fs-20">{locale.status}</th>
                  <th className="fs-20">{locale.match}</th>
                  <th className="fs-20">{locale.note}</th>
                </tr>
              </thead>
              <tbody>
                {data.bookings.map((item) => (
                  <tr className="mt-5" key={item.docID}>
                    <td>{item.phoneNumber}</td>
                    <td>{getDate(item.date)}</td>
                    <td>{item.people}</td>
                    <td>{item.status}</td>
                    <td>
                      <span>
                        {item.match &&
                          item.match.firstTeam &&
                          item.match.firstTeam.name}
                      </span>
                      <span> vs </span>
                      <span>
                        {item.match &&
                          item.match.secondTeam &&
                          item.match.secondTeam.name}
                      </span>
                    </td>
                    <td colSpan={2}>{item.note}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="list-group mt-3">
              {data.bookings.map((item) => (
                <div className="list-group-item mb-3 p-2">
                  <div className="row">
                    <div className="col-9">
                      <span className="font-weight-bold mr-2">
                        {locale.phone}:
                      </span>
                      <span>{item.phoneNumber}</span>
                    </div>
                    <div className="col-3">
                      <span className="font-weight-bold mr-2">
                        {locale.date}:
                      </span>
                      <span>{getDate(item.date)}</span>
                    </div>

                    <div className="col-9">
                      <span className="font-weight-bold mr-2">
                        {locale.match}:
                      </span>
                      <span>
                        {item.match &&
                          item.match.firstTeam &&
                          item.match.firstTeam.name}
                      </span>
                      <span> vs </span>
                      <span>
                        {item.match &&
                          item.match.secondTeam &&
                          item.match.secondTeam.name}
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="font-weight-bold mr-2">
                        {locale.status}:
                      </span>
                      <span>{item.status}</span>
                    </div>
                    <div className="col-9">
                      <span className="font-weight-bold mr-2">
                        {locale.note}:
                      </span>
                      <span>{item.note}</span>
                    </div>
                    <div className="col-3">
                      <span className="font-weight-bold mr-2">
                        {locale.people}:
                      </span>
                      <span>{item.people}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
