import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import AdminImage from "../../assets/placeholder.jpg";
import "./style.css";
import useStore from "../../store/store";
import enLocale from "../../utils/locale.json";
import arrabicLocale from "../../utils/localeArrabic.json";

import { MdOutlineSettings, MdLogout, MdReorder } from "react-icons/md";
import { BiBell } from "react-icons/bi";
import { firestore } from "../../Firebase/config";

const Index = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { setLocale, locale } = useStore((st) => st);

  const getAdminNotifications = () => {
    const messagesdbRef = firestore.collection("adminNotifications");
    messagesdbRef.onSnapshot((querySnapShot) => {
      const notifications = [];
      querySnapShot.forEach(async (item) => {
        const data = item.data();
        if (!data.isRead) {
          notifications.push({ id: item.id, ...data });
        }
      });
      setNotifications(notifications);
    });
  };

  useEffect(() => {
    getAdminNotifications();
  }, []);

  const notificationClickedHandler = async (item) => {
    await firestore.collection("adminNotifications").doc(item.id).delete();
    navigate(item.route);
  };

  const changeHandler = (e) => {
    if (!e.target.value) return;
    if (e.target.value.includes("Arrabic")) {
      setLocale(arrabicLocale);
      sessionStorage.setItem("fanspot-locale", e.target.value);
    } else if (e.target.value.includes("English")) {
      sessionStorage.setItem("fanspot-locale", e.target.value);
      setLocale(enLocale);
    }
  };
  const navbarToggle = () => {
    setIsActive(!isActive);
  };

  const checkActive = (path) => {
    const exist = window.location.pathname.includes(path);
    return exist ? "active" : "";
  };

  const clickHandler = (path) => {
    navigate(path);
  };

  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  return (
    <div>
      <Navbar className="navbarMain" expand="lg">
        <Navbar.Brand>
          <span className="navbarMenu" onClick={navbarToggle}>
            <MdReorder />
          </span>
          <img src={logo} alt="alternatText" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {!props.isAdmin && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <div className="form-group" style={{ margin: "13px" }}>
                <select
                  className="form-control selectLanguage"
                  onChange={changeHandler}
                >
                  <option>{locale.change_language}</option>
                  <option value="English">English</option>
                  <option value="Arrabic">Arrabic</option>
                </select>
              </div>
              <Link to="/portal/settings" className="nav-link">
                <span className="settingItem">
                  <MdOutlineSettings />
                </span>
              </Link>

              <Link to="/portal/profile" className="nav-link adminImageWrapper">
                <img
                  src={!props.isAdmin && user.logo ? user.logo : AdminImage}
                  className="adminImage "
                  alt="alternatText"
                />
              </Link>
              <p className="nav-venueName">{user.venueName}</p>
            </Nav>
          </Navbar.Collapse>
        )}
        <div className="dropdown pointer">
          <span
            className="notifications-container"
            id="notificationDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <BiBell />
            {notifications.length ? (
              <span className="badge badge-light">{notifications.length}</span>
            ) : (
              ""
            )}
          </span>
          <div
            className="dropdown-menu dropdown-menu-right dropdown-menu-lg-left"
            aria-labelledby="notificationDropdown"
          >
            {notifications.length <= 0 ? (
              <span className="dropdown-item">No Notifications</span>
            ) : (
              notifications.map((item) => (
                <div
                  className="dropdown-item"
                  onClick={() => notificationClickedHandler(item)}
                >
                  {item.message}
                </div>
              ))
            )}
          </div>
        </div>
      </Navbar>

      <div className="main">
        <div
          className={"sidebar"}
          style={{
            display: isActive ? "none" : "",
            transition: isActive ? ".5s" : "",
          }}
        >
          <div className="sidebar_links">
            {props.data.map((item) => (
              <div
                key={item.path}
                className={`sidebar_link ${checkActive(item.path)}`}
                onClick={() => clickHandler(item.path)}
              >
                <span>{item.Icon}</span>
                {item.title}
              </div>
            ))}

            <div className="sidebar_link" onClick={props.logoutHandler}>
              <span>
                <MdLogout />
              </span>
              {locale.logout}
            </div>
          </div>
        </div>

        <div
          className={!isActive ? "left-margin dashboardMain" : "dashboardMain"}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Index;
