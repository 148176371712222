import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

// DASHBOARD IMPORTS
import AdminRoutes from "./AdminRoutes";
import PortalRoutes from "./PortalRoutes";
import ClientRoutes from "./ClientRoutes";

function Index() {
  const [url, setUrl] = useState("/");

  useEffect(() => {
    const currentUrl = window.location.pathname;
    setUrl(currentUrl);
  }, [window.location.pathname]);

  const renderRoutes = () => {
    if (url === "/") {
      return <ClientRoutes />;
    } else if (url === "/admin") {
      return <AdminRoutes />;
    } else if (url === "/portal") {
      return <PortalRoutes />;
    }
  };

  return <BrowserRouter>{renderRoutes()}</BrowserRouter>;
}

export default Index;
