import React from "react";
import { Row, Col } from "react-bootstrap";
import FormFrameImage from "../../../assets/Frame.png";
import Navbar from "../../../components/Navbar";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Input from "../../../components/common/input";
import { toast } from "react-toastify";
import { MdAssignment } from "react-icons/md";
import { registerUser } from "../../../Firebase/apis";
import useStore from "../../../store/store";


export const Register = (props) => {
  const { locale } = useStore((st) => st);
  const [state, setState] = useState({
    image: null,
    loading: false,
  });
  const initialStateForFirst = {
    name: "",
    email: "",
    phoneNumber: "",
    country: "",
    password: "",
  };

  const validationsForFirst = yup.object().shape({
    name: yup.string().required().label("Name"),
    email: yup.string().required(),
    phoneNumber: yup.string().required(),
    country: yup.string().required(),
    password: yup.string().required(),
  });
  const getYear = () => {
    const d = new Date();
    return d.getFullYear();
  };

  const handleSubmit = async (values) => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      await registerUser({
        ...values,
        year: getYear(),
        selectedTeams: [],
        selectedLeagues: [],
        nationalTeam: "",
        isPhoneVerified: false,
        allowMarketing: false,
        image: state.image,
      });
      toast.success("Registered successfully");
      window.location.replace("/");
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      toast.error(error.message);
    }
  };

  const fileHandler = (e) => {
    setState((prev) => ({ ...prev, image: e.target.files[0] }));
  };
  return (
    <div>
      <Navbar registerLink="/register" loginLink="/login" client={true} />
      <section className="registerMain">
        <Row>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="registerForm pt-5">
              <h3>{locale.create_your_fanspot_account}</h3>
              <h5>{locale.enter_all_the_details}</h5>

              <div className="formInput formFileInput">
                <button className="btnFile">
                  <span className="fileIcon">
                    <MdAssignment />
                  </span>
                  {locale.upload_logo}
                </button>
                <input
                  onChange={fileHandler}
                  type="file"
                  name="logo"
                  className="fileInput"
                />
                {state.image && (
                  <>
                    <img
                      width="100%"
                      height="200px"
                      src={
                        state.image.size
                          ? URL.createObjectURL(state.image)
                          : state.image
                      }
                      alt="logo"
                    />
                    <p>{locale.note_click_on_image_to_edit}</p>
                  </>
                )}
              </div>

              <>
                <Formik
                  initialValues={initialStateForFirst}
                  validationSchema={validationsForFirst}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <div>
                      <Form>
                        <Input name="name" type="text" label={locale.name} />
                        <Input name="email" label={locale.email} />
                        <Input name="phoneNumber" label={locale.phone_number} />

                        <Input name="country" label={locale.country} />

                        <Input
                          name="password"
                          type="password"
                          label={locale.password}
                        />
                        <button className="btn btn-success mt-3" type="submit">
                          {state.loading ? (
                            <div
                              class="spinner-border text-light"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            locale.submit
                          )}
                        </button>
                      </Form>
                    </div>
                  )}
                </Formik>
              </>
            </div>
          </div>
          <Col className="col-md-6 mt-5">
            <div className="registerImage mt-5">
              <img src={FormFrameImage} className="img-fluid" alt="alternatText"  />
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};
