import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import "./style.css";
import useStore from "../../../store/store";

export const AboutUs = () => {
  const { locale } = useStore((st) => st);
  return (
    <>
      <Navbar registerLink="/register" client={true} />
      <div class="section">
        <div class="about-container">
          <div class="content-section">
            <div class="title">
              <h1>{locale.about_us}</h1>
            </div>
            <div class="content">
              <h3>Lorem ipsum dolor sit amet, consectetur adipisicing</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div class="button">
                <a href="#test">{locale.read_more}</a>
              </div>
            </div>
            <div class="social">
              <a href="#test">
                <FaFacebook />
              </a>
              <a href="#test">
                <FaTwitter />
              </a>
              <a href="#test">
                <FaInstagram />
              </a>
            </div>
          </div>
          <div class="image-section">
            <img src="/assets/abot.jpg" alt="alternatText" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
