import React from "react";
import { AiOutlineCoffee } from "react-icons/ai";
import { BsTable, BsWifi, BsWifiOff } from "react-icons/bs";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import {
  MdAirlineSeatReclineNormal,
  MdCameraOutdoor,
  MdCasino,
  MdFoodBank,
  MdNoFood,
  MdOutlineCasino,
  MdOutlineSignalCellularNoSim,
  MdPublicOff,
} from "react-icons/md";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import {
  TbCoffeeOff,
  TbScreenShare,
  TbScreenShareOff,
  TbTableOff,
} from "react-icons/tb";
import locale from "../../../utils/locale.json";

export const SingleVenue = ({ venue, backHandler, match }) => {
  const getTime = (t) => {
    const d = new Date(t);
    return d.toLocaleDateString();
  };
  const bookHandler = () => {
    alert(locale.directly_contact_to_this_number + venue.mangerPhoneNumber);
  };
  return (
    <div className="container single-venue-wrapper">
      <span onClick={() => backHandler()}>
        <IoArrowBackCircleOutline />
      </span>
      <div className="row">
        <div className="col-sm-8">
          <div className="img-wrapper">
            <img
              src={venue.logo ? venue.logo : "assets/placeholder.jpg"}
              alt={venue.venueName}
            />
            <hr />
            <div className="working-hours-wrapper">
              <h4 className="mb-5">Opening Hours</h4>
              <div className="row">
                {venue.workingHours &&
                  venue.workingHours
                    .filter((item) => item.status)
                    .map((item) => (
                      <div className="col-md-4 time-wrapper">
                        <h6>{item.day}</h6>
                        <div>
                          <span>{item.startTime}</span>
                          <span>-</span>
                          <span>{item.endTime}</span>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <hr />
            <div className="facilities-wrapper">
              <h4 className="mb-5">Facilities</h4>
              <div className="Custom_container">
                <div className="row">
                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>{venue.numberOfScreens}</span>
                    </div>
                    <p>{locale.screen}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.projector ? (
                          <TbScreenShare />
                        ) : (
                          <TbScreenShareOff />
                        )}
                      </span>
                    </div>
                    <p>{locale.projector}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>{venue.wifi ? <BsWifi /> : <BsWifiOff />}</span>
                    </div>
                    <p>{locale.Wifi}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.servesFood ? <MdFoodBank /> : <MdNoFood />}
                      </span>
                    </div>
                    <p>{locale.food}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.outdoorSeating ? (
                          <MdCameraOutdoor />
                        ) : (
                          <MdCameraOutdoor />
                        )}
                      </span>
                    </div>
                    <p>{locale.outdoor}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.tableBooking ? <BsTable /> : <TbTableOff />}
                      </span>
                    </div>
                    <p>{locale.book_table}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.shisha ? <MdCasino /> : <MdOutlineCasino />}
                      </span>
                    </div>
                    <p>{locale.shisha}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.privateRoom ? (
                          <RiGitRepositoryPrivateFill />
                        ) : (
                          <MdPublicOff />
                        )}
                      </span>
                    </div>
                    <p>{locale.private_room}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.servesCofee ? (
                          <AiOutlineCoffee />
                        ) : (
                          <TbCoffeeOff />
                        )}
                      </span>
                    </div>
                    <p>{locale.Coffee}</p>
                  </div>

                  <div className="col">
                    <div className="feature-previeW-container">
                      <span>
                        {venue.seatBooking ? (
                          <MdAirlineSeatReclineNormal />
                        ) : (
                          <MdOutlineSignalCellularNoSim />
                        )}
                      </span>
                    </div>
                    <p>{locale.seat_booking}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 details-wrapper">
          <h3>{venue.venueName}</h3>
          <hr />
          <div className="league-wrapper">
            <p>{match.league.name}</p>
            <p>{getTime(match.startTime)}</p>
          </div>
          <hr />
          <div className="team-wrapper">
            <img
              src={`assets/Flags/${match.firstTeam.flagName}.png`}
              alt={match.firstTeam.name}
            />
            <p>{match.firstTeam.name}</p>
          </div>
          <hr />
          <div className="team-wrapper">
            <img
              src={`assets/Flags/${match.secondTeam.flagName}.png`}
              alt={match.secondTeam.name}
            />
            <p>{match.secondTeam.name}</p>
          </div>
          <hr />
          <button
            className="btn primary-bg-color px-5 my-2"
            onClick={bookHandler}
          >
            {locale.book_table}
          </button>
        </div>
      </div>
    </div>
  );
};
