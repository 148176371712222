import React, { useEffect } from "react";
import Footer from "../../../components/Footer/SecondVariant";
import Navbar from "../../../components/Navbar/SecondVariant";
import { useState } from "react";
import { addUserMessage } from "../../../Firebase/apis";
import { toast } from "react-toastify";
import useStore from "../../../store/store";
import Spinner from "../../../components/common/Spinner.jsx";

export const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const { locale } = useStore((state) => state);
  const [contactFormm, setContactForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
  });

  useEffect(() => {
    document.getElementById("clientcontactus").click();
  }, []);

  const contactChangeHandler = (e) => {
    setContactForm((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const contactSubmitHadnler = async () => {
    console.log(contactFormm);
    try {
      if (
        !contactFormm.email.trim().length ||
        !contactFormm.name.trim().length ||
        !contactFormm.description.trim().length
      )
        return toast.error("All fields are required");
      setLoading(true);
      await addUserMessage({
        email: contactFormm.email,
        subject: contactFormm.name,
        body: contactFormm.description,
        message: null,
      });
      toast.success("Added successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  return (
    <>
      <Navbar />
      <a
        className="empty-link"
        href="#clientcontactus"
        id="clientcontactus"
      ></a>
      <section id="clientcontactus" style={{ marginTop: "100px" }}>
        <div className="contact">
          <div className="container-fluid main-app-wrapper">
            <h1 className="text-center fs-40">{locale.contact_us}</h1>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <img
                  src="/assets/LandingImages/contactImage.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className="inputs">
                  <div className="input">
                    <span>{locale.your_name}</span>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      onChange={contactChangeHandler}
                    />
                  </div>

                  <div className="input">
                    <span>{locale.phone_number}</span>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      onChange={contactChangeHandler}
                    />
                  </div>

                  <div className="input">
                    <span>{locale.email}</span>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={contactChangeHandler}
                    />
                  </div>

                  <div className="input">
                    <span>{locale.how_can_we_help_you}</span>
                    <textarea
                      type="text"
                      rows="6"
                      name="description"
                      onChange={contactChangeHandler}
                      placeholder="Your Message..."
                    ></textarea>
                  </div>

                  <div className="input" onClick={contactSubmitHadnler}>
                    <button className="btn btn-custom fs-20">
                      {loading ? <Spinner /> : locale.contact_now}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
