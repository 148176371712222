import React from "react";
import profile from "../../../assets/images/profile .svg";
import fixture from "../../../assets/images/Fixture.svg";
import reviews from "../../../assets/images/Review.svg";
import tableBooking from "../../../assets/images/table Booking.svg";
import offers from "../../../assets/images/Offers.svg";
import socialMedia from "../../../assets/images/social-media.svg";
import stats from "../../../assets/images/Statistics.svg";
import memberShip from "../../../assets/images/membership.svg";
import fanSport from "../../../assets/images/fanSport.png";
import { Badge, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { userStatus } from "../../../utils/data";
import useStore from "../../../store/store";

export const View = ({ data }) => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const { locale } = useStore((st) => st);

  return (
    <div className="dashboardMain">
      <div className="container">
        <div className="breadCrumb">
          <h4 className="fs-30">
            {" "}
            {user.venueName} | {locale.my_dashboard}
          </h4>
          {!userStatus && (
            <Badge
              bg="secondary"
              className="breadCrumbBadge"
              onClick={() => navigate("/portal/memberships")}
            >
              {locale.start_your_one_month_free_trial}
            </Badge>
          )}
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={"mainCard"}
              onClick={() => navigate("/portal/profile")}
            >
              <Card.Img variant="top" className="cardImage" src={profile} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.profile}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.edit_profile_information}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={
                user.adminVerified && userStatus
                  ? "mainCard"
                  : "mainCard not-verified"
              }
              onClick={() => navigate("/portal/fixtures")}
            >
              <span className="notificationBadge">
                <p>{data.matches}</p>
              </span>
              <Card.Img variant="top" className="cardImage" src={fixture} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.fixtures}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.select_matches_plan_your_schedule}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={
                user.adminVerified && userStatus
                  ? "mainCard"
                  : "mainCard not-verified"
              }
              onClick={() => navigate("/portal/reviews")}
            >
              <span className="notificationBadge">
                <p>{data.reviews}</p>
              </span>
              <Card.Img variant="top" className="cardImage" src={reviews} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.reviews}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.view_customer_feedback}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={
                user.adminVerified && userStatus
                  ? "mainCard"
                  : "mainCard not-verified"
              }
              onClick={() => navigate("/portal/tablemanagement")}
            >
              <span className="notificationBadge">
                <p>{data.bookings}</p>
              </span>
              <Card.Img
                variant="top"
                className="cardImage"
                src={tableBooking}
              />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.table_booking_managment}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.manage_reservsation_reqs}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={
                user.adminVerified && userStatus
                  ? "mainCard"
                  : "mainCard not-verified"
              }
              onClick={() => navigate("/portal/offers")}
            >
              <Card.Img variant="top" className="cardImage" src={offers} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.offers}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.create_edit_offers}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={
                user.adminVerified && userStatus
                  ? "mainCard"
                  : "mainCard not-verified"
              }
              onClick={() => navigate("/portal/socialmanagement")}
            >
              <Card.Img variant="top" className="cardImage" src={socialMedia} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.social_media_managment}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.venue_dashboard_social_management_paragraph}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={"mainCard"}
              onClick={() => navigate("/portal/stats")}
            >
              <Card.Img variant="top" className="cardImage" src={stats} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.statistics}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.view_your_performance}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card
              className={"mainCard"}
              onClick={() => navigate("/portal/memberships")}
            >
              <Card.Img variant="top" className="cardImage" src={memberShip} />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.membership}
                </Card.Title>
                <Card.Text className="cardText fs-15">
                  {locale.view_your_membership}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <Card className={"mainCard not-verified"}>
              <Card.Img
                variant="top"
                className="cardImage"
                style={{ width: 120, marginTop: 30 }}
                src={fanSport}
              />
              <Card.Body>
                <Card.Title className="cardTitle fs-25">
                  {locale.coming_soon}
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
