import React from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Input from "../../../components/common/input";
import useSWR from "swr";
import { addUser, getCountries, updateUser } from "../../../Firebase/apis";
import Spinner from "../../../components/common/Spinner.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Form as BootstrapForm } from "react-bootstrap";
import { MdAssignment } from "react-icons/md";
import { toast } from "react-toastify";

export const UserForm = () => {
  const { data } = useSWR("/get/countries", getCountries);
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    image: null,
    loading: true,
    allowMarketing: false,
    userID: "",
  });
  const getYear = () => {
    const d = new Date();
    return d.getFullYear();
  };

  const [form, setForm] = useState({
    email: "",
    name: "",
    country: "",
    nationalTeam: "",
    password: "",
    phoneNumber: "",
    isPhoneVerified: false,
    year: getYear(),
  });

  const validationsForForm = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
    country: yup.string().required(),
    phoneNumber: yup.string().required(),
  });

  useEffect(() => {
    const portal = location.state;
    if (portal) {
      setForm({
        name: portal.name,
        email: portal.email,
        phoneNumber: portal.phoneNumber,
        password: portal.password,
        country: portal.country,
      });
      setState((pre) => ({
        ...pre,
        image: portal.image,
        userID: portal.userID,
        allowMarketing: portal.allowMarketing,
      }));
    }
    setState((pre) => ({
      ...pre,
      loading: false,
    }));
  }, [location.state]);

  const changeHandler = (e) => {
    setState((p) => ({
      ...p,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      setLoading();
      if (location.state) {
        await updateUser({
          ...values,
          image: state.image,
          userID: state.userID,
          allowMarketing: state.allowMarketing,
        });
      } else {
        await addUser({
          ...values,
          isVerified: true,
          isDeleted: false,
          image: state.image,
          allowMarketing: state.allowMarketing,
        });
      }
      navigate("/admin/users");
    } catch (error) {
      setLoading();
      toast.success(error.message);
    }
  };

  const setLoading = () => {
    setState((p) => ({
      ...p,
      loading: !p.loading,
    }));
  };

  const fileHandler = (e) => {
    setState((pre) => ({
      ...pre,
      image: e.target.files[0],
    }));
  };

  if (state.loading || !data) return <Spinner />;
  return (
    <div className="container mb-5">
      <div className="secondary-container mx-5 px-5">
        <h3>Create your fanspot client account</h3>
        <h5>Enter the details</h5>

        <div className="formInput formFileInput">
          <button className="btnFile ">
            <span className="fileIcon">
              <MdAssignment />
            </span>
            Upload logo
          </button>
          <input
            onChange={fileHandler}
            type="file"
            name="logo"
            className="fileInput"
          />
          {state.image && (
            <>
              <img
                width="100%"
                height="200px"
                src={
                  state.image.size
                    ? URL.createObjectURL(state.image)
                    : state.image
                }
                alt="logo"
              />
              <p>note: click on image to edit</p>
            </>
          )}
        </div>

        <Formik
          initialValues={form}
          validationSchema={validationsForForm}
          onSubmit={handleSubmit}
        >
          {() => (
            <div>
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <Input name="name" type="text" label="Name" />
                  </div>
                  <div className="col-md-6">
                    <Input name="email" type="email" label="Email" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      name="phoneNumber"
                      type="text"
                      label="Phone Number"
                    />
                  </div>
                  <div className="col-md-6">
                    <Input name="country" type="text" label="Country" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profileMain_portal">
                      <h3 className="font-weight-bold">Allow Marketing</h3>
                      <BootstrapForm.Check
                        type="switch"
                        id="custom-switch"
                        checked={state.allowMarketing}
                        name="allowMarketing"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input name="password" type="password" label="Password" />
                  </div>
                </div>

                <button className="btn btn-success mt-3" type="submit">
                  {state.loading ? (
                    <div class="spinner-border text-light" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
