import React from "react";
import { getAllTokens, getLocale, sendNotificationToUsers, uploadLocale } from "../../../Firebase/apis";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import Spinner from "../../../components/common/Spinner.jsx";
import { Tab, Tabs } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Input from "../../../components/common/input.jsx";
import Textarea from "../../../components/common/textArea.jsx";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState(null);
  const [users, setUsers] = useState([]);
  const initialState = {
    title: "",
    body: "",
  };

  const validations = yup.object().shape({
    title: yup.string().required(),
    body: yup.string().required(),
  });

  useEffect(() => {
    getAllTokens().then((toks) => {
      setUsers(toks.map(it => it.deviceTokens));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getLocale().then((data) => {
      setLocale(data);
      setLoading(false);
    });
  }, []);
  const changeHandler = async (event) => {
    if (!event.target.files[0]) return;
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = async (e) => {
      try {
        const data = JSON.parse(e.target.result);
        await uploadLocale(data, event.target.name);
        toast.success("Uploaded");
      } catch (error) {
        toast.error(error.message);
      }
    };
  };
  
  const handleSubmit = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      const data = {
        ...values,
        tokens: users
      }
      await sendNotificationToUsers(data);
      setLoading(false);
      toast.success("Send to all users");
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="container">
      <div className="secondary-container">
        <h3>Settings</h3>
        <Tabs
          defaultActiveKey="notifications"
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
        >
          <Tab eventKey="notifications" title="Notifications">
            <Formik
              initialValues={initialState}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {() => (
                <div className="container">
                  <h1 className="mb-1">Custom Notification</h1>
                  <p className="mb-3">Send custom notification to all users!</p>
                  <Form>
                    <Input name="title" label="Title" />
                    <Textarea name="body" label="Body"  />
                    <button
                      className="btn btn-block btn-success mt-3"
                      style={{ padding: "1rem 7rem" }}
                      type="submit"
                    >
                      Send
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </Tab>
          <Tab eventKey="locals" title="Locales">
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Upload English Locale</label>
                  <input
                    type="file"
                    name="english"
                    className="form-control"
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Upload Arrabic Locale</label>
                  <input
                    type="file"
                    name="arrabic"
                    className="form-control"
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Index;
