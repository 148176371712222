import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { MdOutlineSearch, MdStarRate } from "react-icons/md";
import { toast } from "react-toastify";
import useSWR from "swr";
import { getReviews, setReviewReply } from "../../../Firebase/apis";
import { firestore } from "../../../Firebase/config";
import { userStatus } from "../../../utils/data";
import useStore from "../../../store/store";
import Spinner from "./../../../components/common/Spinner.jsx";

export const Reviews = () => {
  const { data } = useSWR("get/reviews", getReviews);
  const [reply, setReply] = useState("");
  const [reviewId, setReviewId] = useState("");
  const [showModel, setModel] = useState(false);
  const { locale } = useStore((st) => st);
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const getLatestReviews = async () => {
    const reviewsRef = await firestore
      .collection("ratingReviewCollection")
      .where("giveToID", "==", user.userId)
      .where("isViewed", "==", false)
      .get();
    return reviewsRef.docs.map((item) => item.data());
  };
  const setViewReviews = async (reviews) => {
    reviews.forEach(async (item) => {
      const matchRef = firestore
        .collection("ratingReviewCollection")
        .doc(item.docID);
      await matchRef.update({
        isViewed: true,
      });
    });
  };
  useEffect(() => {
    getLatestReviews().then((items) => {
      setViewReviews(items);
    });
  }, []);

  const submitReply = async () => {
    try {
      await setReviewReply(reviewId, reply);
      toast.success("Uploaded");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const replyHandler = (id) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    setReviewId(id);
    setModel(true);
  };
  if (!data) return <Spinner />;

  return (
    <div className="container">
      <div className="breadCrumb">
        <h4 className="fs-30">{locale.venue_portal_customer_review}</h4>
      </div>
      <div className="profile_main p-3 pt-5">
        <div className="row">
          <h5 className="fs-17">{locale.reply_to_your_customer_feedback}</h5>

          <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="fixtureHeadInput mt-3">
              <div>
                <span className="fixtureInputIcon">
                  <MdOutlineSearch />
                </span>
              </div>
              <div>
                <input
                  type="text"
                  className="fixtureCustomInput fs-15"
                  placeholder={locale.search_for_team_league_review_page}
                />
              </div>
            </div>
          </div>
        </div>
        <Table responsive className="mt-5 customTable">
          <thead>
            <tr>
              <th className="fs-20">{locale.id}</th>
              <th className="fs-20">{locale.name}</th>
              <th className="fs-20">{locale.rating}</th>
              <th className="fs-20">{locale.comment}</th>
              <th className="fs-20">{locale.reply}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr className="mt-5">
                <td>{i + 1}</td>
                <td>{item.user && item.user.name}</td>
                <td>
                  <span className="star">
                    <MdStarRate />
                  </span>
                  {item.rating}
                </td>
                <td>{item.review}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => replyHandler(item.docID)}
                  >
                    {locale.reply}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={showModel} onHide={() => setModel(false)}>
          <Modal.Body className="modal-body">
            <h2 className="modal-title">{locale.reply_to_comment}</h2>

            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <p htmlFor="contactNo"> {locale.reply} </p>
              <input
                type="text"
                id="contactNo"
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                placeholder="reply"
              />
            </div>
            <button className="btn btn-custom mt-5" onClick={submitReply}>
              {locale.portal_review_btn_submit}
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
