import React, { useState, useMemo, useEffect } from "react";
import useSWR from "swr";
import { getMatches } from "../../../Firebase/apis";
import Spinner from "../../../components/common/Spinner.jsx";
import FixturesView from "./fixturesView";
import { firestore } from "../../../Firebase/config";
import useStore from '../../../store/store.js';

export const Fixtures = () => {
  const currentDate = new Date();
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [term, setTerm] = useState("");
  const { activeLanguage } = useStore((st) => st);
  const { data, mutate } = useSWR("/admin/get/portal_matches", () => getMatches(term, activeLanguage));

  const getLatestMatches = async () => {
    const matchesRef = await firestore.collection("matches").get();
    const filterRes = matchesRef.docs.filter(
      (item) =>
        item.data().viewedPortals &&
        !item.data().viewedPortals.includes(portal.userId)
    );
    return filterRes.map((item) => item.data());
  };

  const changeHandler = (value) => {
    setTerm(value);
  };
  const setViewMatches = async (matches) => {
    matches.forEach(async (item) => {
      const matchRef = firestore.collection("matches").doc(item.docId);
      const viewedPortals = item.viewedPortals ? [...item.viewedPortals] : [];
      viewedPortals.push(portal.userId);
      await matchRef.update({
        viewedPortals,
      });
    });
  };

  useEffect(() => {
    getLatestMatches().then((items) => {
      setViewMatches(items);
    });
  }, []);

  const transformData = useMemo(() => {
    const clubsMatches = [];
    const countryMatches = [];
    const myMatches = [];

    data &&
      data.forEach((match) => {
        if (match.reservations && match.reservations.includes(portal.userId)) {
          myMatches.push(match);
        }
        if (match.isNationalMatch) {
          const clubIndex = countryMatches.findIndex(
            (c) => c.clubId === match.league.docId
          );
          if (clubIndex < 0) {
            const obj = {
              clubId: match.league.docId,
              name: match.league.name,
              nameInArabic: match.league.nameInArabic,
              image: match.league.image,
              matches: [match],
            };
            countryMatches.push(obj);
          } else {
            countryMatches[clubIndex].matches.push(match);
          }
        } else {
          const clubIndex = clubsMatches.findIndex(
            (c) => c.clubId === match.league.docId
          );
          if (clubIndex < 0) {
            const obj = {
              clubId: match.league ? match.league.docId : "",
              name: match.league ? match.league.name : "",
              nameInArabic: match.league.nameInArabic,
              image: match.league ? match.league.image : null,
              matches: [match],
            };
            clubsMatches.push(obj);
          } else {
            clubsMatches[clubIndex].matches.push(match);
          }
        }
      });
    return { clubsMatches, countryMatches, myMatches };
  }, [data, activeIndex]);

  if (!data) return <Spinner />;
  return (
    <FixturesView
      data={transformData}
      changeHandler={changeHandler}
      activeindex={activeIndex}
      setActiveIndex={setActiveIndex}
    />
  );
};
