import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { loginVenuePortal, sendPassMail } from "../../../Firebase/apis";
import LoginComponent from "./../../../components/login/Login";
import Navbar from "./../../../components/Navbar";
import useStore from "../../../store/store";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [email, setEmail] = useState("");
  const { search } = useLocation();
  const { locale } = useStore((st) => st);

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      const res = await loginVenuePortal(values);
      if (res) {
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  const sendMail = async () => {
    if (email.length <= 0) return toast.error("Email shouldn't be empty");
    try {
      await sendPassMail(email);
      toast.success("send!!");
      setShowModel((p) => !p);
    } catch (error) {
      setShowModel((p) => !p);
      toast.error(error.message);
    }
  };

  if (search.includes("register")) return <Navigate to={"/portal/register"} />;
  return (
    <div>
      <Navbar loginLink="/portal" isPortal={true} />
      <LoginComponent
        loading={loading}
        submitHandler={submitHandler}
        primaryTitle={locale.welcome_back}
        secondaryTitle={locale.login_to_your_account}
        registerLink="/portal/register"
        forgotHandler={() => setShowModel((p) => !p)}
      />
      <Modal show={showModel} onHide={() => setShowModel((p) => !p)}>
        <Modal.Title>{locale.forgot_password}</Modal.Title>
        <Modal.Body>
          <input
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={locale.enter_email_send_link}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowModel((p) => !p)}
            >
              {locale.close}
            </button>
            <button className="btn btn-success" onClick={sendMail}>
              {locale.submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
