import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/Portal/Dashboard";
import { Profile } from "../pages/Portal/Profile";
import { Register } from "../pages/Portal/Register";
import { Login } from "./../pages/Portal/Login/index";
import { Fixtures } from "./../pages/Portal/fixtures";
import { AdminMessages } from "./../pages/Portal/AdminMessages";
import Sidebar from "../components/sidebar";
import { TableManagement } from "../pages/Portal/table-management";
import { Offers } from "./../pages/Portal/Offers/index";
import { OfferForm } from "./../pages/Portal/Offers/OfferForm";
import { SocialManagment } from "../pages/Portal/SocialManagement";
import { Reviews } from "../pages/Portal/Reviews";
import { Settings } from "../pages/Portal/Settings";
import { Memberships } from "../pages/Portal/Memebership";
import { ContactUs } from "../pages/Portal/ContactUs";
import { Statistics } from "../pages/Portal/Statistics";
import useStore from "../store/store";
import { getLocale } from "../Firebase/apis";
import Spinner from "../components/common/Spinner.jsx";

const appLocale = sessionStorage.getItem("fanspot-locale");
const PortalRoutes = () => {
  const [loading, setLoading] = React.useState(true);
  const { locale, setAppLocale, setLocale } = useStore((st) => st);
  let portal = sessionStorage.getItem("fanspot-portal");
  portal = portal && JSON.parse(portal);
  const logoutHandler = () => {
    const c = window.confirm(locale.are_you_sure_to_logout);
    if (!c) return;
    sessionStorage.removeItem("fanspot-portal");
    window.location.replace("/portal");
  };

  // useEffect(() => {
  //   if (portal) {
  //     if (!portal.renevalPortalDate && !checkDateIsGreaterThanToday(portal.renevalPortalDate)) {
  //       reneveVenueMatches(portal.userId);
  //     }
  //   }
  // }, [])

  useEffect(() => {
    getLocale()
      .then((data) => {
        setAppLocale(data.arrabicLocale, data.englishLocale);
        setLocale(
          appLocale && appLocale.includes("English")
            ? data.englishLocale
            : data.arrabicLocale
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Spinner />;
  }
  return !portal ? (
    <Routes>
      <Route path="/portal" element={<Login />} />
      <Route path="/portal/register" element={<Register />} />
    </Routes>
  ) : (
    <Sidebar logoutHandler={logoutHandler}>
      <Routes>
        <Route path="/portal" element={<Navigate to={"/portal/dashboard"} />} />
        <Route path="/portal/dashboard" element={<Dashboard />} />
        <Route path="/portal/profile" element={<Profile />} />
        <Route path="/portal/fixtures" element={<Fixtures />} />
        <Route path="/portal/offers" element={<Offers />} />
        <Route path="/portal/stats" element={<Statistics />} />
        <Route path="/portal/offers/add" element={<OfferForm />} />
        <Route path="/portal/tablemanagement" element={<TableManagement />} />
        <Route path="/portal/socialmanagement" element={<SocialManagment />} />
        <Route path="/portal/reviews" element={<Reviews />} />
        <Route path="/portal/settings" element={<Settings />} />
        <Route path="/portal/memberships" element={<Memberships />} />
        <Route path="/portal/contactus" element={<ContactUs />} />
        <Route path="/portal/inbox" element={<AdminMessages />} />
      </Routes>
    </Sidebar>
  );
};

export default PortalRoutes;
