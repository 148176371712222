import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";

const containerStyle = {
  width: "100%",
  height: "350px",
};

const center = {
  lat: 24.7136,
  lng: 46.6753,
};

function MyComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA",
  });

  const [map, setMap] = useState(null);
  const [position, setPosition] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });

  useEffect(() => {
    if (props.lat !== 0 && props.lng !== 0) {
      setPosition({
        lat: props.lat,
        lng: props.lng,
      });
    }
  }, [props.lat, props.lng]);

  const handleClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const coords = { lat: lat, lng: lng };
    Geocode.setApiKey("AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        props.selectedLocation({ address, ...coords });
        setPosition({ lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(position);
    map.fitBounds(bounds);
    setMap(map);
    if (props.lat === 0 && props.lng === 0) {
      setTimeout(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            setPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          });
        }
      }, 2000);
    }
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={8}
      onLoad={onLoad}
      onClick={handleClick}
      onUnmount={onUnmount}
    >
      <Marker position={position} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
