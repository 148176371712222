import React from "react";
import { useLocation } from "react-router-dom";
import { StatisticsComponent } from "../../../components/Statistics";

const VenueStats = () => {
  const location = useLocation();
  return (
    <div className="container">
      <div className="breadCrumb">
        <h4>{location.state.venueName} | Statistics</h4>
      </div>
      <StatisticsComponent portal={location.state} />
    </div>
  );
};

export default VenueStats;
