import React from "react";
import "./style.css";
import { days, months } from "../../../utils/data";

const Index = ({ match }) => {
  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const getTime = (t) => {
    const d = new Date(t);
    const options = { hour: "numeric", minute: "numeric" };
    return d.toLocaleTimeString(undefined, options);
  };

  const getOnlyDate = (t) => {
    const d = new Date(t);
    return (
      days[d.getDay()] +
      ", " +
      d.getDate() +
      " " +
      months[d.getMonth()] +
      " " +
      d.getFullYear()
    );
  };
  return (
    <div className="seven-template-container">
      <div class="seven-template-inner-container" id="selectedTemplate">
        <img
          src="/assets/Template7.png"
          alt="seven Template"
          className="bg-image"
        />
        <img
          src={`/assets/Flags/${match.firstTeam.flagName}.png`}
          alt="First Team"
          className="first-team-img"
        />
        <img
          src={`/assets/Flags/${match.secondTeam.flagName}.png`}
          alt="Second Team"
          className="second-team-img"
        />
        <h1 className="matchDate">
          <span>{getOnlyDate(match.startTime)}</span>
          <span>|</span>
          <span>{getTime(match.startTime)}</span>
        </h1>
        <h1 className="first-team-name">
          <span>{match.firstTeam.name}</span>
        </h1>
        <h1 className="second-team-name">
          <span>{match.secondTeam.name}</span>
        </h1>
        <h1 className="leagueName">
          <span>{match.league.name}</span>
        </h1>
        <h1 className="venueName">{user.venueName}</h1>
        <h1 className="city">{user.city}</h1>
      </div>
    </div>
  );
};

export default Index;
