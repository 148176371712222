import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar/SecondVariant";
import FAQS from "./faqs";
import useStore from "../../../store/store";
import Footer from "../../../components/Footer/SecondVariant";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "./style.css";

export const VenueHome = () => {
  const { locale, activeLanguage } = useStore((st) => st);
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  useEffect(() => {
    const c = document.getElementById("buisnessHome");
    if (c) {
      c.click();
    }
  }, []);

  return (
    <div>
      <Navbar />
      <a className="nav-link" href="#home" id="buisnessHome"></a>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={5000}
      >
        <section className="herodesktop" id="home">
          <div className="container"> 
            <div className="heroDesktopContent">
              <h1 className="fs-40">{locale.business_1}</h1>
              <p className="fs-20">{locale.business_2}</p>
              <p className="text-center">
                <button
                  className="btn btn-primary btnCustom px-5 py-2 mt-4 fs-20"
                  onClick={() => window.open("/portal?register=true", "_self")}
                >
                  {locale.register_now}
                </button>
              </p>
            </div>
          </div>
        </section>
      </AutoplaySlider>
      <section>
        <div className="empty-container" id="benifits"></div>
        <div className="venue2 mt-5">
          <div className="container-fluid">
            <h1 className="mb-5 fs-40">{locale.business_3}</h1>
            <div className="row ">
              <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div className="card text-left">
                  <img
                    className="card-img-top"
                    src="/assets/venueHome/Venueprofile.png"
                    alt=""
                  />
                  <div className="card-body">
                    <h4 className="card-title fs-30">{locale.business_4}</h4>
                    <p className={`fs-20 card-text ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.business_5}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4  mt-4">
                <div className="card text-left">
                  <img
                    className="card-img-top"
                    src="/assets/venueHome/Insights.png"
                    alt=""
                  />
                  <div className="card-body">
                    <h4 className="card-title fs-30">{locale.business_6}</h4>
                    <p className={`fs-20 card-text ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.business_7}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4  mt-4">
                <div className="card text-left">
                  <img
                    className="card-img-top"
                    src="/assets/venueHome/CrowdManagement.png"
                    alt=""
                  />
                  <div className="card-body">
                    <h4 className="card-title fs-30">{locale.business_8}</h4>
                    <p className={`fs-20 card-text ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.business_9}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="empty-container" id="getting-started"></div>
        <div className="steps">
          <div className="container-fluid">
            <h1 className="fs-40">{locale.business_10}</h1>
            <p className="fs-20">{locale.business_11}</p>
            <div className="row ">
              <div className="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div className="card text-left">
                  <img
                    className="card-img-top"
                    src="/assets/venueHome/matches.png"
                    alt=""
                  />
                  <div className="card-body">
                    <h4 className="card-title fs-30">{locale.business_12}</h4>
                    <p className="card-text fs-20">{locale.business_13}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4  mt-4">
                <div className="card text-left">
                  <img
                    className="card-img-top"
                    src="/assets/venueHome/fixture.png"
                    alt=""
                  />
                  <div className="card-body">
                    <h4 className="card-title fs-30">{locale.business_14}</h4>
                    <p className="card-text fs-20">{locale.business_15}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4  mt-4">
                <div className="card text-left">
                  <img
                    className="card-img-top"
                    src="/assets/venueHome/social.png"
                    alt=""
                  />
                  <div className="card-body">
                    <h4 className="card-title fs-30">
                      {locale.business_16}
                    </h4>
                    <p className="card-text fs-20">
                      {locale.business_17}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="faqs" className="empty-container"></div>
        <FAQS />
      </section>
      <Footer />
    </div>
  );
};
