import React from "react";
import { registerCountry, updateCountry } from "../../../Firebase/apis";
import * as yup from "yup";
import { useState } from "react";
import { Form, Formik } from "formik";
import Input from "../../../components/common/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Spinner from "../../../components/common/Spinner.jsx";
import { MdAssignment } from "react-icons/md";

export const CountryForm = () => {
  const [data, setData] = useState({
    name: "",
  });
  const [state, setState] = useState({
    id: "",
    image: null,
    loading: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      setState((c) => ({
        ...c,
        id: location.state.docId,
        image: location.state.image,
      }));
      setData((c) => ({ ...c, name: location.state.name }));
    }
    setState((c) => ({ ...c, loading: false }));
  }, []);

  const validations = yup.object().shape({
    name: yup.string().required(),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading();
      if (location.state) {
        await updateCountry({ ...values, image: state.image, id: state.id });
      } else {
        await registerCountry({ ...values, image: state.image });
      }
      navigate("/admin/countries");
    } catch (error) {
      setLoading();
      alert(error.message);
    }
  };

  const setLoading = () => {
    setState((prev) => ({ ...prev, loading: !prev.loading }));
  };
  const fileHandler = (e) => {
    setState((pre) => ({
      ...pre,
      image: e.target.files[0],
    }));
  };
  if (state.loading) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <Formik
          initialValues={data}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {() => (
            <div>
              <h1 className="mb-2">{location.state ? "Edit" : "Add"}Country</h1>
              <div className="formInput formFileInput">
                <button className="btnFile">
                  <span className="fileIcon">
                    <MdAssignment />
                  </span>
                  Upload logo
                </button>
                <input
                  onChange={fileHandler}
                  type="file"
                  name="logo"
                  className="fileInput"
                />
                {state.image && (
                  <img
                    width="100%"
                    height="200px"
                    src={
                      state.image.size
                        ? URL.createObjectURL(state.image)
                        : state.image
                    }
                    alt="logo"
                  />
                )}
              </div>
              <Form>
                <Input name="name" type="text" label="Name" />

                <button
                  className="btn btn-block btn-success mt-3 px-5"
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
