import React, { useEffect } from "react";
import LandingComponent from "../../../components/LandingComponent";
import { useLocation, useNavigate } from "react-router-dom";

export const Landing = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (search.includes("buisness")) {
        navigate("/buisness");
      }
    }, 100);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return <LandingComponent />;
};
