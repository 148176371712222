import React from "react";
import {
  getAllLeagues,
  registerMatch,
  updateMatch,
} from "../../../Firebase/apis";
import * as yup from "yup";
import { useState } from "react";
import { Form, Formik } from "formik";
import Input from "../../../components/common/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Spinner from "../../../components/common/Spinner.jsx";
import Select from "../../../components/common/select";
import useSWR from "swr";
import { Form as BootstrapForm } from "react-bootstrap";
export const MatchForm = () => {
  const [dataForm, setDataForm] = useState({
    firstTeamId: "",
    secondTeamId: "",
    startTime: "",
    round: "",
    address: "",
    clubId: "",
  });

  const [state, setState] = useState({
    id: "",
    loading: true,
    isNationalMatch: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useSWR("/admin/get/getAllLeagues", getAllLeagues);

  useEffect(() => {
    if (location.state) {
      setState((c) => ({
        ...c,
        id: location.state.docId,
        isNationalMatch: location.state.isNationalMatch,
      }));
      setDataForm({
        firstTeamId: location.state.firstTeamId,
        secondTeamId: location.state.secondTeamId,
        startTime: location.state.startTime,
        round: location.state.round,
        clubId: location.state.clubId,
      });
    }
    setState((c) => ({ ...c, loading: false }));
  }, []);

  const validations = yup.object().shape({
    round: yup.string().required(),
    firstTeamId: yup.string().required(),
    secondTeamId: yup.string().required(),
    startTime: yup.string().required(),
    clubId: yup.string().required(),
  });

  const getDate = (time) => {
    const d = new Date(time);
    return d.toLocaleDateString();
  };

  const handleSubmit = async (values) => {
    let leagueID = "";
    let countryID = "";
    leagueID = values.clubId;
    try {
      setLoading();
      if (location.state) {
        await updateMatch({
          ...values,
          isNationalMatch: state.isNationalMatch,
          id: state.id,
          teams: [values.firstTeamId, values.secondTeamId],
          countryID,
          leagueID,
          matchDate: getDate(values.startTime),
        });
      } else {
        await registerMatch({
          ...values,
          teams: [values.firstTeamId, values.secondTeamId],
          isNationalMatch: state.isNationalMatch,
          countryID,
          leagueID,
          isTopMatch: false,
          viewedPortals: [],
          matchDate: getDate(values.startTime),
        });
      }
      navigate("/admin/matches");
    } catch (error) {
      setLoading();
      alert(error.message);
    }
  };

  const setLoading = () => {
    setState((prev) => ({ ...prev, loading: !prev.loading }));
  };

  const matchStatusHandler = (e) => {
    setState((pre) => ({
      ...pre,
      isNationalMatch: e.target.checked,
    }));
  };

  const getFilterTeams = () => {
    return data.teams.filter(
      (item) => item.isNationalTeam === state.isNationalMatch
    );
  };

  if (state.loading || !data) return <Spinner />;
  return (
    <div className="container">
      <div className="secondary-container">
        <Formik
          initialValues={dataForm}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {() => (
            <div>
              <h1 className="mb-2">
                {location.state ? "Edit " : "Add "} Match
              </h1>

              <div className="profileMain_portal">
                <h3 className="font-weight-bold">Is National Team</h3>

                <BootstrapForm.Check
                  type="switch"
                  id="custom-switch"
                  checked={state.isNationalMatch}
                  name="isNationalMatch"
                  onChange={matchStatusHandler}
                />
              </div>
              <Form>
                <Input
                  name="startTime"
                  type="datetime-local"
                  label="Start Time"
                />
                <Select
                  data={data.leagues}
                  searchKey="docId"
                  searchValue="name"
                  name="clubId"
                  label="Club"
                />
                <Input name="round" type="text" label="Round" />
                <Select
                  data={getFilterTeams()}
                  searchKey="docId"
                  searchValue="name"
                  name="firstTeamId"
                  label="First Team"
                />
                <Select
                  data={getFilterTeams()}
                  searchKey="docId"
                  searchValue="name"
                  name="secondTeamId"
                  label="Second Team"
                />

                <button
                  className="btn btn-block btn-success mt-3 px-5"
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
