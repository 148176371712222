import React from "react";
import { useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import useStore from "../../../store/store";
const FaqItem = ({ question, answer }) => {
  const [active, setActive] = useState(false);
  const { activeLanguage } = useStore((st) => st);
  const getAnswer = () => {
    return answer.split("●");
  };
  return (
    <>
      <li
        className="list-group-item1 pointer"
        onClick={() => setActive((a) => !a)}
      >
        <div className="list-item pointer" style={{ flexDirection: activeLanguage && activeLanguage.includes("Arrabic") ? "row-reverse" : "row" }}>
          <span className="fs-20">{question}</span>
          {active ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
        </div>

        {active && (
          <>
            {getAnswer().map((item) => (
              <p className={`pt-2 fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{item}</p>
            ))}
          </>
        )}
      </li>
    </>
  );
};

export default FaqItem;
