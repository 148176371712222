import React, { useEffect, useState } from "react";
import "./normalize.css";
import "./style.css";
import { Link } from "react-router-dom";
import useStore from "../../store/store";
import Footer from "../../components/Footer/SecondVariant";
import Spinner from "../common/Spinner.jsx";
import { Modal } from "react-bootstrap";
import { RestaurantForm } from "../RestaurantFormForPopup";
import { addRestaurant, addUserMessage } from "../../Firebase/apis";
import { toast } from "react-toastify";
import isMobileScreen from "./../../hooks/isMobileScreen.jsx";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [showRestaurentPopup, setShowRestaurentPopup] = useState(false);
  const isMobile = isMobileScreen();
  const [collapse, setCollapse] = useState(false);
  const {
    setLocale,
    locale,
    setLanguage,
    englishLocale,
    arrabicLocale,
    activeLanguage
  } = useStore((st) => st);
  const [contactFormm, setContactForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
  });

  useEffect(() => {
    const c = document.getElementById("clientlandingHeroHome");
    if (c) {
      c.click();
    }
  }, []);

  const contactChangeHandler = (e) => {
    setContactForm((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const changeHandler = (e) => {
    if (!e) return;
    if (e.includes("Arrabic")) {
      setLocale(arrabicLocale);
      sessionStorage.setItem("fanspot-locale", e);
      setLanguage(e);
    } else if (e.includes("English")) {
      sessionStorage.setItem("fanspot-locale", e);
      setLocale(englishLocale);
      setLanguage(e);
    }
  };
  const clickHandler = (url) => {
    const el = document.createElement("a");
    el.href = url;
    el.setAttribute("target", "_blank");
    el.click();
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await addRestaurant({
        ...values,
        offerReq: true,
      });
      toast.success("Added successfully");
      setLoading(false);
      setShowRestaurentPopup(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };
  const contactSubmitHadnler = async () => {
    try {
      if (
        !contactFormm.email.trim().length ||
        !contactFormm.name.trim().length ||
        !contactFormm.description.trim().length
      )
        return toast.error("All fields are required");
      setLoading(true);
      await addUserMessage({
        email: contactFormm.email,
        subject: contactFormm.name,
        body: contactFormm.description,
        message: null,
      });
      toast.success("Added successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };
  const currentLocale = sessionStorage.getItem("fanspot-locale");
  if (loading) return <Spinner />;
  return (
    <>
      <div
        className="landing-container header"
        style={{ height: isMobile && collapse ? "650px" : "90px" }}
      >
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg  bg-custom">
            <a className="navbar-brand" href="/">
              <img
                src="/assets/LandingImages/logo.png"
                width="150px"
                alt="alternatText"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setCollapse((p) => !p)}
            >
              <span className="navbar-toggler-icon">
                <img
                  className="toggleImage"
                  src="/assets/LandingImages/menu.png"
                  height="20px"
                  alt="alternatText"
                />
              </span>
            </button>

            {!isMobile ? (
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ml-auto">
                  <div className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alight-pc-right" : "alight-pc-left"}`}>
                    <li className="nav-item active">
                      <a
                        className="nav-link"
                        href="#clientlandingHero"
                        id="clientlandingHeroHome"
                      >
                        {locale.home}
                        <span className="sr-only">({locale.current})</span>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="#clientaboutus">
                        {locale.about_us}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#clientthescience">
                        {locale.the_science}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#clientfeatures">
                        {locale.features}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#clientbuisness">
                        {locale.buisness}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#clientcontactus">
                        {locale.contact_us}
                      </a>
                    </li>
                  </div>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#test"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {sessionStorage.getItem("fanspot-locale")
                        ? sessionStorage
                          .getItem("fanspot-locale")
                          .includes("English")
                          ? "English"
                          : "العربية"
                        : "العربية"}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <option
                        className="dropdown-item"
                        onClick={() => {
                          changeHandler("English");
                          setCollapse(false)
                        }}
                      >
                        English
                      </option>
                      <option
                        className="dropdown-item"
                        onClick={() => {
                          changeHandler("Arrabic");
                          setCollapse(false)
                        }}
                      >
                        العربية
                      </option>
                    </div>
                  </li>
                  <li className="nav-item  venueButton ">
                    <Link className="nav-link linkBtn" to="/buisness">
                      {locale.IMVenue}
                    </Link>
                  </li>
                </ul>
              </div>
            ) : collapse ? (
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active" onClick={() => setCollapse(false)}>
                    <a
                      className="nav-link"
                      href="#clientlandingHero"
                      id="clientlandingHeroHome"
                    >
                      {locale.home}
                      <span className="sr-only">({locale.current})</span>
                    </a>
                  </li>

                  <li className="nav-item" onClick={() => setCollapse(false)}>
                    <a className="nav-link" href="#clientaboutus">
                      {locale.about_us}
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => setCollapse(false)}>
                    <a className="nav-link" href="#clientthescience">
                      {locale.the_science}
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => setCollapse(false)}>
                    <a className="nav-link" href="#clientfeatures">
                      {locale.features}
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => setCollapse(false)}>
                    <a className="nav-link" href="#clientbuisness">
                      {locale.buisness}
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => setCollapse(false)}>
                    <a className="nav-link" href="#clientcontactus">
                      {locale.contact_us}
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#test"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {sessionStorage.getItem("fanspot-locale")
                        ? sessionStorage
                          .getItem("fanspot-locale")
                          .includes("English")
                          ? "English"
                          : "العربية"
                        : "English"}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <option
                        className="dropdown-item"
                        onClick={() => {
                          changeHandler("English");
                          setCollapse(false)
                        }}
                      >
                        English
                      </option>
                      <option
                        className="dropdown-item"
                        onClick={() => {
                          changeHandler("Arrabic");
                          setCollapse(false)
                        }}
                      >
                        العربية
                      </option>
                    </div>
                  </li>
                  <li className="nav-item  venueButton ">
                    <Link className="nav-link linkBtn" to="/buisness">
                      {locale.IMVenue}
                    </Link>
                  </li>
                </ul>
              </div>
            ) : ""}
          </nav>
        </div>
      </div>

      <section className="landingHero" id="clientlandingHero">
        <div className="container-fluid main-app-wrapper">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="heroContent">
                <h1 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_1}</h1>
                <p className={`sub-heading fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_2}</p>
                <div className="stores">
                  <div
                    className="storeBtn"
                    onClick={() => clickHandler("https://www.apple.com/store")}
                  >
                    <img
                      src={
                        currentLocale && currentLocale.includes("Arrabic")
                          ? "/assets/icons/applestorearabic.svg"
                          : "/assets/icons/applestore.svg"
                      }
                      height="40px"
                      alt="alternatText"
                    />
                  </div>

                  <div
                    className="storeBtn"
                    onClick={() =>
                      clickHandler("https://play.google.com/store/games?pli=1")
                    }
                  >
                    <img
                      src={
                        currentLocale && currentLocale.includes("Arrabic")
                          ? "/assets/icons/playstorearabic.png"
                          : "/assets/icons/playstore.png"
                      }
                      alt="alternatText"
                      className="playstore-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                src="/assets/LandingImages/heroImg.png"
                className="heroImg img-fluid"
                alt="alternatText"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="about">
          <div className="empty-container" id="clientaboutus" style={{ height: "90px" }}></div>
          <div className={`container-fluid main-app-wrapper ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
            <h5 id="clientaboutus" className={`fs-40 mb-5 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
              {locale.about_fanspot}
            </h5>
            <h1 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_5}</h1>
            <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
              {locale.landing_6}
              {/* <br /> {locale.speacial_pleasent} */}
            </p>
          </div>
        </div>
      </section>
      <section>
        <div
          style={{
            height: "30px",
            background: "#00985f",
          }}
          id="clientthescience"
        ></div>
        <div className="about2">
          <div className={`container-fluid main-app-wrapper ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
            <h5 className={`fs-30 mb-5 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.the_sciences}</h5>
            <h1 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_7}</h1>

            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className="about2Card">
                  <h3 className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_8}</h3>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_9}</p>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className="about2Card">
                  <h3 className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_10}</h3>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_11}</p>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className="about2Card">
                  <h3 className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_12}</h3>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_13}</p>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className="about2Card">
                  <h3 className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_14}</h3>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_15}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="empty-container" id="clientfeatures"></div>
        <div className="features">
          <div className={`container-fluid main-app-wrapper ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
            <h1 className={`fs-40`}>{locale.key_features}</h1>

            <div className="row mt-5">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="featureContent">
                  <h2 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_16}</h2>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_17}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <img
                  src="/assets/LandingImages/feature1.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <img
                  src="/assets/LandingImages/feature3.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="featureContent">
                  <h2 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_18}</h2>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_19}</p>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="featureContent">
                  <h2 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_20}</h2>
                  <ul style={{ paddingLeft: "0", listStyle: "none" }}>
                    <li className="mb-3 fs-20">{locale.landing_21}</li>
                    {/* <li className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
                      {locale.predict_game_of_the_month}
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <img
                  src="/assets/LandingImages/feature2.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="empty-container" id="clientbuisness"></div>
        <div className="offer">
          <div className={`container-fluid main-app-wrapper ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>
            <h1 className={`fs-40`}>{locale.venue_manage_main_heading}</h1>

            <div className="row" style={{ marginTop: "111px" }}>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <img
                  src="/assets/LandingImages/offer22.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="offerContent">
                  <h5 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_22}</h5>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_23}</p>

                  <button
                    className="btn btn-secondary btnCustom fs-20"
                    onClick={() =>
                      window.open("/portal?register=true", "_self")
                    }
                  >
                    {locale.register_venue}
                  </button>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "111px" }}>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="offerContent">
                  <h5 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_24}</h5>
                  <p className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.landing_25}</p>

                  <button
                    className="btn btn-secondary btnCustom fs-20"
                    onClick={() => setShowRestaurentPopup(true)}
                  >
                    {locale.register_restaurent}
                  </button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <img
                  src="/assets/LandingImages/offer11.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="empty-container" id="clientcontactus"></div>
        <div className="contact">
          <div className="container-fluid main-app-wrapper">
            <h1 className={`fs-40 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : "text-center"}`}>{locale.contact_us}</h1>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <img
                  src="/assets/LandingImages/contactImage.png"
                  className="img-fluid"
                  alt="alternatText"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className="inputs">
                  <div className="input">
                    <span className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.your_name}</span>
                    <input
                      type="text"
                      className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}
                      placeholder="Enter your name"
                      name="name"
                      onChange={contactChangeHandler}
                    />
                  </div>

                  <div className="input">
                    <span className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.phone_number}</span>
                    <input
                      type="text"
                      className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}
                      placeholder="Phone Number"
                      name="phoneNumber"
                      onChange={contactChangeHandler}
                    />
                  </div>

                  <div className="input">
                    <span className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.email}</span>
                    <input
                      type="text"
                      className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}
                      placeholder="Email"
                      name="email"
                      onChange={contactChangeHandler}
                    />
                  </div>

                  <div className="input">
                    <span className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.how_can_we_help_you}</span>
                    <textarea
                      type="text"
                      className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}
                      rows="6"
                      name="description"
                      onChange={contactChangeHandler}
                      placeholder="Your Message..."
                    ></textarea>
                  </div>

                  <div className="input" onClick={contactSubmitHadnler}>
                    <button className="btn btn-custom fs-20">
                      {locale.contact_now}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        size="lg"
        show={showRestaurentPopup}
        onHide={() => setShowRestaurentPopup(false)}
      >
        <RestaurantForm
          cancelHandler={() => setShowRestaurentPopup(false)}
          submitHandler={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default Index;
