import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import LoginComponent from "../../../components/login/Login";
import { loginUser } from "../../../Firebase/apis";
import Navbar from "../../../components/Navbar";

export const Login = () => {
  const [loading, setLoading] = useState(false);

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      const res = await loginUser(values);
      if (res) {
        toast.success("Login successfully");
        window.location.replace("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Navbar loginLink="/portal" client={true} />
      <LoginComponent
        loading={loading}
        registerLink="/register"
        submitHandler={submitHandler}
        primaryTitle="Welcome Back!"
        secondaryTitle="Login Client"
      />
    </>
  );
};
