import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { getClientsFaqs } from "../../../Firebase/apis";
import FaqItem from "./FaqItem";
import useStore from "../../../store/store";

const FAQS = () => {
  const { data } = useSWR("/get/client/faqs", getClientsFaqs);
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { locale, activeLanguage } = useStore((st) => st);

  const filterData = useMemo(() => {
    return data
      ? data.filter((item) =>
          item.question.toLowerCase().includes(search.toLowerCase())
        )
      : [];
  }, [data, search]);
  return (
    <div className="container faq-container">
      <div className="sec-container">
        <h2 className="fs-40">{locale.business_18}</h2>
        <p className="fs-20">{locale.business_19}</p>
      </div>

      <div className="row my-3">
        <div className="col-sm-6">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="form-control fs-20"
            placeholder="Search"
          />
        </div>
      </div>
      <ul>
        {filterData.slice(0, itemsPerPage).map((item) => (
          <FaqItem
            question={
              activeLanguage && activeLanguage.includes("Arrabic")
                ? item.questionInArrabic
                : item.question
            }
            answer={
              activeLanguage && activeLanguage.includes("Arrabic")
                ? item.answerInArrabic
                : item.answer
            }
          />
        ))}
        {search.length > 0 && filterData.length === 0 && (
          <p className="fs-20">
            {locale.if_not_found_in_faqs}
            {<Link to="/contactus">{locale.contact_us}</Link>}
          </p>
        )}
      </ul>

      {filterData && filterData.length > 0 && filterData.length > itemsPerPage && (
        <span className="d-flex justify-content-center">
          <button
            className="btn primary-bg-color"
            onClick={() => setItemsPerPage((c) => c + 10)}
          >
            <BsChevronDoubleDown />
          </button>
        </span>
      )}
    </div>
  );
};

export default FAQS;
