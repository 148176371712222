import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import useSWR from "swr";
import RegisterComponent from "../../../components/register/Register";
import {
  addNotificationForAdmin,
  getRegions,
  registerVenuePortal,
} from "../../../Firebase/apis";
import Spinner from "../../../components/common/Spinner.jsx";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store/store";

export const Register = () => {
  const [loading, setLoading] = useState(false);
  const { data } = useSWR("/get/get/regisons", getRegions);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { locale } = useStore((st) => st);
  const submitHandler = async (values) => {
    try {
      setLoading(true);
      await registerVenuePortal({
        ...values,
        adminVerified: false,
        isCompleted: true,
        status: true,
      });
      const message =
        "New Venue has been registered, Please have a look and approve!";
      const route = "admin/venueportals";
      await addNotificationForAdmin(message, route);
      setShow(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  if (!data) return <Spinner />;
  return (
    <div>
      <Navbar loginLink="/portal" isPortal={true} />
      <RegisterComponent
        regions={data}
        loading={loading}
        submitHandler={submitHandler}
      />
      <Modal show={show} onHide={() => navigate("/portal")} size="lg">
        <Modal.Header>
          <Modal.Title>{locale.registration_completed}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "1.3rem" }}>
            {locale.registration_req_completed}
          </p>
          <Button
            className="mt-4 px-3"
            variant="success"
            onClick={() => navigate("/portal")}
          >
            {locale.login_to_my_account}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
