import React from "react";
import { useField, ErrorMessage } from "formik";
import { useEffect } from "react";
import useStore from "../../store/store";

const Input = ({ label, changeHandler, ...props }) => {
  const [field, meta] = useField(props);
  const { locale, activeLanguage } = useStore((st) => st);

  useEffect(() => {
    changeHandler(field.name, meta.value);
  }, [meta.value]);
  return (
    <div className="mb-3">
      <label htmlFor={field.name} className={`fs-15 full-width`}>{label}</label>
      <input
        className={`form-control fs-15 ${meta.error && "is-invalid"}`}
        type="text"
        value={field.value ? field.value : ""}
        {...field}
        {...props}
      />
      <ErrorMessage
        name={field.name}
        component="div"
        style={{ color: "red" }}
      />
    </div>
  );
};

export default Input;
