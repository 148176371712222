import React, { useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import FormFrameImage from "../../assets/Frame.png";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import "./Register.css";
import Input from "../common/inputSecondVariant";
import Select from "../common/selectSecondVariant";
import Map1 from "../common/Map1";
import { toast } from "react-toastify";
import {
  CHANNELS,
  COUNTRIES,
  SUBSCRIPTIONS,
  getSubscriptions,
} from "../../utils/data";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { CustomSelect } from "../../components/common/MultiSelect";
import { useMemo } from "react";
import useSWR from "swr";
import { getRegions } from "../../Firebase/apis";
import Spinner from "../../components/common/Spinner.jsx";
import useStore from "../../store/store";

const Register = (props) => {
  const { data, mutate } = useSWR("/get/getRegions", getRegions);
  const [firstStep, setFirstStep] = useState(true);
  const [agree, setAgree] = useState(false);
  const [basicInfo, setBasicInfo] = useState(null);
  const { locale, activeLanguage } = useStore((st) => st);
  const [state, setState] = useState({
    lat: 0,
    lng: 0,
    address: null,
    showModel: false,
    region: null,
    city: null,
    subscriptionBundle: null,
  });
  const initialStateForFirst = {
    venueName: "",
    firstName: "",
    lastName: "",
    city: "",
    country: COUNTRIES[0].name,
    commercialRegister: "",
    region: "",
  };

  const initialStateForSecond = {
    email: "",
    mangerPhoneNumber: "",
    googleMapLink: "",
    password: "",
    confirmPassword: "",
    channelSubscription: [],
    paymentFrequency: "",
  };

  const validationsForFirst = yup.object().shape({
    venueName: yup
      .string()
      .required(locale.venue_register_venueName_message)
      .label("Venue Name"),
    firstName: yup.string().required(locale.venue_register_first_name_message),
    lastName: yup.string().required(locale.venue_register_last_name_message),
    country: yup.string().required(locale.venue_register_country_message),
  });
  const validationsForSecond = yup.object().shape({
    email: yup.string().required(locale.venue_register_email_message),
    mangerPhoneNumber: yup
      .string()
      .required(locale.venue_register_phone_number_message),
    password: yup.string().required(locale.venue_register_password_message),
    confirmPassword: yup
      .string()
      .required(locale.venue_register_confirm_password_message),
    channelSubscription: yup
      .array()
      .required(locale.venue_register_channel_subscription_message),
  });

  const handleSubmit = async (values) => {
    if (values) {
      if (!basicInfo) return alert(locale.basic_info_required);
      if (values.password !== values.confirmPassword)
        return alert(locale.password_not_match);
      if (state.lat === 0) return alert(locale.location_required_message);
      if (!state.subscriptionBundle)
        return alert(locale.subscription_bundle_required);

      let subscriptionExpiryDate = new Date();
      subscriptionExpiryDate = new Date(
        subscriptionExpiryDate.setFullYear(
          subscriptionExpiryDate.getFullYear() + 1
        )
      ).toLocaleDateString();
      const bundle = SUBSCRIPTIONS.find(
        (item) => item.id == state.subscriptionBundle
      );
      props.submitHandler({
        ...values,
        ...basicInfo,
        lat: state.lat,
        lng: state.lng,
        region: state.region,
        city: state.city,
        address: state.address,
        subscriptionExpiryDate,
        rating: 0,
        subscriptionBundle: bundle.name,
        subscriptions: [bundle.name],
      });
    }
  };

  const firstStepSubmit = (values) => {
    if (state.lat === 0 || state.lng === 0)
      return toast.error(locale.location_required_message);
    if (values) {
      setFirstStep((pre) => !pre);
      setBasicInfo(values);
    }
  };
  const handleModal = (e) => {
    setState((pre) => ({
      ...pre,
      showModel: !state.showModel,
    }));
  };

  const handleLocation = (loc) => {
    setState((pre) => ({
      ...pre,
      lat: loc.lat,
      lng: loc.lng,
      address: loc.address,
    }));
  };
  const handleLocationSubmition = () => {
    toast.success(locale.selected_success_message);
    handleModal();
  };
  const cancelLocationHandler = () => {
    handleModal();
  };

  const regionChangeHandler = (e) => {
    setState((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const regions = useMemo(() => {
    return data ? data.resgions : [];
  }, [data]);

  const cities = useMemo(() => {
    let i = 0;
    if (data && state.region) {
      i = data.resgions.findIndex((item) => item.province.eng === state.region);
    }
    return data ? data.resgions[i].cities : [];
  }, [data, state.region]);

  if (!data) return <Spinner />;

  return (
    <div>
      <section className="registerMain">
        <Row>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="registerForm">
              {!firstStep && (
                <span
                  className="color-primary pointer"
                  style={{ fontSize: "2rem" }}
                  onClick={() => setFirstStep(!firstStep)}
                >
                  <IoArrowBackCircleOutline />
                </span>
              )}
              <h3 className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}>{locale.create_your_fanspot_account}</h3>
              <h5 className={`fs-25 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}>{locale.enter_all_the_details}</h5>

              {firstStep ? (
                <>
                  <button
                    className={`btn btn-block btn-secondary my-2 px-5 fs-20`}
                    onClick={handleModal}
                  >
                    {locale.venue_location}
                  </button>
                  <p className={`my-2 full-width ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}>{state.address}</p>
                  <Formik
                    initialValues={initialStateForFirst}
                    validationSchema={validationsForFirst}
                    onSubmit={firstStepSubmit}
                  >
                    {() => (
                      <div>
                        <Form>
                          <Input
                            name="venueName"
                            type="text"
                            label={locale.venue_name}
                          />
                          <div className="d-flex justify-content-between">
                            <Input name="firstName" label={locale.first_name} />
                            <Input name="lastName" label={locale.last_name} />
                          </div>
                          <Select
                            data={COUNTRIES}
                            searchKey="name"
                            searchValue="name"
                            name="country"
                            label={locale.country}
                          />

                          <div className="form-group">
                            <label className={`fs-20 full-width ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`} htmlFor="region">
                              {locale.region}
                            </label>
                            <select
                              className={`fs-20 form-control ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}
                              onChange={regionChangeHandler}
                              name="region"
                              value={state.region}
                              id="region"
                            >
                              <option value="">{locale.select_region}</option>
                              {regions.map((item) => (
                                <option value={item.province.eng}>
                                  {activeLanguage &&
                                    !activeLanguage.includes("English")
                                    ? item.province.ar
                                    : item.province.eng}
                                </option>
                              ))}
                            </select>
                          </div>
                          {state.region && (
                            <div className="form-group">
                              <label className={`fs-20 full-width ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`} htmlFor="city">
                                {locale.city}
                              </label>
                              <select
                              className={`fs-20 form-control ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}
                              onChange={regionChangeHandler}
                                name="city"
                                value={state.city}
                                id="city"
                              >
                                <option value="">{locale.select_city}</option>
                                {cities.map((item) => (
                                  <option value={item.eng}>
                                    {activeLanguage &&
                                      !activeLanguage.includes("English")
                                      ? item.ar
                                      : item.eng}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                          <Input
                            name="commercialRegister"
                            label={locale.commercial_register}
                          />
                          <button
                            className="btn btn-success mt-3"
                            onClick={() => firstStepSubmit()}
                          >
                            {locale.next}
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <Formik
                    initialValues={initialStateForSecond}
                    validationSchema={validationsForSecond}
                    onSubmit={handleSubmit}
                  >
                    {() => (
                      <div>
                        <Form>
                          <Input
                            name="mangerPhoneNumber"
                            type="text"
                            label={locale.manager_phone_number}
                          />
                          <div className="form-group">
                            <label
                              className={`fs-20 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}
                              htmlFor="SubscriptionBundle"
                            >
                              {locale.subscription_bundle}
                            </label>
                            <select
                              className="form-control fs-20"
                              onChange={regionChangeHandler}
                              name="subscriptionBundle"
                              value={state.subscriptionBundle}
                              id="SubscriptionBundle"
                            >
                              <option value="">{locale.select_bundle}</option>
                              {getSubscriptions(locale).map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                            </select>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Input
                                name="email"
                                type="email"
                                label={locale.email}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                className="custom-select mt-2 fs-20"
                                name="channelSubscription"
                                options={CHANNELS}
                                component={CustomSelect}
                                placeholder="Select multi Channels"
                                isMulti={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Input
                                name="password"
                                type="password"
                                label={locale.password}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Input
                                name="confirmPassword"
                                type="password"
                                label={locale.confirm_password}
                              />
                            </div>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className={`form-check-input ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}
                              id="tcs"
                              checked={agree}
                              onChange={(e) => setAgree(e.target.checked)}
                            />
                            <label
                              className={`fs-42 width-full pointer form-check-label ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""}`}
                              htmlFor="tcs"
                              onClick={() => window.open("/assets/terms.pdf")}
                              style={{ marginLeft: "35px", marginTop: "-5px" }}
                            >
                              {locale.i_agree_terms_conditions}
                            </label>
                          </div>

                          <button
                            className="btn btn-success mt-3 fs-20"
                            type="submit"
                            disabled={!agree}
                          >
                            {props.loading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              locale.submit
                            )}
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
          <Col className="col-md-5 mt-5">
            <div className="registerImage mt-5">
              <img
                src={FormFrameImage}
                className="img-fluid"
                alt="alternatText"
              />
            </div>
          </Col>
        </Row>
      </section>
      <Modal show={state.showModel} onHide={handleModal} size="lg">
        <Modal.Header>
          <Modal.Title>{locale.location}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Map1
            selectedLocation={handleLocation}
            lat={state.lat}
            lng={state.lng}
            address={state.address}
          />
        </Modal.Body>
        <Modal.Footer style={{ zIndex: "123" }}>
          <Button
            className="mt-3"
            variant="secondary"
            onClick={cancelLocationHandler}
          >
            {locale.cancel}
          </Button>
          <Button
            className="mt-3"
            variant="success"
            onClick={handleLocationSubmition}
          >
            {locale.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Register;
