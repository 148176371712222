import React from "react";

const Select = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="col-form-label font-weight-bold">
        {props.label}
      </label>

      <select {...props} className={`form-control`}>
        <option value="">Search {props.label}</option>
        {props.data.map((e, i) => (
          <option key={i} value={e[props.searchKey]}>
            {typeof props.searchValue !== "undefined"
              ? e[props.searchValue]
              : e.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
