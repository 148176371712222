import React, { useEffect, useState } from "react";
import { firestore } from "../../../Firebase/config";
import { View } from "./view";
import "./dashboard.css";
import { toast } from "react-toastify";
import { deleteAdminMessageInPortal } from "../../../Firebase/apis";

export const Dashboard = () => {
  let user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  user = { ...user, message: null };
  const [data, setData] = useState({
    matches: 0,
    reviews: 0,
    bookings: 0,
  });
  const getMatches = () => {
    const matchesRef = firestore.collection("matches");
    matchesRef.onSnapshot((snapshot) => {
      const filterRes = snapshot.docs.filter(
        (item) =>
          item.data().viewedPortals &&
          !item.data().viewedPortals.includes(user.userId)
      );
      setData((p) => ({
        ...p,
        matches: filterRes.length,
      }));
    });
    const reviewsRef = firestore
      .collection("ratingReviewCollection")
      .where("giveToID", "==", user.userId)
      .where("isViewed", "==", false);
    reviewsRef.onSnapshot((snap) => {
      setData((p) => ({
        ...p,
        reviews: snap.docs.length,
      }));
    });
    const bookingRef = firestore
      .collection("bookingCollection")
      .where("venueID", "==", user.userId)
      .where("isViewed", "==", false);
    bookingRef.onSnapshot((snap) => {
      setData((p) => ({
        ...p,
        bookings: snap.docs.length,
      }));
    });
  };

  useEffect(() => {
    getMatches();
    if (user.message) {
      toast.success(user.message);
      deleteAdminMessageInPortal(user.userId).then((res) => {
        sessionStorage.setItem(
          "fanspot-portal",
          JSON.stringify({ ...user, message: null })
        );
      });
    }
  }, []);

  return <View data={data} />;
};
