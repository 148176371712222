import React from "react";
import { addRestaurant } from "../../../Firebase/apis";
import Spinner from "../../../components/common/Spinner.jsx";
import { RestaurantForm } from "../../../components/RestaurantForm";
import { useState } from "react";
import { toast } from "react-toastify";

export const ClientOfferForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await addRestaurant({
        ...values,
        offerReq: true,
      });
      toast.success("Added successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  if (loading) return <Spinner />;
  return <RestaurantForm submitHandler={handleSubmit} />;
};
