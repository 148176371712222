import React, { useState } from "react";
import { Tab, Tabs, Table, Form } from "react-bootstrap";
import { MdAssignment } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  updatedVenuePortalFeatures,
  updatedVenuePortalImages,
  updatedVenuePortalInfo,
} from "../../../Firebase/apis";
import {
  TIMINGS,
  userStatus,
  userStatusforStandard,
  WORKING_HOURS,
} from "../../../utils/data";
import "./Profile.css";
import useStore from "../../../store/store";

export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { locale, activeLanguage } = useStore((st) => st);
  const [state, setState] = useState({
    applyAll: false,
    sTime: "",
    eTime: "",
  });
  const [dataForm, setDataForm] = useState({
    venueName: "",
    googleMapLink: "",
    venuePhoneNumber: "",
    website: "",
    menue: null,
    password: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    snapchatLink: "",
    numberOfScreens: 1,
    wifi: false,
    commentry: false,
    projector: false,
    servesFood: false,
    outdoorSeating: false,
    tableBooking: false,
    shisha: false,
    privateRoom: false,
    servesCofee: false,
    seatBooking: false,
    workingHours: WORKING_HOURS,
    logo: null,
    userId: "",
    pictures: [],
  });

  const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  useEffect(() => {
    setDataForm((prev) => ({
      venueName: user.venueName ? user.venueName : "",
      googleMapLink: user.googleMapLink ? user.googleMapLink : "",
      venuePhoneNumber: user.venuePhoneNumber ? user.venuePhoneNumber : "",
      website: user.website ? user.website : "",
      menue: user.menue ? user.menue : null,
      password: user.password ? user.password : "",
      facebookLink: user.facebookLink ? user.facebookLink : "",
      instagramLink: user.instagramLink ? user.instagramLink : "",
      twitterLink: user.twitterLink ? user.twitterLink : "",
      snapchatLink: user.snapchatLink ? user.snapchatLink : "",
      numberOfScreens: user.numberOfScreens ? user.numberOfScreens : 1,
      wifi: user.wifi ? user.wifi : false,
      commentry: user.commentry ? user.commentry : false,
      projector: user.projector ? user.projector : false,
      servesFood: user.servesFood ? user.servesFood : false,
      outdoorSeating: user.outdoorSeating ? user.outdoorSeating : false,
      tableBooking: user.tableBooking ? user.tableBooking : false,
      shisha: user.shisha ? user.shisha : false,
      privateRoom: user.privateRoom ? user.privateRoom : false,
      servesCofee: user.servesCofee ? user.servesCofee : false,
      seatBooking: user.seatBooking ? user.seatBooking : false,
      workingHours: user.workingHours ? user.workingHours : prev.workingHours,
      logo: user.logo ? user.logo : null,
      userId: user.userId ? user.userId : "",
      pictures: user.pictures ? user.pictures : [],
    }));
  }, []);

  const changeHandler = (e) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    setDataForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const changeHandlerForOptional = (e) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    setDataForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const fileHandler = (e) => {
    if (!e.target.files[0]) return;
    setDataForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    toast.success("Selected successfully!");
  };
  const ApplytimeHandler = (day, e) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    const whCopy = [...dataForm.workingHours];
    const index = whCopy.findIndex((item) => item.day.eng === day);
    whCopy[index][e.target.name] = e.target.value;
    setDataForm((prev) => ({
      ...prev,
      workingHours: whCopy,
    }));
  };
  const statusHandler = (e, day) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    const whCopy = [...dataForm.workingHours];
    const index = whCopy.findIndex((item) => item.day.eng === day);
    whCopy[index]["status"] = e.target.checked;
    setDataForm((prev) => ({
      ...prev,
      workingHours: whCopy,
    }));
  };
  const featureStatusHandler = (e) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    setDataForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };
  const venuePhotoHandler = (e) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    if (!e.target.files[0]) return;
    if (!userStatusforStandard && dataForm.pictures.length === 2)
      return toast.error(locale.maximum_of_only_two_pic);
    const imgObj = {
      id: Math.random(),
      image: e.target.files[0],
    };
    setDataForm((prev) => ({
      ...prev,
      pictures: [...prev.pictures, imgObj],
    }));
  };

  const showErrors = (errors) => {
    for (let i = 0; i < errors.length; i++) {
      const item = errors[i];
      toast.error(item);
    }
  };

  const checkValidationForBasicInfo = () => {
    const errors = [];
    if (dataForm.venueName === "" && userStatus) {
      errors.push("Venue Name is required");
    }
    showErrors(errors);
    return errors.length > 0 ? false : true;
  };

  const checkValidationFoImages = () => {
    const errors = [];
    if (!dataForm.logo) {
      errors.push("Logo is required");
    }

    showErrors(errors);
    return errors.length > 0 ? false : true;
  };

  const submitHandlerForInfo = async () => {
    const res = checkValidationForBasicInfo();
    if (res) {
      try {
        setLoading(true);
        await updatedVenuePortalInfo({ ...dataForm, isCompleted: true });
        toast.success("Updated successfully");
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  const submitHandlerForFeatures = async () => {
    try {
      setLoading(true);
      await updatedVenuePortalFeatures({ ...dataForm, isCompleted: true });
      toast.success("Updated successfully");
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const submitHandlerForImages = async () => {
    const res = checkValidationFoImages();
    if (res) {
      try {
        setLoading(true);
        await updatedVenuePortalImages({ ...dataForm, isCompleted: true });
        toast.success("Updated successfully");
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const removeLogoHandler = () => {
    setDataForm((p) => ({
      ...p,
      logo: null,
    }));
  };
  const removeImageHandler = (id) => {
    let picsCopy = [...dataForm.pictures];

    picsCopy = picsCopy.filter((it) => it.id !== id);
    setDataForm((p) => ({
      ...p,
      pictures: picsCopy,
    }));
  };

  const checkLiveUrl = (item) => {
    return item.image && item.image.size ? true : false;
  };

  const menuClickHanlder = () => {
    if (!userStatus) {
      alert(locale.you_need_to_upgrade_memebership);
    }
  };

  const timeApplyHandler = (e) => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    if (state.sTime.length > 0 && state.eTime.length > 0 && e.target.checked) {
      let hCopy = [...dataForm.workingHours];
      hCopy = hCopy.map((item) => ({
        ...item,
        startTime: state.sTime,
        endTime: state.eTime,
        status: true,
      }));
      setDataForm((p) => ({
        ...p,
        workingHours: hCopy,
      }));
    }

    setState((p) => ({
      ...p,
      applyAll: e.target.checked,
    }));
  };

  return (
    <div className="container portal-profile-page">
      <div className="breadCrumb">
        <h4 className="fs-30">{locale.venue_portal_profile}</h4>
      </div>

      <div className="profile_main">
        <div className="navLinks">
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="home"
              title={locale.information}
              className="fs-20 info-tab"
            >
              <br />
              <h6 className="fs-17">
                {locale.fill_below_fields_to_upgrade_venue}
              </h6>

              <div className="formInputs">
                <div className="formInput">
                  <p className="fs-15">{locale.venue_name}</p>
                  <input
                    type="text"
                    className="profile-input fs-15"
                    placeholder={locale.venue_name}
                    name="venueName"
                    value={dataForm.venueName}
                  />
                </div>

                <div className="formInput">
                  <p className="fs-15">{locale.venue_phone_number}</p>
                  <input
                    type="text"
                    className="profile-input fs-15"
                    placeholder={locale.venue_phone_number_profile}
                    name="venuePhoneNumber"
                    value={dataForm.venuePhoneNumber}
                    onChange={changeHandlerForOptional}
                  />
                </div>

                <div className="formInput">
                  <p className="fs-15">{locale.website_optional}</p>
                  <input
                    type="text"
                    className="profile-input fs-15"
                    placeholder={locale.website}
                    name="website"
                    value={dataForm.website}
                    onChange={changeHandlerForOptional}
                  />
                </div>

                <div className="formInput formFileInput">
                  <p className="fs-15">{locale.menue}</p>
                  <button className="btnFile fs-15" onClick={menuClickHanlder}>
                    <span className="fileIcon">
                      <MdAssignment />
                    </span>
                    {locale.upload_menue}
                  </button>
                  <input
                    onChange={fileHandler}
                    disabled={!userStatus}
                    name="menue"
                    type="file"
                    className="fileInput mt-5"
                    placeholder="Location"
                  />
                </div>
              </div>
              <br />
              <br />
              <h5 className="fs-17 f-bold">{locale.set_working_hours}</h5>

              <td>
                <Form.Select
                  aria-label="Default select example"
                  value={state.sTime}
                  name="startTime"
                  onChange={(e) => {
                    if (!userStatus)
                      return alert(locale.you_need_to_upgrade_memebership);
                    setState((p) => ({ ...p, sTime: e.target.value }));
                  }}
                  className="fs-15"
                >
                  {TIMINGS.map((time) => (
                    <option className="fs-15" value={time} key={time}>
                      {time}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <span
                  className="fs-15"
                  style={{ fontSize: "1.2rem", margin: "0 20px" }}
                >
                  {locale.to}
                </span>
              </td>
              <td>
                <Form.Select
                  aria-label="Default select example"
                  value={state.eTime}
                  name="eTime"
                  className="fs-15"
                  onChange={(e) => {
                    if (!userStatus)
                      return alert(locale.you_need_to_upgrade_memebership);
                    setState((p) => ({ ...p, eTime: e.target.value }));
                  }}
                >
                  {TIMINGS.map((time) => (
                    <option className="fs-15" value={time} key={time}>
                      {time}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <div
                style={{
                  maxWidth: "250px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "2rem 0",
                }}
              >
                <label
                  style={{ fontSize: "1.2rem" }}
                  className="form-check-label ml-2 fs-15 mt-3"
                  htmlFor="applyall"
                >
                  {locale.apply_to_all}
                </label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={state.applyAll}
                  onChange={timeApplyHandler}
                  className="applytoallswitch"
                />
              </div>
              <Table responsive className="portal-profile-page-table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {dataForm.workingHours.map((item, i) => (
                    <tr key={i}>
                      <td
                        className="fs-15"
                        style={{ textTransform: "capitalize" }}
                      >
                        <span className="day">
                          {activeLanguage && !activeLanguage.includes("English")
                            ? item.day.ar
                            : item.day.eng}
                        </span>
                      </td>

                      <td>
                        <span className="mt-3">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={item.status}
                            onChange={(e) => statusHandler(e, item.day.eng)}
                          />
                        </span>
                      </td>

                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={item.startTime}
                          name="startTime"
                          className="fs-15"
                          onChange={(e) => ApplytimeHandler(item.day.eng, e)}
                        >
                          {TIMINGS.map((time, index) => (
                            <option
                              className="fs-15"
                              value={time}
                              key={i + time}
                            >
                              {time}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                      <td>
                        <span
                          className="fs-15 from-to"
                          style={{ fontSize: "1.2rem" }}
                        >
                          {locale.to}
                        </span>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={item.endTime}
                          name="endTime"
                          className="fs-15"
                          onChange={(e) => ApplytimeHandler(item.day.eng, e)}
                        >
                          {TIMINGS.map((time, index) => (
                            <option
                              className="fs-15"
                              value={time}
                              key={i + time}
                            >
                              {time}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="row mt-5">
                <h5 className="fs-17 f-bold">
                  {locale.add_social_media_links}
                </h5>

                <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                  <div className="formInput">
                    <p className="fs-15">{locale.facebook}</p>
                    <input
                      type="text"
                      className="profile-input fs-15"
                      placeholder="Faebook"
                      name="facebookLink"
                      value={dataForm.facebookLink}
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                  <div className="formInput">
                    <p className="fs-15">{locale.instagram}</p>
                    <input
                      type="text"
                      className="profile-input fs-15"
                      placeholder="Instagram"
                      name="instagramLink"
                      value={dataForm.instagramLink}
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                  <div className="formInput">
                    <p className="fs-15">{locale.twitter}</p>
                    <input
                      type="text"
                      className="profile-input fs-15"
                      placeholder="Twitter"
                      name="twitterLink"
                      value={dataForm.twitterLink}
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                  <div className="formInput">
                    <p className="fs-15">{locale.snapchat}</p>
                    <input
                      type="text"
                      className="profile-input fs-15"
                      placeholder="Snapchat"
                      name="snapchatLink"
                      value={dataForm.snapchatLink}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </div>
              <button
                className="btn btn-block btn-success px-5 mt-5 fs-17"
                onClick={submitHandlerForInfo}
              >
                {loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  locale.update
                )}
              </button>
            </Tab>

            <Tab eventKey="profile" title={locale.features}>
              <br />
              <h6 className="fs-17">{locale.select_available_features}</h6>

              <div className="row profileMain">
                <div className="col-sm-12 col-md-12 col-lg-6 p-2">
                  <h6 className="mb-3 mt-2 fs-15 secondryHeading">
                    <div>{locale.number_of_screens}</div>
                    <span className="noOfScreenBadge">
                      <Form.Select
                        aria-label="Default select example"
                        name="numberOfScreens"
                        onChange={changeHandler}
                      >
                        <option className="fs-15" value="1">
                          1
                        </option>
                        <option className="fs-15" value="2-5">
                          2-5
                        </option>
                        <option className="fs-15" value="5-10">
                          5-10
                        </option>
                        <option className="fs-15" value="+10">
                          +10
                        </option>
                      </Form.Select>
                    </span>
                  </h6>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.Wifi}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={dataForm.wifi}
                      name="wifi"
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.projector}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="projector"
                      checked={dataForm.projector}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.serves_food}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="servesFood"
                      checked={dataForm.servesFood}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.outdoor_seating}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="outdoorSeating"
                      checked={dataForm.outdoorSeating}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.table_booking}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="tableBooking"
                      checked={dataForm.tableBooking}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.shisha}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="shisha"
                      checked={dataForm.shisha}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.private_room}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="privateRoom"
                      checked={dataForm.privateRoom}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.serves_coffee}</p>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="servesCofee"
                      checked={dataForm.servesCofee}
                      onChange={featureStatusHandler}
                    />
                  </div>

                  <div className="profileMain_portal">
                    <p className="fs-15 mt-3">{locale.commentry}</p>

                    <Form.Check
                      type="switch"
                      name="commentry"
                      id="custom-switch"
                      checked={dataForm.commentry}
                      onChange={featureStatusHandler}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 mt-5 p-5">
                  <div className="wifiPassword">
                    <div className="password_text">
                      <h6 className="fs-15">{locale.wifi_passowrd}</h6>
                      <p className="fs-15">
                        ({locale.password_will_only} <br />{" "}
                        {locale.show_up_to_checkedin_users})
                      </p>
                    </div>

                    <div className="password_input">
                      <input
                        className="fs-15"
                        type="text"
                        name="password"
                        value={dataForm.password}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                </div>

                <div className="Custom_container">
                  <div className="row">
                    <div className="col">
                      <div className="feature-previeW-container">
                        <span className="numberofscreen-icon">
                          {dataForm.numberOfScreens}
                        </span>
                      </div>
                      <p>{locale.screen}</p>
                    </div>
                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.wifi && <div className="cross-line"></div>}
                        <span>
                          <img src="/assets/icons/wifi.svg" alt="altText" />
                        </span>
                      </div>
                      <p className={!dataForm.wifi && "disabled-text"}>
                        {locale.Wifi}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.projector && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img
                            src="/assets/icons/projector.svg"
                            alt="altText"
                          />
                        </span>
                      </div>
                      <p className={!dataForm.projector && "disabled-text"}>
                        {locale.projector}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.servesFood && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img src="/assets/icons/Food.svg" alt="altText" />
                        </span>
                      </div>
                      <p className={!dataForm.servesFood && "disabled-text"}>
                        {locale.food}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.outdoorSeating && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img src="/assets/icons/outdoor.svg" alt="altText" />
                        </span>
                      </div>
                      <p
                        className={!dataForm.outdoorSeating && "disabled-text"}
                      >
                        {locale.outdoor}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.tableBooking && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img
                            src="/assets/icons/table Booking.svg"
                            alt="altText"
                          />
                        </span>
                      </div>
                      <p className={!dataForm.tableBooking && "disabled-text"}>
                        {locale.book_table}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.shisha && <div className="cross-line"></div>}
                        <span>
                          <img src="/assets/icons/shisha.svg" alt="altText" />
                        </span>
                      </div>
                      <p className={!dataForm.shisha && "disabled-text"}>
                        {locale.shisha}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.privateRoom && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img
                            src="/assets/icons/Private Room.svg"
                            alt="altText"
                          />
                        </span>
                      </div>
                      <p className={!dataForm.privateRoom && "disabled-text"}>
                        {locale.private_room}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.servesCofee && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img src="/assets/icons/coffee.svg" alt="altText" />
                        </span>
                      </div>
                      <p className={!dataForm.servesCofee && "disabled-text"}>
                        {locale.Coffee}
                      </p>
                    </div>

                    <div className="col">
                      <div className="feature-previeW-container">
                        {!dataForm.commentry && (
                          <div className="cross-line"></div>
                        )}
                        <span>
                          <img
                            src="/assets/icons/commentary.svg"
                            alt="altText"
                          />
                        </span>
                      </div>
                      <p className={!dataForm.commentry && "disabled-text"}>
                        {locale.commentry}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-block btn-success px-5 mt-5 fs-17"
                onClick={submitHandlerForFeatures}
              >
                {loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  locale.update
                )}
              </button>
            </Tab>

            <Tab eventKey="contact" title={locale.photos}>
              <h6 className="my-5 fs-17">
                {locale.add_photo_to_your_venue_page}
              </h6>
              <h4 className="fs-15">{locale.venue_logo}</h4>
              <div className="formInput formFileInput">
                {!dataForm.logo && (
                  <button className="btnFile fs-15">
                    <span className="fileIcon">
                      <MdAssignment />
                    </span>
                    {locale.upload_logo}
                  </button>
                )}
                <input
                  onChange={fileHandler}
                  type="file"
                  name="logo"
                  className="fileInput pointer"
                  style={{ height: "200px", marginTop: "-6rem" }}
                />
                {dataForm.logo && (
                  <div className="gallery-img">
                    <span onClick={() => removeLogoHandler()}>
                      <ImCross />
                    </span>
                    <img
                      width="100%"
                      src={
                        dataForm.logo && dataForm.logo.size
                          ? URL.createObjectURL(dataForm.logo)
                          : dataForm.logo
                      }
                      alt="logo"
                    />
                  </div>
                )}
              </div>

              <h4 className="mt-5 fs-15">{locale.add_photo}</h4>
              <div className="formInput formFileInput">
                <button className="btnFile fs-15">
                  <span className="fileIcon">
                    <MdAssignment />
                  </span>
                  {locale.upload_photo}
                </button>
                <input
                  onChange={venuePhotoHandler}
                  type="file"
                  className="fileInput"
                />
              </div>

              <h4 className="mt-5 fs-15">{locale.library}</h4>

              <div className="row">
                {dataForm.pictures.map((item, index) => (
                  <div className="col-sm-12 col-md-6 col-lg-3 mt-5 gallery-img">
                    <span onClick={() => removeImageHandler(item.id)}>
                      <ImCross />
                    </span>
                    <img
                      width="100%"
                      height="250px"
                      src={
                        checkLiveUrl(item)
                          ? URL.createObjectURL(item.image)
                          : item.image
                      }
                      alt={index}
                      key={index}
                    />
                  </div>
                ))}
              </div>
              <button
                className="btn btn-block btn-success px-5 mt-5 fs-17"
                onClick={submitHandlerForImages}
              >
                {loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  locale.update
                )}
              </button>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
