import React from "react";
const Input = ({ label, ...props }) => {
  return (
    <div className="mb-3">
      <label htmlFor={props.name}>{label}</label>
      <input className={`form-control`} type="text" {...props} />
    </div>
  );
};

export default Input;
