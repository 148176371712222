import React from "react";
import { addRestaurant, updateRestaurant } from "../../../Firebase/apis";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/Spinner.jsx";
import { RestaurantForm } from "../../../components/RestaurantForm";
import { useState } from "react";

export const AddRestaurant = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      if (location.state) {
        await updateRestaurant({
          ...values,
        });
      } else {
        await addRestaurant({
          ...values,
        });
      }
      setLoading(false);
      navigate("/admin/restaurants");
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  if (loading) return <Spinner />;
  return (
    <RestaurantForm restaurant={location.state} submitHandler={handleSubmit} />
  );
};
