import React from "react";
import {
  deleteMatch,
  getMatches,
  registerMatch,
  suggestionHandlerInDb,
  updateMatch,
} from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import { useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { useMemo } from "react";
import { MdAssignment } from "react-icons/md";
import { getCurrentDate } from "./../../../utils/data";
import readXlsxFile from "read-excel-file";
import { downloadExcel } from "../../../utils/data";
import {
  HiOutlineArrowCircleLeft,
  HiOutlineArrowCircleRight,
} from "react-icons/hi";
export const Matches = () => {
  const { data, error } = useSWR("/admin/get/matches", getMatches);
  const [searchTerm, setSearchTerm] = useState("");
  const [activetab, setActivetab] = useState("Clubs");
  const [activeDate, setActiveDate] = useState(getCurrentDate());

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you sure, you want to delete?");
      if (confirm) {
        await deleteMatch(id);
        alert("Deleted successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const weeklyPredictionHandler = async (id, weeklyPrediction) => {
    try {
      await suggestionHandlerInDb(id, { weeklyPrediction });
      alert("updated successfully");
    } catch (error) {
      alert(error.message);
    }
  };
  const topMatchHandler = async (id, isTopMatch) => {
    try {
      await suggestionHandlerInDb(id, { isTopMatch });
      alert("updated successfully");
    } catch (error) {
      alert(error.message);
    }
  };
  const monthlyPredictionHandler = async (id, monthlyPrediction) => {
    try {
      await suggestionHandlerInDb(id, { monthlyPrediction });
      alert("updated successfully");
    } catch (error) {
      alert(error.message);
    }
  };

  const getTime = (date) => {
    const d = new Date(date);
    const tArr = d.toLocaleTimeString().split(":");
    const k = tArr[tArr.length - 1].split(" ");
    return tArr[0] + " : " + tArr[1] + " " + k[1];
  };

  const getMatchesForActiveTab = (matches) => {
    const selectedDate = new Date(activeDate);
    const filtermatches = [];
    matches.forEach((match) => {
      const matchDate = new Date(match.startTime);
      if (
        matchDate.toLocaleDateString() === selectedDate.toLocaleDateString()
      ) {
        filtermatches.push(match);
      }
    });
    return filtermatches;
  };

  const importMatchesHandler = (e) => {
    let fileObj = e.target.files[0];
    readXlsxFile(fileObj)
      .then(async (matches) => {
        for (let i = 0; i < matches.length; i++) {
          if (i > 0) {
            const item = matches[i];
            const match = {
              firstTeamId: item[0],
              secondTeamId: item[1],
              startTime: item[2],
              round: item[3],
              clubId: item[4],
              isNationalMatch: item[5],
              isTopMatch: item[6],
              teams: [item[0], item[1]],
              countryID: "",
              leagueID: item[4],
              matchDate: getDate(item[2]),
              viewedPortals: [],
            };
            await registerMatch(match);
          }
        }
        toast.success("successfully imported all the matches.");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const importMatchesUpdateHandler = (e) => {
    let fileObj = e.target.files[0];
    readXlsxFile(fileObj)
      .then(async (matches) => {
        for (let i = 0; i < matches.length; i++) {
          if (i > 0) {
            const item = matches[i];
            const match = {
              id: item[0],
              matchDate: getDate(item[3]),
              startTime: item[4],
            };
            await updateMatch(match);
          }
        }
        toast.success("successfully imported all the matches.");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getDate = (time) => {
    const d = new Date(time);
    return d.toLocaleDateString();
  };

  const increamentHanlder = () => {
    const d = new Date(activeDate);
    d.setDate(d.getDate() + 1);
    setActiveDate(getCurrentDate(d));
  };
  const decreamentHanlder = () => {
    const d = new Date(activeDate);
    d.setDate(d.getDate() - 1);
    setActiveDate(getCurrentDate(d));
  };
  const exportMatchesHandler = (matches) => {
    const header = ["id", "firstTeam", "secondTeam", "matchDate", "startTime"];
    console.log(matches);

    if (activetab.includes("Clubs")) {
      let filterMatches = [];
      matches.clubsMatches.forEach((club) => {
        filterMatches = [...filterMatches, ...club.matches];
      });
      const tData = filterMatches.map((item) => ({
        id: item.docId,
        firstTeam: item.firstTeam.name,
        secondTeam: item.secondTeam.name,
        matchDate: item.matchDate,
        startTime: item.startTime,
      }));
      downloadExcel(tData, "matches");
    } else {
      let filterMatches = [];
      matches.countryMatches.forEach((club) => {
        filterMatches = [...filterMatches, ...club.matches];
      });
      const tData = filterMatches.map((item) => ({
        id: item.docId,
        firstTeam: item.firstTeam.name,
        secondTeam: item.secondTeam.name,
        matchDate: item.matchDate,
        startTime: item.startTime,
      }));
      downloadExcel(tData, "matches");
    }
  };

  const transformData = useMemo(() => {
    const clubsMatches = [];
    const countryMatches = [];
    if (data) {
      const filterMatches = data.filter(
        (it) =>
          (it.league &&
            it.league.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (it.firstTeam &&
            it.firstTeam.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (it.secondTeam &&
            it.secondTeam.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (it.round &&
            it.round.toLowerCase().includes(searchTerm.toLowerCase()))
      );

      filterMatches.forEach((match) => {
        if (match.isNationalMatch) {
          const clubIndex = countryMatches.findIndex(
            (c) => c.clubId === match.league.docId
          );

          if (clubIndex < 0) {
            const obj = {
              clubId: match.league.docId,
              name: match.league.name,
              image: match.league.image,
              matches: [match],
            };
            countryMatches.push(obj);
          } else {
            countryMatches[clubIndex].matches.push(match);
          }
        } else {
          const clubIndex = clubsMatches.findIndex((c) =>
            c.clubId === match.league ? match.league.docId : ""
          );

          if (clubIndex < 0) {
            const obj = {
              clubId: match.league ? match.league.docId : "",
              name: match.league ? match.league.name : "",
              image: match.league ? match.league.image : "",
              matches: [match],
            };
            clubsMatches.push(obj);
          } else {
            clubsMatches[clubIndex].matches.push(match);
          }
        }
      });
    }
    return { clubsMatches, countryMatches };
  }, [data, searchTerm]);

  if (error) toast.error(error.message);
  if (!data) return <Spinner />;
  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Matches</h1>
          <Link
            to="/admin/matches/add"
            className="btn btn-outline-secondary d-flex justify-content-center align-items-center mb-3"
          >
            <span style={{ marginRight: "10px" }}>
              <GrAdd />
            </span>
            Add
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="formInput formFileInput">
            <button className="btnFile btn-excell">
              <span className="fileIcon">
                <MdAssignment />
              </span>
              Import Matches from excell
            </button>
            <input
              onChange={importMatchesHandler}
              type="file"
              className="fileInput"
            />
          </div>
          <button
            className="btnFile btn-excell"
            style={{ width: "149px" }}
            onClick={() => exportMatchesHandler(transformData)}
          >
            Export Matches
          </button>
        </div>

        <div className="formInput formFileInput" style={{ marginTop: "40px" }}>
          <button className="btnFile btn-excell">
            <span className="fileIcon">
              <MdAssignment />
            </span>
            Update Matches by Import
          </button>
          <input
            onChange={importMatchesUpdateHandler}
            type="file"
            className="fileInput"
          />
        </div>

        <div className="my-3">
          <label htmlFor="SearchId">ٖFilter by League, team and Round</label>
          <input
            className={`form-control`}
            type="text"
            id="SearchId"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="matches-datepicker">
          <div>
            <span className="left-arrow pointer" onClick={decreamentHanlder}>
              <HiOutlineArrowCircleLeft />
            </span>
          </div>
          <div>
            <input
              className="form-control"
              type="date"
              id="matchDate"
              value={activeDate}
              onChange={(e) => setActiveDate(e.target.value)}
            />
          </div>
          <div>
            <span className="right-arrow pointer" onClick={increamentHanlder}>
              <HiOutlineArrowCircleRight />
            </span>
          </div>
        </div>
        <Tabs
          defaultActiveKey="Clubs"
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
          onSelect={(k) => setActivetab(k)}
        >
          <Tab eventKey="Clubs" title="Clubs">
            {transformData.clubsMatches.map((club) => (
              <div className="fixtureBottom" key={club.clubId}>
                {getMatchesForActiveTab(club.matches).length > 0 && (
                  <div className="fixtureBottom_league d-flex align-items-center">
                    <img
                      src={club.image}
                      alt="clubImage"
                      width={50}
                      height={50}
                    />
                    <h4 className="m-lg-4">{club.name}</h4>
                  </div>
                )}
                <div className="leagueMatches">
                  {getMatchesForActiveTab(club.matches).map((match) => (
                    <div className="leagueMatch" key={match.docId}>
                      <p>{match.firstTeam.name}</p>
                      <img
                        src={
                          match.firstTeam.image
                            ? match.firstTeam.image
                            : `/assets/Flags/${match.firstTeam.flagName}.png`
                        }
                        alt={match.firstTeam.name}
                      />
                      <h5>{getTime(match.startTime)}</h5>
                      <img
                        src={
                          match.secondTeam.image
                            ? match.secondTeam.image
                            : `/assets/Flags/${match.secondTeam.flagName}.png`
                        }
                        alt={match.secondTeam.name}
                      />
                      <p>{match.secondTeam.name}</p>
                      <p>{match.round}</p>
                      <div className="d-flex align-items-center ">
                        <label>Weekly Prediction?</label>
                        <span className="m-lg-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={
                              match.weeklyPrediction
                                ? match.weeklyPrediction
                                : false
                            }
                            onChange={(e) =>
                              weeklyPredictionHandler(
                                match.docId,
                                e.target.checked
                              )
                            }
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-center ">
                        <label>Top Match</label>
                        <span className="m-lg-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={
                              match.isTopMatch ? match.isTopMatch : false
                            }
                            onChange={(e) =>
                              topMatchHandler(match.docId, e.target.checked)
                            }
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-center ">
                        <label>Monthly Prediction?</label>
                        <span className="m-lg-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={
                              match.monthlyPrediction
                                ? match.monthlyPrediction
                                : false
                            }
                            onChange={(e) =>
                              monthlyPredictionHandler(
                                match.docId,
                                e.target.checked
                              )
                            }
                          />
                        </span>
                      </div>
                      <Link
                        to="/admin/matches/add"
                        state={match}
                        className="btn btn-secondary"
                      >
                        Edit
                      </Link>
                      <span
                        onClick={() => deleteHandler(match.docId)}
                        className="btn btn-danger"
                        style={{ marginLeft: "6px" }}
                      >
                        Delete
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Tab>

          <Tab eventKey="Nationals" title="Nationals">
            {transformData.countryMatches.map((club) => (
              <div className="fixtureBottom" key={club.clubId}>
                {getMatchesForActiveTab(club.matches).length > 0 && (
                  <div className="fixtureBottom_league d-flex align-items-center">
                    <img
                      src={club.image}
                      alt="clubImage"
                      width={50}
                      height={50}
                    />
                    <h4 className="m-lg-4">{club.name}</h4>
                  </div>
                )}
                <div className="leagueMatches">
                  {getMatchesForActiveTab(club.matches).map((match) => (
                    <div className="leagueMatch" key={match.docId}>
                      <p>{match.firstTeam.name}</p>
                      <img
                        src={
                          match.firstTeam.image
                            ? match.firstTeam.image
                            : `/assets/Flags/${match.firstTeam.flagName}.png`
                        }
                        alt={match.firstTeam.name}
                      />
                      <h5>{getTime(match.startTime)}</h5>
                      <img
                        src={
                          match.secondTeam.image
                            ? match.secondTeam.image
                            : `/assets/Flags/${match.secondTeam.flagName}.png`
                        }
                        alt={match.secondTeam.name}
                      />
                      <p>{match.secondTeam.name}</p>
                      <p>{match.round}</p>
                      <div className="d-flex align-items-center ">
                        <label>Top Match</label>
                        <span className="m-lg-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={
                              match.isTopMatch ? match.isTopMatch : false
                            }
                            onChange={(e) =>
                              topMatchHandler(match.docId, e.target.checked)
                            }
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-center ">
                        <label>Weekly Prediction?</label>
                        <span className="m-lg-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={
                              match.weeklyPrediction
                                ? match.weeklyPrediction
                                : false
                            }
                            onChange={(e) =>
                              weeklyPredictionHandler(
                                match.docId,
                                e.target.checked
                              )
                            }
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-center ">
                        <label>Monthly Prediction?</label>
                        <span className="m-lg-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={
                              match.monthlyPrediction
                                ? match.monthlyPrediction
                                : false
                            }
                            onChange={(e) =>
                              monthlyPredictionHandler(
                                match.docId,
                                e.target.checked
                              )
                            }
                          />
                        </span>
                      </div>
                      <Link
                        to="/admin/matches/add"
                        state={match}
                        className="btn btn-secondary"
                      >
                        Edit
                      </Link>
                      <span
                        onClick={() => deleteHandler(match.docId)}
                        className="btn btn-danger"
                        style={{ marginLeft: "6px" }}
                      >
                        Delete
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
