import React from "react";
import { useField, ErrorMessage } from "formik";

const TextArea = (props) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-3">
      <label className="fs-15" htmlFor={field.name}>{props.label}</label>
      <textarea
        className={`form-control fs-15 ${meta.error && "is-invalid"}`}
        {...field}
        {...props}
        rows={3}
      ></textarea>

      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};

export default TextArea;
