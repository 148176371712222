import React from "react";
import useStore from "../../store/store";
import { FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";

const Index = () => {
  const { locale, activeLanguage } = useStore((state) => state);
  const clickHandler = (url) => {
    const el = document.createElement("a");
    el.href = url;
    el.setAttribute("target", "_blank");
    el.click();
  };
  return (
    <footer className="footer">
      <div className="container-fluid main-app-wrapper">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-5">
            <div className="footer-left">
              <img
                src="/assets/LandingImages/logoLG.png"
                className="img-fluid footer-logo"
                alt="alternatText"
              />
              <p className={`${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRightWebsite" : ""}`}>{locale.footer_first_tab_primary_text}</p>

              <h6>{locale.footer_first_tab_secondary_text}</h6>

              <div className="stores-btns">
                <div className="store_btn pointer"
                 onClick={() =>
                  clickHandler("https://play.google.com/store/games?hl=en&gl=US&pli=1")
                }
                >
                  <img
                    src="/assets/LandingImages/playstore.png"
                    className="img-fluid"
                    height="30px"
                    alt="alternatText"
                  />
                  <p>{locale.playstore}</p>
                </div>
                <div className="store_btn pointer"
                  onClick={() =>
                    clickHandler("https://apps.apple.com/pk/app/apple-store/id375380948")
                  }>
                  <img
                    src="/assets/LandingImages/apple.png"
                    className="img-fluid"
                    height="30px"
                    alt="alternatText"
                  />
                  <p>{locale.appstore}</p>
                </div>
              </div>
              <div className="social-icons">
                <FaInstagram
                  className="icon pointer"
                  onClick={() =>
                    clickHandler("https://www.instagram.com/fanspotapp/")
                  }
                />
                <FaWhatsapp className="icon pointer"
                  onClick={() =>
                    clickHandler("https://web.whatsapp.com/")
                  } />
                <FaTwitter
                  className="icon pointer"
                  onClick={() =>
                    clickHandler("https://twitter.com/?lang=en")
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-7">
            <div className="footer-right">
              <h3>{locale.footer_second_tab_primary_text}</h3>
              <img
                src="/assets/LandingImages/footerImg.png"
                className="img-fluid"
                alt="alternatText"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Index;
