import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FaQuestionCircle, FaUsers, FaVenusMars } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { TbClubs } from "react-icons/tb";
import { BiRestaurant } from "react-icons/bi";
import { Dashboard } from "../pages/Admin/Dashboard";
import Sidebar from "../components/AdminSidebar";
import { Login } from "../pages/Admin/Login";
import {
  MdContactSupport,
  MdDashboard,
  MdOnlinePrediction,
  MdOutlineCardMembership,
  MdOutlineFamilyRestroom,
  MdOutlineMailOutline,
} from "react-icons/md";
import { VenuePortals } from "../pages/Admin/VenuePortals";
import { Countries } from "../pages/Admin/Countries";
import { CountryForm } from "../pages/Admin/Countries/CountryForm";
import { Clubs } from "../pages/Admin/Clubs";
import { ClubForm } from "../pages/Admin/Clubs/ClubForm";
import { TeamForm } from "../pages/Admin/Teams/TeamForm";
import { Teams } from "../pages/Admin/Teams";
import { MatchForm } from "../pages/Admin/Matches/MatchForm";
import { Matches } from "../pages/Admin/Matches";
import { RiTeamLine } from "react-icons/ri";
import { Restaurants } from "../pages/Admin/Restaurants";
import { AddRestaurant } from "../pages/Admin/Restaurants/RestaurantForm";
import { Users } from "../pages/Admin/Users";
import { VenuesSuggestions } from "../pages/Admin/VenueSuggestions";
import { PortalForm } from "../pages/Admin/VenuePortals/PortalForm";
import { UserForm } from "../pages/Admin/Users/userForm";
import { Support } from "../pages/Admin/support";
import Settings from "../pages/Admin/Settings";
import { Predictions } from "../pages/Admin/predictions";
import Ultras from "../pages/Admin/Ultras";
import { FAQS } from "../pages/Admin/FAQS";
import VenueStats from "../pages/Admin/VenuePortals/VenueStats";
import { MembershipRequests } from "../pages/Admin/SubscriptionRequests";
import useStore from "../store/store";
import { getAllMatches, getAllPortals, getLocale, getUsers } from "../Firebase/apis";
import Spinner from "../components/common/Spinner.jsx";

const SIDEBAR_LINKS = [
  {
    path: "/admin/dashboard",
    title: "Dashboard",
    Icon: <MdDashboard />,
  },
  {
    path: "/admin/venueportals",
    title: "Venue Portals",
    Icon: <MdOutlineFamilyRestroom />,
  },
  {
    path: "/admin/venusuggestions",
    title: "Venue Suggestions",
    Icon: <FaVenusMars />,
  },
  {
    path: "/admin/ultras",
    title: "Ultras",
    Icon: <FaUsers />,
  },
  {
    path: "/admin/users",
    title: "Users",
    Icon: <FaUsers />,
  },
  {
    path: "/admin/leagues",
    title: "Leagues",
    Icon: <TbClubs />,
  },
  {
    path: "/admin/teams",
    title: "Teams",
    Icon: <RiTeamLine />,
  },
  {
    path: "/admin/matches",
    title: "Matches",
    Icon: <MdOutlineMailOutline />,
  },
  {
    path: "/admin/restaurants",
    title: "Restaurants",
    Icon: <BiRestaurant />,
  },
  {
    path: "/admin/support",
    title: "Support",
    Icon: <MdContactSupport />,
  },
  {
    path: "/admin/predictions",
    title: "Predictions",
    Icon: <MdOnlinePrediction />,
  },
  {
    path: "/admin/faqs",
    title: "FAQS",
    Icon: <FaQuestionCircle />,
  },
  {
    path: "/admin/membershipreqs",
    title: "Membership Requests",
    Icon: <MdOutlineCardMembership />,
  },
  {
    path: "/admin/settings",
    title: "Settings",
    Icon: <IoSettingsSharp />,
  },
];

const appLocale = sessionStorage.getItem("fanspot-locale");
const AdminRoutes = () => {
  const admin = sessionStorage.getItem("fanspot-admin");
  const { setData, setAppLocale, setLocale, setLanguage } = useStore((state) => state);
  const [loading, setLoading] = React.useState(true);

  const logoutHandler = () => {
    const c = window.confirm("Are you sure to logout?");
    if (!c) return;
    sessionStorage.removeItem("fanspot-admin");
    window.location.replace("/admin");
  };

  const getData = async () => {
    let portals = await getAllPortals();
    portals = portals.filter((item) => !item.isDeleted);
    let users = await getUsers();
    users = users.filter((item) => !item.isDeleted);
    const matches = await getAllMatches();
    setData(portals, users, matches);
  };
  useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    getLocale()
      .then((data) => {
        setAppLocale(data.arrabicLocale, data.englishLocale);
        setLanguage("English");
        setLocale(
          appLocale && appLocale.includes("Arrabic")
            ? data.arrabicLocale
            : data.englishLocale
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Spinner />;
  }
  return !admin ? (
    <Routes>
      <Route path="/admin" element={<Login />} />
    </Routes>
  ) : (
    <Sidebar data={SIDEBAR_LINKS} logoutHandler={logoutHandler} isAdmin={true}>
      <Routes>
        <Route path="/admin" element={<Navigate to={"/admin/dashboard"} />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/countries" element={<Countries />} />
        <Route path="/admin/countries/add" element={<CountryForm />} />
        <Route path="/admin/teams" element={<Teams />} />
        <Route path="/admin/teams/add" element={<TeamForm />} />
        <Route path="/admin/matches" element={<Matches />} />
        <Route path="/admin/matches/add" element={<MatchForm />} />
        <Route path="/admin/venueportals/add" element={<PortalForm />} />
        <Route path="/admin/venueportals/stats" element={<VenueStats />} />
        <Route path="/admin/leagues" element={<Clubs />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/users/add" element={<UserForm />} />
        <Route path="/admin/venusuggestions" element={<VenuesSuggestions />} />
        <Route path="/admin/clubs/add" element={<ClubForm />} />
        <Route path="/admin/restaurants" element={<Restaurants />} />
        <Route path="/admin/restaurants/add" element={<AddRestaurant />} />
        <Route path="/admin/venueportals" element={<VenuePortals />} />
        <Route path="/admin/support" element={<Support />} />
        <Route path="/admin/faqs" element={<FAQS />} />
        <Route path="/admin/predictions" element={<Predictions />} />
        <Route path="/admin/membershipreqs" element={<MembershipRequests />} />
        <Route path="/admin/ultras" element={<Ultras />} />
        <Route path="/admin/settings" element={<Settings />} />
      </Routes>
    </Sidebar>
  );
};

export default AdminRoutes;
