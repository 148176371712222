import React from "react";
import "./Statistic.css";
import { Tab, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  MdStarRate,
  MdOutlineArrowDownward,
  MdOutlineArrowUpward,
} from "react-icons/md";
import useSWR from "swr";
import { getStatesData, getStatesForAdminData } from "../../Firebase/apis";
import Spinner from "../common/Spinner.jsx";
import { toast } from "react-toastify";
import { useState } from "react";
import { useMemo } from "react";
import { DateDifference } from "../../utils/date";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import useStore from "../../store/store";

export const StatisticsComponent = ({ portal, users, portals }) => {
  const { data, error } = useSWR("/get/stats", () =>
    portal ? getStatesData(portal) : getStatesForAdminData()
  );
  const { locale, activeLanguage } = useStore((st) => st);
  const [activeTab, setActiveTab] = useState("7");

  const tabHandler = (value) => {
    setActiveTab(value);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Overall Statistics Past Days
    </Tooltip>
  );

  const downloadVisistedVenues = (
    checkins,
    visitedVenues,
    mapClicks,
    ratingReviews,
    offersClaimed,
    searchVenues
  ) => {
    const wb = XLSX.utils.book_new();
    visitedVenues = activeTab.includes("-1")
      ? data.venuesVisited
      : visitedVenues.visiteditems;
    checkins = activeTab.includes("-1") ? data.chekins : checkins;
    let noCheckins =
      activeTab === "-1"
        ? data.venuesVisited.filter((item) => !item.isCheckIn)
        : visitedVenues.visitedCheckinsItems;
    noCheckins = noCheckins ? noCheckins : [];
    mapClicks = activeTab === "-1" ? data.venuesVisitedMap : mapClicks;
    ratingReviews = activeTab === "-1" ? data.rateReviews : ratingReviews;
    offersClaimed = activeTab === "-1" ? data.offerClaimed : offersClaimed;
    searchVenues = activeTab === "-1" ? data.offerClaimed : searchVenues;

    /* create a worksheet for visited venues */
    let transformData = [];
    for (let i = 0; i < visitedVenues.length; i++) {
      const item = visitedVenues[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.userId);
      transformData.push({
        ...item,
        user,
        venue,
      });
    }
    transformData = transformData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));
    const wsVisistedVenues = XLSX.utils.json_to_sheet(transformData);
    XLSX.utils.book_append_sheet(wb, wsVisistedVenues, "visitedVenues");

    /* create a worksheet for checkins venues */
    let transformCheckinsData = [];
    for (let i = 0; i < checkins.length; i++) {
      const item = checkins[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.venueID);
      transformCheckinsData.push({
        ...item,
        user,
        venue,
      });
    }

    transformCheckinsData = transformCheckinsData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));
    const wscheckinVenues = XLSX.utils.json_to_sheet(transformCheckinsData);
    XLSX.utils.book_append_sheet(wb, wscheckinVenues, "checkinVenues");

    /* create a worksheet for none checkins venues */
    let transformNoneCheckinsData = [];
    for (let i = 0; i < noCheckins.length; i++) {
      const item = noCheckins[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.userId);
      transformNoneCheckinsData.push({
        ...item,
        user,
        venue,
      });
    }

    transformNoneCheckinsData = transformNoneCheckinsData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));

    const wsnoneCheckinVenues = XLSX.utils.json_to_sheet(transformCheckinsData);
    XLSX.utils.book_append_sheet(wb, wsnoneCheckinVenues, "noneCheckinVenues");

    /* create a worksheet for none Map click venues */
    let transformMapClickData = [];
    for (let i = 0; i < mapClicks.length; i++) {
      const item = mapClicks[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.userId);
      transformMapClickData.push({
        ...item,
        user,
        venue,
      });
    }

    transformMapClickData = transformMapClickData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));

    const wsMapclickVenues = XLSX.utils.json_to_sheet(transformMapClickData);
    XLSX.utils.book_append_sheet(wb, wsMapclickVenues, "mapclickVenues");

    /* create a worksheet for none rating reviews venues */
    let transformRatingReviewsData = [];
    for (let i = 0; i < ratingReviews.length; i++) {
      const item = ratingReviews[i];
      const user = users.find((user) => user.userID === item.giveByID);
      const venue = portals.find((user) => user.userId === item.giveToID);
      transformRatingReviewsData.push({
        ...item,
        user,
        venue,
      });
    }

    transformRatingReviewsData = transformRatingReviewsData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));

    const wsRatingReviews = XLSX.utils.json_to_sheet(
      transformRatingReviewsData
    );
    XLSX.utils.book_append_sheet(wb, wsRatingReviews, "ratingReviews");

    /* create a worksheet for none offer claimed venues */
    let transformOfferClaimedsData = [];
    for (let i = 0; i < offersClaimed.length; i++) {
      const item = offersClaimed[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.venueID);
      transformOfferClaimedsData.push({
        ...item,
        user,
        venue,
      });
    }

    transformOfferClaimedsData = transformOfferClaimedsData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));

    const wsOfferClaimed = XLSX.utils.json_to_sheet(transformOfferClaimedsData);
    XLSX.utils.book_append_sheet(wb, wsOfferClaimed, "OfferClamied");

    // For booking history
    let bookingsData = [];
    for (let i = 0; i < data.bookings.length; i++) {
      const item = data.bookings[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.venueID);
      bookingsData.push({
        ...item,
        user,
        venue,
      });
    }
    bookingsData = bookingsData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));
    const wsBooking = XLSX.utils.json_to_sheet(bookingsData);
    XLSX.utils.book_append_sheet(wb, wsBooking, "TableBooking");

    /* create a worksheet for none offer claimed venues */
    let transformVenuesSearchData = [];
    for (let i = 0; i < searchVenues.length; i++) {
      const item = searchVenues[i];
      const user = users.find((user) => user.userID === item.userID);
      const venue = portals.find((user) => user.userId === item.venueID);
      transformVenuesSearchData.push({
        ...item,
        user,
        venue,
      });
    }

    transformVenuesSearchData = transformVenuesSearchData.map((item, i) => ({
      S_No: i + 1,
      venue: item.venue ? item.venue.venueName : "Venue Deleted",
      user: item.user ? item.user.name : "User Deleted",
    }));

    const wsSearchvenues = XLSX.utils.json_to_sheet(transformVenuesSearchData);
    XLSX.utils.book_append_sheet(wb, wsSearchvenues, "SearchVenues");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const statsData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(statsData, "stats.xlsx");
  };

  const renderData = (
    checkins,
    venuesVisited,
    venuesVisitedMap,
    ratingAndReviews,
    offerClaimed,
    venueSearch
  ) => (
    <div>
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span className="statsUp">
                <MdOutlineArrowUpward />
              </span>
            </OverlayTrigger>
            <h2>
              {activeTab === "-1"
                ? data.venuesVisited.length
                : venuesVisited.visiteditems.length}{" "}
            </h2>
            <p className="fs-17">{locale.viewed_venue_page}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <span className="statsUp">
              <MdOutlineArrowUpward />
            </span>
            <h2>
              {activeTab === "-1"
                ? data.venuesVisited.filter((item) => !item.isCheckIn).length
                : venuesVisited.visitedCheckinsItems.length}
            </h2>
            <p className="fs-17">{locale.visited_venues_but_no_checkin}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span className="statsUp">
                <MdOutlineArrowUpward />
              </span>
            </OverlayTrigger>
            <h2>
              {activeTab === "-1"
                ? data.venuesVisitedMap.length
                : venuesVisitedMap.length}
            </h2>
            <p className="fs-17">{locale.map_clicked}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <span className="statsUp">
              <MdOutlineArrowUpward />
            </span>
            <h2>0</h2>
            <p className="fs-17">{locale.table_seat_request}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <span className="statsDown">
              <MdOutlineArrowDownward />
            </span>
            <h2>
              {activeTab === "-1"
                ? data.rateReviews.length
                : ratingAndReviews.length}
            </h2>
            <p className="fs-17">{locale.rate_recevied}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <h2>
              {activeTab === "-1"
                ? data.rateReviews.length
                : ratingAndReviews.length}
            </h2>
            <p className="fs-17">{locale.review_received}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <h2>
              {activeTab === "-1"
                ? data.offerClaimed.length
                : offerClaimed.length}
            </h2>
            <p className="fs-17">{locale.offer_claimed}</p>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <h2>
              {activeTab === "-1" ? data.chekins.length : checkins.length}
            </h2>
            <p className="fs-17">{locale.check_in}</p>
          </div>
        </div>

        {portal && (
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="statsCard">
              <h2>
                <span>
                  <MdStarRate />
                </span>
                {portal && portal.rating}
              </h2>
              <p className="fs-17">{locale.rating_status}</p>
            </div>
          </div>
        )}
        <div className="col-sm-6 col-md-6 col-lg-3">
          <div className="statsCard">
            <span className="statsDown">
              <MdOutlineArrowDownward />
            </span>
            <h2>
              {activeTab === "-1"
                ? data.venueSearch.length
                : venueSearch.length}
            </h2>
            <p className="fs-17">{locale.venue_search}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const filterChekinData = useMemo(() => {
    const cDate = new Date();
    const items = [];
    data &&
      data.chekins.forEach((item) => {
        const d = new Date(item.date);
        if (parseInt(activeTab) >= DateDifference.inDays(d, cDate)) {
          items.push(item);
        }
      });
    return items;
  }, [data, activeTab]);

  const filterVenuesData = useMemo(() => {
    const cDate = new Date();
    const visiteditems = [];
    const visitedCheckinsItems = [];
    data &&
      data.venuesVisited.forEach((item) => {
        const d = new Date(item.date);
        if (parseInt(activeTab) >= DateDifference.inDays(d, cDate)) {
          if (!item.isCheckIn) {
            visitedCheckinsItems.push(item);
          }
          visiteditems.push(item);
        }
      });
    return { visiteditems, visitedCheckinsItems };
  }, [data, activeTab]);

  const filterVenuesMapData = useMemo(() => {
    const cDate = new Date();
    const items = [];
    data &&
      data.venuesVisitedMap.forEach((item) => {
        const d = new Date(item.date);
        if (parseInt(activeTab) >= DateDifference.inDays(d, cDate)) {
          items.push(item);
        }
      });
    return items;
  }, [data, activeTab]);

  const filterRateReviewsData = useMemo(() => {
    const cDate = new Date();
    const items = [];
    data &&
      data.rateReviews.forEach((item) => {
        const d = new Date(item.time);
        if (parseInt(activeTab) >= DateDifference.inDays(d, cDate)) {
          items.push(item);
        }
      });
    return items;
  }, [data, activeTab]);

  const filterOfferClaimed = useMemo(() => {
    const cDate = new Date();
    const items = [];
    data &&
      data.offerClaimed.forEach((item) => {
        const d = new Date(item.date);
        if (parseInt(activeTab) >= DateDifference.inDays(d, cDate)) {
          items.push(item);
        }
      });
    return items;
  }, [data, activeTab]);
  const filterVenueSearch = useMemo(() => {
    const cDate = new Date();
    const items = [];
    data &&
      data.venueSearch.forEach((item) => {
        const d = new Date(item.date);
        if (parseInt(activeTab) >= DateDifference.inDays(d, cDate)) {
          items.push(item);
        }
      });
    return items;
  }, [data, activeTab]);

  if (error) return toast.error(error.message);
  if (!data) return <Spinner />;
  return (
    <div className="profile_main">
      <div className="navLinks">
        {!portal && (
          <div
            className="btn btn-secondary my-2"
            onClick={() =>
              downloadVisistedVenues(
                filterChekinData,
                filterVenuesData,
                filterVenuesMapData,
                filterRateReviewsData,
                filterOfferClaimed,
                filterVenueSearch
              )
            }
          >
            {locale.export_stats}
          </div>
        )}
        <Tabs
          defaultActiveKey="7"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={tabHandler}
        >
          <Tab
            className="fs-20"
            eventKey="7"
            title={
              activeLanguage && !activeLanguage.includes("English")
                ? "٧ ايام"
                : "7 Days"
            }
          >
            {renderData(
              filterChekinData,
              filterVenuesData,
              filterVenuesMapData,
              filterRateReviewsData,
              filterOfferClaimed,
              filterVenueSearch
            )}
          </Tab>

          <Tab
            className="fs-20"
            eventKey="30"
            title={
              activeLanguage && !activeLanguage.includes("English")
                ? "٣٠ يوم"
                : "30 Days"
            }
          >
            {renderData(
              filterChekinData,
              filterVenuesData,
              filterVenuesMapData,
              filterRateReviewsData,
              filterOfferClaimed,
              filterVenueSearch
            )}
          </Tab>

          <Tab
            className="fs-20"
            eventKey="90"
            title={
              activeLanguage && !activeLanguage.includes("English")
                ? "٩٠ يوم"
                : "90 Days"
            }
          >
            {renderData(
              filterChekinData,
              filterVenuesData,
              filterVenuesMapData,
              filterRateReviewsData,
              filterOfferClaimed,
              filterVenueSearch
            )}
          </Tab>

          <Tab
            className="fs-20"
            eventKey="-1"
            title={
              activeLanguage && !activeLanguage.includes("English")
                ? "الكل"
                : "All Time"
            }
          >
            {renderData(
              filterChekinData,
              filterVenuesData,
              filterVenuesMapData,
              filterRateReviewsData,
              filterOfferClaimed,
              filterVenueSearch
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
