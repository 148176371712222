import React from "react";
import { getClientPredictions } from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import { useMemo } from "react";
import Table from "../../../components/common/table";
import { useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { downloadExcel } from "../../../utils/data";

export const Predictions = () => {
  const { data, error } = useSWR(
    "/admin/get/client-predictions",
    getClientPredictions
  );
  console.log(data);
  const [selectedData, setSelectedData] = useState();

  const transformData = useMemo(() => {
    const matches = [];
    data &&
      data.forEach((match) => {
        const matchIndex = matches.findIndex(
          (c) => c.matchID === match.matchID
        );
        if (matchIndex < 0) {
          const obj = {
            ...match,
            matchID: match.matchID,
            users: match.user
              ? [
                  {
                    ...match.user,
                    firstTeamScore: match.firstTeamScore,
                    secondTeamScore: match.secondTeamScore,
                  },
                ]
              : [],
          };
          matches.push(obj);
        } else {
          if (match.user) {
            matches[matchIndex].users.push({
              ...match.user,
              firstTeamScore: match.firstTeamScore,
              secondTeamScore: match.secondTeamScore,
            });
          } else {
            matches[matchIndex].users.push({
              firstTeamScore: match.firstTeamScore,
              secondTeamScore: match.secondTeamScore,
            });
          }
        }
      });
    return { matches };
  }, [data]);

  const COLUMNS = [
    {
      key: "TeamA",
      label: "Team A",
      content: (item) => <span>{item.firstTeam && item.firstTeam.name}</span>,
    },
    {
      key: "Date",
      label: "Date",
      content: (item) => <span>{item.match && item.match.matchDate}</span>,
    },
    {
      key: "TeamB",
      label: "Team B",
      content: (item) => <span>{item.secondTeam && item.secondTeam.name}</span>,
    },
    {
      key: "league",
      label: "League",
      content: (item) => (
        <span>{item.match && item.match.league && item.match.league.name}</span>
      ),
    },
    {
      key: "Monthly?Yearly",
      label: "Monthly/Yearly",
      content: (item) => <span>{item.isMonthly ? "Monthly" : "Weekly"}</span>,
    },
    {
      key: "ScoreSubmissions",
      label: "Score Submissions",
      content: (item) => (
        <span
          style={{
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => setSelectedData(item)}
        >
          {item.users ? item.users.length : 0}
        </span>
      ),
    },
  ];
  const SELECTED_DATA_COLUMNS = [
    {
      key: "TeamA",
      label: "Team A",
      content: (item) => <span>{selectedData.firstTeam.name}</span>,
    },
    {
      key: "score",
      label: "Score",
      content: (item) => (
        <span>{item.firstTeamScore + " / " + item.secondTeamScore}</span>
      ),
    },
    {
      key: "TeamB",
      label: "Team B",
      content: (item) => <span>{selectedData.secondTeam.name}</span>,
    },
    {
      key: "User Name",
      label: "User Name",
      content: (item) => <span>{item.name}</span>,
    },
  ];

  function exportDataHandler() {
    const tData = selectedData.users.map((item) => ({
      userID: selectedData.userID,
      firstTeam: selectedData.firstTeam.name,
      secondTeam: selectedData.secondTeam.name,
      userName: item.name,
      firstTeamScore: selectedData.firstTeamScore.toString(),
      secondTeamScore: selectedData.secondTeamScore.toString(),
    }));
    downloadExcel(tData, "users");
  }
  if (error) toast.error(error.message);
  if (!data) return <Spinner />;
  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Users Predictions</h1>
        </div>
      </div>
      {selectedData ? (
        <>
          <div className="d-flex" style={{ gap: "1rem" }}>
            <button
              onClick={() => setSelectedData(null)}
              className="btn btn-primary my-2"
            >
              <MdOutlineArrowBack />
            </button>
            <button
              onClick={exportDataHandler}
              className="btn btn-secondary my-2"
            >
              Export
            </button>
          </div>
          <Table data={selectedData.users} coloumns={SELECTED_DATA_COLUMNS} />
        </>
      ) : (
        <Table data={transformData.matches} coloumns={COLUMNS} />
      )}
    </div>
  );
};
