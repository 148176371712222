import React from "react";
import { loginAdmin } from "../../../Firebase/apis";
import LoginComponent from "./../../../components/login/Login";
import { toast } from "react-toastify";
import { useState } from "react";
export const Login = () => {
  const [loading, setLoading] = useState(false);
  const submitHandler = async (values) => {
    try {
      setLoading(true);
      const res = await loginAdmin(values);
      if (res) {
        window.location.reload();
        toast.success("Login Admin Succeesfully");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <LoginComponent
        loading={loading}
        submitHandler={submitHandler}
        primaryTitle="Welcome Back!"
        secondaryTitle="Login Admin"
      />
    </div>
  );
};
