import React from "react";
import { useState } from "react";

export const DescriptionCard = ({ details }) => {
  const [state, setState] = useState(false);

  return (
    <span>
      {details.length > 100 && !state ? (
        <>
          {details.slice(0, 100)}{" "}
          <span onClick={() => setState(true)} className="pointer">
            {" "}
            ...
          </span>
        </>
      ) : (
        details
      )}
    </span>
  );
};
