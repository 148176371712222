import React from "react";
import { deleteOffer, getOffers } from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import { MdOutlineSearch } from "react-icons/md";
import { Table } from "react-bootstrap";
import { useState } from "react";
import { useMemo } from "react";
import { userStatus } from "../../../utils/data";
import useStore from "../../../store/store";

export const Offers = () => {
  const { data, error } = useSWR("/admin/get/offers", getOffers);
  const [searchTerm, setSearchTerm] = useState("");
  const { locale } = useStore((st) => st);
  const navigate = useNavigate();
  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm(locale.are_you_sure_you_want_to_delete);
      if (confirm) {
        await deleteOffer(id);
        alert("Deleted successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const addOfferHandler = () => {
    if (!userStatus) return alert(locale.you_need_to_upgrade_memebership);
    navigate("/portal/offers/add");
  };

  const filterData = useMemo(() => {
    return data
      ? data.filter(
          (item) =>
            item.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            item.code.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            item.details.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : [];
  }, [searchTerm, data]);

  if (error) toast.error(error.message);
  if (!data) return <Spinner />;
  return (
    <div className="container">
      <div className="breadCrumb d-flex justify-content-between mt-2">
        <h4 className="fs-30">{locale.venue_portal_offers}</h4>
        <button
          onClick={addOfferHandler}
          className="btn btn-outline-secondary d-flex justify-content-center align-items-center"
          style={{ marginTop: "5rem" }}
        >
          <span style={{ marginRight: "10px" }}>
            <GrAdd />
          </span>
          <span>{locale.add}</span>
        </button>
      </div>
      <div className="profile_main p-3 pt-5">
        <p className="fs-17">
          {locale.offer_sub_title}
        </p>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="fixtureHeadInput mt-3">
              <div>
                <span className="fixtureInputIcon">
                  <MdOutlineSearch />
                </span>
              </div>
              <div>
                <input
                  type="text"
                  className="fixtureCustomInput fs-15"
                  placeholder={locale.search_for_team_league_offer_page}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <Table responsive className="mt-5 customTable">
          <thead>
            <tr>
              <th className="fs-20">{locale.id}</th>
              <th className="fs-20">{locale.code}</th>
              <th className="fs-20">{locale.details}</th>
              <th className="fs-20">{locale.validity_date}</th>
              <th className="fs-20"></th>
              <th className="fs-20"></th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((item, i) => (
              <tr className="mt-5">
                <td>{i + 1}</td>
                <td>{item.title}</td>
                <td>{item.details}</td>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>
                  <Link
                    to="/portal/offers/add"
                    state={item}
                    className="btn btn-secondary"
                  >
                    {locale.edit}
                  </Link>
                </td>
                <td>
                  <span
                    onClick={() => deleteHandler(item.docID)}
                    className="btn btn-danger"
                  >
                    {locale.delete}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
