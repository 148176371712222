import React from "react";
import { useField, ErrorMessage } from "formik";
import useStore from "../../store/store";

const Select = (props) => {
  const [field, meta] = useField(props);
  const { locale, activeLanguage } = useStore((st) => st);

  return (
    <div className="form-group">
      <label htmlFor={field.name} className={`col-form-label fs-15`}>
        {props.label}
      </label>

      <select
        {...field}
        {...props}
        // className={`form-control fs-15 ${activeLanguage && activeLanguage.includes("Arrabic") ? "alignRight" : ""} ${meta.error && "is-invalid"}`}
        className={`form-control fs-15 ${meta.error && "is-invalid"}`}
      >
        <option value="">{props.placeholder ? props.placeholder : props.label}</option>
        {props.data.map((e, i) => (
          <option key={i} value={e[props.searchKey]}>
            <span>{e.firstTeam && e.firstTeam.name}</span>
            <span> vs </span>
            <span>{e.secondTeam && e.secondTeam.name}</span>
          </option>
        ))}
      </select>
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};

export default Select;
