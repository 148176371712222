import React from "react";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./style.css";
import useStore from "../../store/store";

export const Fixtures = (props) => {
  const { locale } = useStore((st) => st);
  const [limit, setLimit] = useState(6);
  const getTime = (date) => {
    const d = new Date(date);
    return d.toLocaleTimeString();
  };

  const venuesHandler = (reservations, match) => {
    props.offerVenuesHandler(reservations ? reservations : [], match);
  };
  return (
    <>
      <div className="container" style={{ marginBottom: "100px" }}>
        <div className="matches-container">
          <div className="row">
            {props.data && props.data.length ? (
              props.data.slice(0, limit).map((match) => (
                <div className="col-md-6">
                  <div className="match-wrapper-container">
                    <div className="match-wrapper">
                      <span>{match.firstTeam.name}</span>
                      <img
                        src={
                          match.firstTeam.image
                            ? match.firstTeam.image
                            : `/assets/Flags/${match.firstTeam.flagName}.png`
                        }
                        alt={match.firstTeam.name}
                      />
                      <span>{getTime(match.startTime)}</span>
                      <img
                        src={
                          match.secondTeam.image
                            ? match.secondTeam.image
                            : `/assets/Flags/${match.secondTeam.flagName}.png`
                        }
                        alt={match.secondTeam.name}
                      />
                      <span>{match.secondTeam.name}</span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={() => venuesHandler(match.reservations, match)}
                        className="btn primary-bg-color"
                      >
                        {locale.find_venues}
                        <span>
                          <IoIosArrowForward />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                {locale.there_is_no_matches_message}
              </div>
            )}
          </div>
        </div>
      </div>
      {props.data.length > limit && (
        <button
          className="btn btn-primary"
          onClick={() => setLimit((p) => p + 6)}
        >
          {locale.load_more}
        </button>
      )}
    </>
  );
};
