import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { deleteEvent, getEvents } from "../../../Firebase/apis";
import { toast } from "react-toastify";
import Spinner from "./../../../components/common/Spinner.jsx";
import List from "./list";
import EventForm from "./form";

const Index = () => {
  const [activeKey, setActiveKey] = useState("Events");
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents()
      .then((res) => {
        setLoading(false);
        setEvents(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }, []);

  const addHandler = (event) => {
    setEvents((p) => [...p, event]);
  };
  const changeEvent = (event) => {
    const es = [...events];
    const index = es.findIndex((it) => it.docId === event.docId);
    if (index > -1) {
      es[index] = event;
    }
    setEvents(es);
    setSelectedItem(null);
  };

  const deleteHandler = async (id) => {
    try {
      await deleteEvent(id);
      setEvents(events.filter((i) => i.docId !== id));
      toast.success("Deleted");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const editHandler = (item) => {
    setSelectedItem(item);
    setActiveKey("NewEvent");
  };
  if (loading) return <Spinner />;
  return (
    <div className="container">
      <div className="secondary-container">
        <Tabs
          activeKey={activeKey}
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
          onSelect={(k) => setActiveKey(k)}
        >
          <Tab eventKey="Events" title="Events">
            <List
              data={events}
              setSelectedItem={editHandler}
              deleteHandler={deleteHandler}
              setLoading={setLoading}
            />
          </Tab>
          <Tab eventKey="NewEvent" title="New Event">
            <EventForm
              changeEvent={changeEvent}
              addEvent={addHandler}
              setActiveKey={setActiveKey}
              selectedItem={selectedItem}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Index;
