import React from "react";
import { useField, ErrorMessage } from "formik";
import useStore from "../../store/store";

const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { activeLanguage } = useStore((st) => st);
  return (
    <div className="mb-3">
      <label className={`fs-15 full-width`} htmlFor={field.name}>{label}</label>
      <input
        className={`form-control fs-15 ${meta.error && "is-invalid"}`}
        type="text"
        value={field.value ? field.value : ""}
        {...field}
        {...props}
      />
      <ErrorMessage
        name={field.name}
        component="div"
        style={{ color: "red" }}
      />
    </div>
  );
};

export default Input;
