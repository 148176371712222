import React from "react";
import {
  addNotificationForVenue,
  blockPortalHandler,
  changeSubscriptionOfPortal,
  deleteAccountReq,
  getDeleteAccountReq,
  getVenueForDeletion,
  rejectDeleteAccountReq,
  sendMessageToVenueHandler,
  verifyPortal,
} from "../../../Firebase/apis";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import { useState } from "react";
import { useMemo } from "react";
import { SUBSCRIPTIONS, downloadExcel } from "../../../utils/data";
import { DateDifference } from "../../../utils/date";
import { useEffect } from "react";
import useStore from "../../../store/store";
import shallow from "zustand/shallow";

export const VenuePortals = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");
  const [deleteVenueReqs, setDeleteVenueReqs] = useState([]);
  const [verifiedVenues, setVerifiedVenues] = useState([]);
  const [unVerifiedVenues, setUnVerifiedVenues] = useState([]);
  const navigate = useNavigate();
  const [showSubscriptionModal, setSubscriptionModal] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [selectedPortal, setSelectedPortal] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { venues, deleteVenue } = useStore(
    (state) => ({ venues: state.portals, deleteVenue: state.deleteVenue }),
    shallow
  );

  const changeSubscriptionHandler = (item) => {
    setSelectedPortal(item);
    setSelectedSubscription(item ? item.subscriptionBundle : null);
    setSubscriptionModal(true);
  };

  useEffect(() => {
    setVerifiedVenues(venues.filter((item) => item.adminVerified));
    setUnVerifiedVenues(venues.filter((item) => !item.adminVerified));
    getDeleteAccountReq()
      .then((res) => {
        setDeleteVenueReqs(res);
      })
      .catch((err) => toast.error(err.message));
  }, [venues]);

  const statusChangeHandler = async (item) => {
    try {
      const confirm = window.confirm("Are you sure, you want to Verify?");
      if (confirm) {
        await verifyPortal(item.userId, !item.adminVerified);
        alert("Verified!");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const submitMessage = async () => {
    try {
      if (!message.trim().length) return alert("Please type message.");
      await sendMessageToVenueHandler(selectedPortal.userId, message);
      const notiMessage = "New messsage from admin.";
      const route = "portal/inbox";
      await addNotificationForVenue(notiMessage, route, selectedPortal.userId);
      setSelectedPortal(null);
      alert("Submited!");
      setSendMessage(false);
    } catch (error) {
      alert(error.message);
    }
  };
  const subscriptionDoSubmit = async (item) => {
    try {
      if (!selectedSubscription)
        return alert("Please first select subscription.");
      await changeSubscriptionOfPortal(
        selectedPortal.userId,
        selectedSubscription
      );
      alert("Changed!");
      setSubscriptionModal(false);
    } catch (error) {
      alert(error.message);
    }
  };
  const changeHandler = (e) => {
    setSelectedSubscription(e.target.value);
  };
  const blockVenueHandler = async (item) => {
    try {
      const confirm = window.confirm(
        item.status
          ? "Are you sure, you want to Disable?"
          : "Are you sure, you want to Enable?"
      );
      if (confirm) {
        await blockPortalHandler(item.userId, !item.status);
        alert("Updated!");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const navigateToVenue = async (id) => {
    try {
      const venue = await getVenueForDeletion(id);
      if (!venue) return alert("Venue not found");
      navigate("/admin/venueportals/add", { state: venue });
    } catch (error) {
      alert(error.message);
    }
  };
  const getDate = (d) => {
    if (d) {
      return new Date(d);
    } else {
      return new Date();
    }
  };

  const COLUMNSForDeleteAcc = [
    {
      label: "ID",
      content: (item) => (
        <span
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => navigateToVenue(item.id)}
        >
          {item.id}
        </span>
      ),
    },
    {
      path: "reason",
      label: "Reason",
    },
    {
      key: "Reject",
      label: "",
      content: (item) => (
        <span onClick={() => rejectHandler(item.id)} className="btn btn-danger">
          Reject
        </span>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <button
          disabled={item.isDeleted}
          onClick={() => deleteHandler(item.id, false)}
          className="btn btn-danger"
        >
          {item.isDeleted ? "Deleted" : "Delete"}
        </button>
      ),
    },
  ];
  const COLUMNS = [
    {
      path: "userId",
      label: "Venue ID",
    },
    {
      path: "venueName",
      label: "Name",
    },
    {
      path: "email",
      label: "Email",
    },
    {
      path: "mangerPhoneNumber",
      label: "Manager Phone Number",
    },
    {
      key: "City",
      label: "City",
      content: (item) => <span>{item.city}</span>,
    },
    {
      path: "subscriptionBundle",
      label: "Subscription Bundle",
    },
    {
      label: "Days Left",
      content: (item) => (
        <span>
          {DateDifference.inDays(
            new Date(),
            getDate(item.subscriptionExpiryDate)
          ) > 0
            ? DateDifference.inDays(
                new Date(),
                getDate(item.subscriptionExpiryDate)
              )
            : "Expired"}
        </span>
      ),
    },
    {
      label: "Subscription Expirey",
      content: (item) => (
        <span>{getDate(item.subscriptionExpiryDate).toLocaleDateString()}</span>
      ),
    },
    {
      key: "Change Bundle",
      label: "",
      content: (item) => (
        <span
          onClick={() => changeSubscriptionHandler(item)}
          className="btn primary-bg-color font-weight-bold"
        >
          Change Subscription
        </span>
      ),
    },
    {
      key: "changeStatus",
      content: (item) => (
        <div className="form-check form-switch">
          <input
            onChange={() => blockVenueHandler(item)}
            checked={item.status}
            type="checkbox"
            className="form-check-input"
            id={item.userId}
          />
          <label className="form-check-label" htmlFor={item.userId}>
            Disable/Enabled
          </label>
        </div>
      ),
    },
    {
      key: "changeStatus",
      content: (item) =>
        !item.adminVerified ? (
          <div className="form-check form-switch">
            <input
              onChange={() => statusChangeHandler(item)}
              checked={item.adminVerified}
              type="checkbox"
              className="form-check-input"
              id={item.userId}
            />
            <label className="form-check-label" htmlFor={item.userId}>
              Approve/Not-Approve
            </label>
          </div>
        ) : (
          <Link to="/admin/venueportals/stats" state={item} className="">
            Stats
          </Link>
        ),
    },
    {
      key: "sendMessage",
      content: (item) => (
        <button
          onClick={() => {
            setSendMessage(true);
            setSelectedPortal(item);
          }}
          className="btn btn-success"
        >
          Send Message
        </button>
      ),
    },
    {
      key: "changeStatus",
      content: (item) => (
        <Link
          to="/admin/venueportals/add"
          state={item}
          className="btn btn-secondary"
        >
          Edit
        </Link>
      ),
    },

    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.userId, true)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];

  const rejectHandler = async (id) => {
    try {
      await rejectDeleteAccountReq(id);
      toast.success("Rejected successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteHandler = async (id, verifiedVenue) => {
    const c = window.confirm("Are you sure to delete this venue Account?");
    if (!c) return;
    try {
      await deleteAccountReq(id, verifiedVenue);
      deleteVenue(id);
      toast.success("Deleted successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  function handleDownloadExcel() {
    const tData = verifiedVenues.map((item) => ({
      venueName: item.venueName,
      email: item.email,
      logo: item.logo,
      country: item.country,
      address: item.address,
      venuePhoneNumber: item.venuePhoneNumber,
      adminVerified: item.adminVerified,
      subscriptionBundle: item.subscriptionBundle,
      paymentFrequency: item.paymentFrequency,
      city: item.city,
      channels: item.channelSubscription.toString(),
      phoneNumber: item.mangerPhoneNumber,
      subscriptionExpiryDate: getDate(
        item.subscriptionExpiryDate
      ).toLocaleDateString(),
      DaysLeft: DateDifference.inDays(
        new Date(),
        getDate(item.subscriptionExpiryDate)
      ),
      userId: item.userId,
    }));
    downloadExcel(tData, "venues");
  }

  const filterVP = useMemo(() => {
    return verifiedVenues
      ? verifiedVenues.filter(
          (it) =>
            (it.venueName &&
              it.venueName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (it.email &&
              it.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (it.city &&
              it.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (it.address &&
              it.address.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      : [];
  }, [verifiedVenues, searchTerm]);
  const filterUVP = useMemo(() => {
    return unVerifiedVenues
      ? unVerifiedVenues.filter(
          (it) =>
            (it.venueName &&
              it.venueName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (it.email &&
              it.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (it.city &&
              it.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (it.address &&
              it.address.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      : [];
  }, [unVerifiedVenues, searchTerm]);
  const sortedDeleteReqs = useMemo(() => {
    return deleteVenueReqs.sort((x) => (!x.isDeleted ? -1 : 1));
  }, [deleteVenueReqs]);

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Venue Portals</h1>
          <Link
            to="/admin/venueportals/add"
            className="btn btn-outline-secondary d-flex justify-content-center align-items-center mb-3"
          >
            <span style={{ marginRight: "10px" }}>
              <GrAdd />
            </span>
            Add
          </Link>
        </div>

        <Tabs
          defaultActiveKey="verifiedportals"
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
        >
          <Tab eventKey="verifiedportals" title="Verified Venues">
            <div
              className="btn btn-secondary my-2"
              onClick={handleDownloadExcel}
            >
              Exports Venues
            </div>
            <div className="mb-3">
              <label>Search</label>
              <input
                className={`form-control`}
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Table coloumns={COLUMNS} data={filterVP} />
          </Tab>
          <Tab eventKey="unverifiedportals" title="Unverified Venues">
            <div className="mb-3">
              <label>Search</label>
              <input
                className={`form-control`}
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Table coloumns={COLUMNS} data={filterUVP} />
          </Tab>
          <Tab eventKey="deletereqsportals" title="Delete Requests of Venues">
            <Table coloumns={COLUMNSForDeleteAcc} data={sortedDeleteReqs} />
          </Tab>
        </Tabs>
      </div>
      <Modal
        show={showSubscriptionModal}
        onHide={() => setSubscriptionModal(false)}
      >
        <Modal.Title>Select Subscription</Modal.Title>
        <Modal.Body>
          <div class="form-group">
            <label htmlFor="SubscriptionBundle">Subscription Bundle</label>
            <select
              class="form-control"
              onChange={changeHandler}
              name="subscriptionBundle"
              value={selectedSubscription}
              id="SubscriptionBundle"
            >
              <option value="">Select Bundle</option>
              {SUBSCRIPTIONS.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className="d-flex mt-3">
            <button
              className="btn btn-danger"
              onClick={() => setSubscriptionModal(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-success ml-3"
              style={{ marginLeft: "20px" }}
              onClick={subscriptionDoSubmit}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={sendMessage} onHide={() => setSendMessage(false)}>
        <Modal.Title>Message</Modal.Title>
        <Modal.Body>
          <div class="form-group">
            <label htmlFor="message">Message </label>
            <input
              class="form-control"
              onChange={(e) => setMessage(e.target.value)}
              name="message"
              value={message}
              id="message"
              placeholder="Enter message to send"
            />
          </div>
          <div className="d-flex mt-3">
            <button
              className="btn btn-danger"
              onClick={() => setSendMessage(false)}
            >
              Cancel
            </button>
            <button
              style={{ marginLeft: "20px" }}
              className="btn btn-success ml-3"
              onClick={submitMessage}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
