import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AboutUs,
  ClientOfferForm,
  ContactUs,
  VenueHome,
  Home,
  Login,
  Pricing,
  Register,
  Landing,
} from "../pages/Client";
import useStore from "../store/store";
import { getLocale } from "../Firebase/apis";
import Spinner from "../components/common/Spinner.jsx";

const appLocale = sessionStorage.getItem("fanspot-locale");
const ClientRoutes = () => {
  const [loading, setLoading] = React.useState(true);
  const { setAppLocale, setLocale } = useStore((st) => st);
  React.useEffect(() => {
    getLocale()
      .then((data) => {
        setAppLocale(data.arrabicLocale, data.englishLocale);
        setLocale(
          appLocale && appLocale.includes("English")
            ? data.englishLocale
            : data.arrabicLocale
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Spinner />;
  }
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/offer" element={<ClientOfferForm />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/buisness" element={<VenueHome />} />
      <Route path="/fixtures" element={<Home />} />
      <Route path="/contactus" element={<ContactUs />} />
    </Routes>
  );
};

export default ClientRoutes;
