import React from "react";
import {
  approveRestReq,
  deleteRestaurant,
  getRestaurants,
} from "../../../Firebase/apis";
import useSWR from "swr";
import Spinner from "./../../../components/common/Spinner.jsx";
import { toast } from "react-toastify";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useState } from "react";
import { downloadExcel } from "../../../utils/data";
import { Tab, Tabs } from "react-bootstrap";

export const Restaurants = () => {
  const { data, error } = useSWR("/admin/get/ressturants", getRestaurants);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you sure, you want to delete?");
      if (confirm) {
        await deleteRestaurant(id);
        alert("Deleted successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const approveHandler = async (item) => {
    const c = window.confirm("Are you sure?");
    if (!c) return;
    try {
      await approveRestReq(item.docId, !item.offerReq);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const COLUMNS = [
    {
      key: "logo",
      label: "Image",
      content: (item) => (
        <img
          src={item.image}
          width={"100px"}
          height={"50px"}
          alt="alternatText"
        />
      ),
    },
    {
      key: "name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      path: "number",
      label: "Number",
    },
    {
      path: "address",
      label: "address",
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <Link
          to="/admin/restaurants/add"
          state={item}
          className="btn btn-secondary"
        >
          Edit
        </Link>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.docId)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];
  const N_COLUMNS = [
    {
      key: "logo",
      label: "Image",
      content: (item) => (
        <img
          src={item.image}
          width={"100px"}
          height={"50px"}
          alt="alternatText"
        />
      ),
    },
    {
      key: "name",
      label: "Name",
      content: (item) => <span>{item.name}</span>,
    },
    {
      path: "number",
      label: "Number",
    },
    {
      path: "address",
      label: "address",
    },
    {
      key: "changeStatus",
      content: (item) => (
        <div className="form-check form-switch">
          <input
            onChange={() => approveHandler(item)}
            checked={!item.offerReq}
            type="checkbox"
            className="form-check-input"
            id={item.docId}
          />
          <label className="form-check-label" htmlFor={item.docId}>
            DisApprove/Approve
          </label>
        </div>
      ),
    },
    {
      key: "Edit",
      label: "",
      content: (item) => (
        <Link
          to="/admin/restaurants/add"
          state={item}
          className="btn btn-secondary"
        >
          Edit
        </Link>
      ),
    },
    {
      key: "Delete",
      label: "",
      content: (item) => (
        <span
          onClick={() => deleteHandler(item.docId)}
          className="btn btn-danger"
        >
          Delete
        </span>
      ),
    },
  ];

  function handleDownloadExcel() {
    const tData = data
      ? data.map((item) => ({
          name: item.name,
          number: item.number,
          address: item.address,
          lat: item.lat,
          lng: item.lng,
          openingHour: item.openingHour,
          instagramLink: item.instagramLink,
          claimCode: item.claimCode,
          image: item.image,
          city: item.city,
        }))
      : [];
    downloadExcel(tData, "restaurents");
  }

  const getData = () => {
    const VR = data ? data.filter((item) => !item.offerReq) : [];
    const NVR = data ? data.filter((item) => item.offerReq) : [];
    return { VR, NVR };
  };

  if (error) toast.error(error.message);
  if (!data) return <Spinner />;

  return (
    <div className="container">
      <div className="secondary-container">
        <div className="d-flex justify-content-between">
          <h1>Restaurants</h1>
          <Link
            to="/admin/restaurants/add"
            className="btn btn-outline-secondary d-flex justify-content-center align-items-center mb-3"
          >
            <span style={{ marginRight: "10px" }}>
              <GrAdd />
            </span>
            Add
          </Link>
        </div>

        <div className="btn btn-secondary my-2" onClick={handleDownloadExcel}>
          Exports Restaurents
        </div>

        <Tabs
          defaultActiveKey="verifiedRestaurents"
          id="uncontrolled-tab-example"
          className="mb-3 fixtureNav"
        >
          <Tab eventKey="verifiedRestaurents" title="Restaurents">
            <Table data={getData().VR} coloumns={COLUMNS} />
            {getData().VR.length <= 0 && <span>No Restaurnats added yet.</span>}

            {getData().VR.length > 0 && getData().VR.length > itemsPerPage && (
              <span className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => setItemsPerPage((c) => c + 10)}
                >
                  <BsChevronDoubleDown />
                </button>
              </span>
            )}
          </Tab>
          <Tab eventKey="NotverifiedRestaurents" title="Restaurent Requests">
            <Table data={getData().NVR} coloumns={N_COLUMNS} />
            {getData().NVR.length <= 0 && (
              <span>No Restaurnats added yet.</span>
            )}

            {getData().NVR.length > 0 && getData().NVR.length > itemsPerPage && (
              <span className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => setItemsPerPage((c) => c + 10)}
                >
                  <BsChevronDoubleDown />
                </button>
              </span>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
